<form class="container-fluid">
    <div class="row">
        <div class="col-md-6">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Name</mat-label>
                <input matInput placeholder="Placeholder">
              </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Code</mat-label>
                <input matInput placeholder="Placeholder">
              </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Code Type</mat-label>
                <input matInput placeholder="Placeholder">
              </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Gross Price</mat-label>
                <input matInput placeholder="Placeholder">
              </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Cash Price</mat-label>
                <input matInput placeholder="Placeholder">
              </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Min Price</mat-label>
                <input matInput placeholder="Placeholder">
              </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Max Price</mat-label>
                <input matInput placeholder="Placeholder">
              </mat-form-field>
        </div>
        <div class="col-md-6 d-flex flex-row justify-content-end align-item-center">
            <button mat-raised-button color="primary" style="height: 60px; width: 120px;">Save</button>
        </div>
    </div>
</form>