import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpCallService } from 'src/app/services/http-call.service';
import { environment } from 'src/environments/environment.prod';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SpinnerService } from 'src/app/services/spinner.service';

interface DialogData {
  roleObj: any;
  assignedCount: number;
}

@Component({
  selector: 'app-delete-role',
  templateUrl: './delete-role.component.html',
  styleUrls: ['./delete-role.component.scss']
})
export class DeleteRoleComponent implements OnInit {

  migrateRoleID;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<DeleteRoleComponent>,
    private spinner: SpinnerService,
    private snackbar: MatSnackBar,
    private httpCall: HttpCallService
  ) { }

  ngOnInit(): void {
    this.getProviderRoles(localStorage.getItem('proID'));
  }

  providerRolesList: any = [];
  getProviderRoles(proID) {
    this.httpCall.getAuth(`${environment.providerDomain}/providerApi/getrolesProvider/${proID}`).subscribe(res => {
      if (res['success']) {
        this.providerRolesList = res['response'];
      } else {
        this.snackbar.open(res['message'], 'Failed', {
          duration: 5000,
          panelClass: 'snackBar-failure'
        });
      }
    }, (err) => {
      this.snackbar.open(err.error['message'], 'Failed', {
        duration: 5000,
        panelClass: 'snackBar-failure'
      });
    });
  }

  confirmDeleteRole() {
    let body = {
      providerId: localStorage.getItem('proID'),
      deleteroleId: this.data.roleObj._id,
      editroleId: this.migrateRoleID
    };
    this.httpCall.postAuth(`${environment.providerDomain}/providerApi/deleteRoleProvider`, body).subscribe( res => {
      if(res['success']) {
        this.snackbar.open(res['message'], 'Success', {
          duration: 5000,
          panelClass: 'snackBar-success'
        });
        this.dialogRef.close(res);
      } else {
        this.snackbar.open(res['message'], 'Failed', {
          duration: 5000,
          panelClass: 'snackBar-failure'
        });
      }
    }, (err) => {
      this.snackbar.open(err.error['message'], 'Failed', {
        duration: 5000,
        panelClass: 'snackBar-failure'
      });
    });
  }

}
