import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpCallService } from 'src/app/services/http-call.service';
import { environment } from 'src/environments/environment.prod';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { SpinnerService } from 'src/app/services/spinner.service';
import * as _ from 'lodash';

interface DialogData {
  isNew: boolean;
  proID: string;
  serviceItem?: any;
  insuranceList?: any;
}

@Component({
  selector: 'app-add-edit-charge-master-service',
  templateUrl: './add-edit-charge-master-service.component.html',
  styleUrls: ['./add-edit-charge-master-service.component.scss']
})
export class AddEditChargeMasterServiceComponent implements OnInit {

  serviceObj = {
    codeType: 1,
    itemCode: '',
    serviceName: '',
    serviceID: '',
    shortDesc: '',
    description: '',
    standardPriceIP: null,
    standardPriceOP: null,
    cashPriceIP: null,
    cashPriceOP: null,
    outNetworkPriceIP: null,
    outNetworkPriceOP: null,
    // minPriceIP: null,
    // minPriceOP: null,
    // maxPriceIP: null,
    // maxPriceOP: null,
    isBundled: false,
    isServicable: true,
    // isShoppable: true,
    // categoryType: '',
    serviceItemType: '',
    billingID: '',
    quantity: '',
  };

  feeTypes = [
    { name: 'Included', value: 'Y' },
    { name: 'Not Included', value: 'N' },
    { name: 'Partially Included', value: 'P' },
    { name: 'Not Applicable', value: 'NA' },
  ];

  proInsuranceList: any = [];

  serviceNameControl: FormControl = new FormControl();
  servicesDataList: any = [];

  codeTypes = environment.codeTypes;

  serviceItemTypeControl = new FormControl();
  filteredServiceItemTypesOptions: Observable<string[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<AddEditChargeMasterServiceComponent>,
    private snackbar: MatSnackBar,
    private spinner: SpinnerService,
    private httpCall: HttpCallService,
  ) {
    if(data != null) {
      this.data.insuranceList.forEach(element => {
        let newItem = {
          carrier_name: element.name,
          carrier_id: element.carrier_id,
          ip_price: null,
          op_price: null
        }
        this.proInsuranceList.push(newItem);
      });
    }
  }

  serviceItemTypes = [
    { item_type: 'Facility Fee' },
    { item_type: 'Drug Fee' },
    { item_type: 'Physician Fee' },
    { item_type: 'Other Fee' },
    { item_type : 'Primary Procedure' },
    { item_type : 'Primary Physician' }
  ];

  ngOnInit(): void {
    if(!this.data.isNew) {
      this.getServiceDetails(this.data.serviceItem.id);
    }
    // this.getServiceItemTypes(localStorage.getItem('proID'));
    // this.filteredServiceItemTypesOptions = this.serviceItemTypeControl.valueChanges.pipe( startWith(''), map(value => this.filterServiceItemTypes(value)) );
    this.getServicesLookupThrottled = _.debounce(this.getServicesLookup, 500);
  }

  // filterServiceItemTypes(value: string) {
  //   const filterValue = value.toLowerCase();
  //   return this.serviceItemTypes.filter(element => element.item_type.toLowerCase().includes(filterValue));
  // }

  searchBy = '1';
  servicesList: any = []

  getServicesLookupThrottled: any;
  getServicesLookup(serviceName) {
    if(serviceName) {
      let body = {
        code_type: this.serviceObj.codeType,
        text: serviceName,
        type: parseInt(this.searchBy)
      };
      this.httpCall.postAuth(`${environment.providerDomain}/providerApi/drglookup`, body).subscribe( res => {
        if(res['success']) {
          this.servicesList = res['response'];
        }
      }, (err) => {
        this.snackbar.open(err.error['message'], 'Failed', {
          duration: 5000,
          panelClass: 'snackBar-failure'
        });
      });
    }
  }

  getServiceDetails(serviceID) {
    this.httpCall.get(`${environment.providerDomain}/BackEnd/fetchNewSingleChargeMaster/${serviceID}`).subscribe( res => {
      if(res['success']) {
        let response = res['response'][0];

        this.serviceObj.codeType = response?.code_type;
        this.serviceObj.itemCode = response?.cpt_code;
        this.serviceObj.serviceItemType = response?.item_type?.trim();
        this.serviceObj.serviceName = response?.test_name;
        this.serviceObj.serviceID = response?.master_id;
        this.serviceObj.shortDesc = response?.short_description;
        this.serviceObj.description = response?.description;
        this.serviceObj.standardPriceIP = response?.std_price_ip;
        this.serviceObj.standardPriceOP = response?.std_price_op;
        this.serviceObj.cashPriceIP = response?.cash_price_ip;
        this.serviceObj.cashPriceOP = response?.cash_price_op;
        this.serviceObj.outNetworkPriceIP = response?.outofnetwork_ip;
        this.serviceObj.outNetworkPriceOP = response?.outofnetwork_op;
        this.serviceObj.isBundled = response?.is_bundled;
        this.serviceObj.isServicable = response?.isServicable;
        this.serviceObj.billingID = response?.billingId;

        // this.proInsuranceList.forEach(element => {
        //   let filtered = response.insurance_prices.filter( item => item.carrier_id == element.carrier_id);
        //   if(filtered) {
        //     element.ip_price = filtered[0].ip_price;
        //     element.op_price = filtered[0].op_price;
        //   }
        // });
      } else {
        this.snackbar.open(res['message'], 'Failed', {
          duration: 5000,
          panelClass: 'snackBar-failure'
        });
      }
    }, (err) => {
      this.snackbar.open(err.error['message'], 'Failed', {
        duration: 5000,
        panelClass: 'snackBar-failure'
      });
    });
  }

  selectService(selectedItem) {
    this.serviceObj.serviceID = selectedItem._id;
    this.serviceObj.itemCode = selectedItem.cpt_code;
    this.serviceObj.serviceName = selectedItem.test_name;
  }


  submitServiceDetails() {
    let body;
    if(this.data.isNew) {
      body = {
        item_type: this.serviceObj.serviceItemType,
        isMandatory: false,
        isServicable: !!this.serviceObj.isServicable,
        billingId: this.serviceObj.billingID,
        providerId: this.data.proID,
        test_name: this.serviceObj.serviceName.toUpperCase(),
        code_type: this.serviceObj.codeType,
        cpt_code: this.serviceObj.itemCode,
        description: this.serviceObj.description,
        master_id: this.serviceObj.serviceID,
        short_description: this.serviceObj.shortDesc,
        is_bundled: this.serviceObj.isBundled,
        std_price_ip: this.serviceObj.standardPriceIP.includes('$')?this.serviceObj.standardPriceIP.replace('$',''):this.serviceObj.standardPriceIP,
        std_price_op: this.serviceObj.standardPriceOP.includes('$')?this.serviceObj.standardPriceOP.replace('$',''):this.serviceObj.standardPriceOP,
        cash_price_ip: this.serviceObj.cashPriceIP.includes('$')?this.serviceObj.cashPriceIP.replace('$',''):this.serviceObj.cashPriceIP,
        cash_price_op: this.serviceObj.cashPriceOP.includes('$')?this.serviceObj.cashPriceOP.replace('$',''):this.serviceObj.cashPriceOP,
        outofnetwork_ip: this.serviceObj.outNetworkPriceIP?this.serviceObj.outNetworkPriceIP.includes('$')?this.serviceObj.outNetworkPriceIP.replace('$',''):this.serviceObj.outNetworkPriceIP:'',
        outofnetwork_op: this.serviceObj.outNetworkPriceIP?this.serviceObj.outNetworkPriceIP.includes('$')?this.serviceObj.outNetworkPriceIP.replace('$',''):this.serviceObj.outNetworkPriceIP:'',
        insurance_prices: this.proInsuranceList,
        quantity: this.serviceObj.quantity
      };
      if(body.std_price_ip.includes('-') || body.std_price_op.includes('-') || body.cash_price_ip.includes('-') || body.cash_price_op.includes('-') || body.outofnetwork_ip.includes('-') || body.outofnetwork_op.includes('-')){
        return this.snackbar.open("Values should only contain postive numbers", 'Failed', {
                duration: 5000,
                panelClass: 'snackBar-failure'
              });
      }
      if(body.insurance_prices.length > 0){
        let isNegativeInInsurances = body.insurance_prices.some( el => {
            return (el.ip_price < 0 || el.op_price < 0);
        })
        if(isNegativeInInsurances){
          return this.snackbar.open("Values should only contain postive numbers", 'Failed', {
            duration: 5000,
            panelClass: 'snackBar-failure'
          });
        }
      }
      this.spinner.showSpinner('Loading...Please Wait');
      this.httpCall.postAuth(`${environment.providerDomain}/providerApi/addNewCMItem`, body).subscribe( res => {
        this.spinner.stopSpinner();
        if(res['success']) {
          this.snackbar.open(res['message'], 'Success', {
            duration: 5000,
            panelClass: 'snackBar-success'
          });
          console.log(res,"check response")
          this.dialogRef.close(res);
        } else {
          this.snackbar.open(res['message'], 'Failed', {
            duration: 5000,
            panelClass: 'snackBar-failure'
          });
        }
      }, (err) => {
        this.spinner.stopSpinner();
        this.snackbar.open(err.error['message'], 'Failed', {
          duration: 5000,
          panelClass: 'snackBar-failure'
        });
      });
    } else {
      // Edit parent service
      body = {
        service_id: this.data.serviceItem.id,
        item_type: this.serviceObj.serviceItemType,
        isServicable: !!this.serviceObj.isServicable,
        isShoppable: true,
        billingId: this.serviceObj.billingID,
        // providerId: this.data.proID,
        test_name: this.serviceObj.serviceName.toUpperCase(),
        code_type: this.serviceObj.codeType,
        cpt_code: this.serviceObj.itemCode,
        description: this.serviceObj.description,
        master_id: this.serviceObj.serviceID,
        short_description: this.serviceObj.shortDesc,
        is_bundled: this.serviceObj.isBundled,
        std_price_ip: this.serviceObj.standardPriceIP,
        std_price_op: this.serviceObj.standardPriceOP,
        cash_price_ip: this.serviceObj.cashPriceIP,
        cash_price_op: this.serviceObj.cashPriceOP,
        outofnetwork_ip: this.serviceObj.outNetworkPriceIP,
        outofnetwork_op: this.serviceObj.outNetworkPriceOP,
        quantity: this.serviceObj.quantity,
        employee_id: localStorage.getItem('empID')
      };
      console.log(body);
      if(
        body.std_price_ip.toString().includes('-')
        || body.std_price_op.toString().includes('-')
        || body.cash_price_ip.toString().includes('-')
        || body.cash_price_op.toString().includes('-')
        || body.outofnetwork_ip.toString().includes('-')
        || body.outofnetwork_op.toString().includes('-')){
        return this.snackbar.open("Values should only contain postive numbers", 'Failed', {
                duration: 5000,
                panelClass: 'snackBar-failure'
              });
      }

      this.spinner.showSpinner('Loading...Please Wait');
      this.httpCall.postAuth(`${environment.providerDomain}/providerApi/manageCMItem`, body).subscribe( res => {
        this.spinner.stopSpinner();
        if(res['success']) {
          this.snackbar.open(res['message'], 'Success', {
            duration: 5000,
            panelClass: 'snackBar-success'
          });
          this.dialogRef.close(res);
        } else {
          this.snackbar.open(res['message'], 'Failed', {
            duration: 5000,
            panelClass: 'snackBar-failure'
          });
        }
      }, (err) => {
        this.spinner.stopSpinner();
        this.snackbar.open(err.error['message'], 'Failed', {
          duration: 5000,
          panelClass: 'snackBar-failure'
        });
      });
    }
  }

}
