import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { GoogleAnalyticsService } from './services/google-analytics.service';
import { HttpCallService } from './services/http-call.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MyInterceptor } from './services/interceptor';
import { ProviderGuardService } from './services/auth-guard.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatStepperModule } from '@angular/material/stepper';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatSpinner, MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AgmCoreModule } from '@agm/core';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AmazingTimePickerModule } from 'amazing-time-picker';
import { ProviderLandingComponent } from './provider-landing/provider-landing.component';
import { SharedModule } from './shared/shared.module';
import { MatRadioModule } from '@angular/material/radio';

@NgModule({
  declarations: [
    AppComponent,
    ProviderLandingComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    // FlexLayoutModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatIconModule,
    MatStepperModule,
    MatCheckboxModule,
    MatChipsModule,
    MatRadioModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    FormsModule,

    // NgxMatSelectSearchModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyDTQpxPMtf4zDqZ7XlHCdKe7W_vb0NfWGk",
      libraries: ["places"]
    }),
    SharedModule
  ],
  providers: [
    HttpCallService,
    ProviderGuardService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MyInterceptor,
      multi: true
    },
    GoogleAnalyticsService
  ],
  entryComponents: [
    MatSpinner
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
