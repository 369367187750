import { Component, OnInit } from '@angular/core';
import { HttpCallService } from 'src/app/services/http-call.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { SelectItem } from 'primeng/api';
import * as _ from 'underscore';
import * as crypto from 'crypto-js';
import { AddEditOptionalServicesComponent } from '../add-edit-optional-services/add-edit-optional-services.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';
import { ViewServiceDetailComponent } from '../view-service-detail/view-service-detail.component';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-add-multi-parent',
  templateUrl: './add-multi-parent.component.html',
  styleUrls: ['./add-multi-parent.component.scss']
})
export class AddMultiParentComponent implements OnInit {

  optionalTestCategories = [
    { name: 'EVALUATION & MANAGEMENT SERVICES', masterID: '60095f2f23b5e2083c46a652' },
    { name: 'LABORATORY & PATHOLOGY SERVICES', masterID: '60095f2f23b5e2083c46a638' },
    { name: 'RADIOLOGY SERVICES', masterID: '60095f2f23b5e2083c46a63f' },
    { name: 'MEDICINE & SURGERY SERVICES', masterID: '60095f2f23b5e2083c46a654' },
    { name: 'OTHERS', masterID: '606ebd93aa30040b0b5c5889' }
  ];

  selectedCategoryID = '60095f2f23b5e2083c46a652';
  categorySelected(selectedItem) {
    this.selectedCategoryID = selectedItem.masterID;
    this.filterByCategories();
  }

  backToPreviousPage() {
    this.location.back();
  }

  getEachTabCount(id) {
    let filtered = this.addedChildrenServicesList.filter( element => element.category == id);
    return filtered.length > 0 ? filtered.length : 0;
  }

  searchBy = '1';
  selectedCodeType = 1;
  codeTypes = environment.codeTypes;

  enteredServiceName;

  constructor(
    public httpCall: HttpCallService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private snackbar: MatSnackBar,
    private spinner: SpinnerService,
    private location: Location
  ) { }

  serviceItemTypes = [
    { item_type: 'Facility Fee' },
    { item_type: 'Drug Fee' },
    { item_type: 'Physician Fee' },
    { item_type: 'Other Fee' },
  ];

  feeTypes = [
    { name: 'Included', value: 'Y' },
    { name: 'Not Included', value: 'N' },
    { name: 'Partially Included', value: 'P' },
    { name: 'Not Applicable', value: 'NA' },
  ];

  ngOnInit(): void {
    this.getProviderInsurances(localStorage.getItem('proID'));
    // this.getServiceItemTypes(localStorage.getItem('proID'));
    this.getPhysicians(localStorage.getItem('proID'));
  }

  selectedServiceItemType;
  // serviceItemTypes: any = [];
  // getServiceItemTypes(proID) {
  //   this.spinner.showSpinner('Loading...Please Wait');
  //   this.httpCall.getAuth(`${environment.providerDomain}/BackEnd/getServiceTypes/${proID}`).subscribe(res => {
  //     this.spinner.stopSpinner();
  //     if (res['success']) {
  //       this.serviceItemTypes = res['response'];
  //     } else {
  //       this.snackbar.open(res['message'], 'Failed', {
  //         duration: 5000,
  //         panelClass: 'snackBar-failure'
  //       });
  //     }
  //   }, (err) => {
  //     this.spinner.stopSpinner();
  //     this.snackbar.open(err.error['message'], 'Failed', {
  //       duration: 5000,
  //       panelClass: 'snackBar-failure'
  //     });
  //   });
  // }

  public selectedPhysicians: Array<SelectItem> = new Array<SelectItem>();
  physiciansList: SelectItem[];
  getPhysicians(proID) {
    let body = {
      providerId: proID
    };
    this.spinner.showSpinner('Loading...Please Wait');
    this.httpCall.postAuth(`${environment.providerDomain}/providerApi/getAllDoctors`, body).subscribe(res => {
      this.spinner.stopSpinner();
      if (res['success']) {
        this.physiciansList = res['response'];
      } else {
        this.snackbar.open(res['message'], 'Failed', {
          duration: 5000,
          panelClass: 'snackBar-failure'
        });
      }
    }, (err) => {
      this.spinner.stopSpinner();
      this.snackbar.open(err.error['message'], 'Failed', {
        duration: 5000,
        panelClass: 'snackBar-failure'
      });
    });
  }

  proInsuranceList: any = [];
  getProviderInsurances(proID) {
    this.spinner.showSpinner('Loading...Please Wait');
    this.httpCall.getAuth(`${environment.providerDomain}/BackEnd/getProviderInsurances/${proID}`).subscribe(res => {
      this.spinner.stopSpinner();
      if (res['success']) {
        this.proInsuranceList = res['response'];
      } else {
        this.snackbar.open(res['message'], 'Failed', {
          duration: 5000,
          panelClass: 'snackBar-failure'
        });
      }
    }, (err) => {
      this.spinner.stopSpinner();
      this.snackbar.open(err.error['message'], 'Failed', {
        duration: 5000,
        panelClass: 'snackBar-failure'
      });
    });
  }

  chargeMasterServicesList: any = [];
  getChangeMasterLookup(event: any) {
    if(event.target.value.length > 0) {
      let apiUrl: string;
      if(this.selectedCategoryID) {
        apiUrl = `providerApi/masterLookup/${localStorage.getItem('proID')}/${event.target.value}?cpt=${this.selectedCodeType}&searchType=${parseInt(this.searchBy)}&category=${this.selectedCategoryID}`;
      } else {
        apiUrl = `providerApi/masterLookup/${localStorage.getItem('proID')}/${event.target.value}?cpt=${this.selectedCodeType}&searchType=${parseInt(this.searchBy)}`;
      }
      this.httpCall.getAuth(`${environment.providerDomain}/${apiUrl}`).subscribe(res => {
        if (res['success']) {
          this.chargeMasterServicesList = res['response'];
        } else {
          // this.snackbar.open(res['message'], 'Failed', {
          //   duration: 5000
          // });
        }
      }, (err) => {
        this.snackbar.open(err.error['message'], 'Failed', {
          duration: 5000,
          panelClass: 'snackBar-failure'
        });
      });
    }
  }

  getCategoryName(id) {
    return this.optionalTestCategories.filter(element => element.masterID == id)[0].name;
  }

  filterByCategories() {
    let filtered = this.addedChildrenServicesList.filter( element => element.category == this.selectedCategoryID);
    this.tempAddedChildrenServicesList = filtered;
  }

  totalStandardPriceIP: number = 0;
  totalStandardPriceOP: number = 0;

  addedChildrenServicesList: any = [];
  tempAddedChildrenServicesList: any = [];
  serviceSelectedFromChargeMaster(selectedService) {
    this.enteredServiceName = '';
    let newServiceObj = {
      // chargeMasterId: selectedService._id,
      master_id: selectedService.master_id,
      is_bundled: selectedService.is_bundled,
      std_price_ip: selectedService.std_price_ip,
      std_price_op: selectedService.std_price_op,
      cash_price_ip: selectedService.cash_price_ip,
      cash_price_op: selectedService.cash_price_op,
      outofnetwork_ip: selectedService.outofnetwork_ip,
      outofnetwork_op: selectedService.outofnetwork_op,
      min_price_ip: selectedService.min_price_ip,
      min_price_op: selectedService.min_price_op,
      max_price_ip: selectedService.max_price_ip,
      max_price_op: selectedService.max_price_op,
      isMandatory: selectedService.isMandatory,
      code_type: selectedService.code_type,
      provider: localStorage.getItem('proID'),
      test_name: selectedService.test_name.toUpperCase(),
      description: selectedService.description,
      short_description: selectedService.short_description,
      cpt_code: selectedService.cpt_code,
      quantity: Number.isInteger(selectedService.quantity)? selectedService.quantity.toString() : '',
      insurance_prices: selectedService.insurance_prices,
      // employee_id: localStorage.getItem('empID'),
      category: this.selectedCategoryID,
      Facility_fee: 'N',
      Drug_fee: 'N',
      Physician_fee: 'N',
      Other_fee: 'N',
      cptcode_type: `${localStorage.getItem('proID')}-${selectedService.cpt_code}-${selectedService.code_type}`,
      master_key: selectedService.master_key
    };
    let filtered = this.addedChildrenServicesList.filter( item => item.cpt_code == selectedService.cpt_code && item.code_type == selectedService.code_type);
    if(filtered.length > 0) {
      this.snackbar.open(`${selectedService.test_name} was already added`, 'Failed', {
        duration: 5000,
        panelClass: 'snackBar-failure'
      });
    } else {
      this.addedChildrenServicesList.push(newServiceObj);
      this.filterByCategories();
    }
  }

  getTotalStandardPrices() {
    let childStandardArrayIP = _.pluck(this.addedChildrenServicesList, 'std_price_ip')
    let childStandardArrayOP = _.pluck(this.addedChildrenServicesList, 'std_price_op')
    return [childStandardArrayIP.reduce((a, b) => a + b, 0) || 0, childStandardArrayOP.reduce((a, b) => a + b, 0) || 0];
  }

  removeChildren(selectedService) {
    let filteredIndex = this.addedChildrenServicesList.findIndex( item => item.cpt_code == selectedService.cpt_code);
    this.addedChildrenServicesList.splice(filteredIndex, 1);
    this.filterByCategories();
  }

  enteredPhysician: string;

  async openTestDialog(isNew: boolean, selectedItem?: any, isChildNode?: boolean) {
    if(!isChildNode && selectedItem) {
      isChildNode = selectedItem?.superKey && selectedItem?.parentId ? true : false;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.minWidth = '30vw';
    dialogConfig.data = {
      isNew: isNew,
      proID: localStorage.getItem('proID'),
      isChild: isChildNode,
      serviceItem: selectedItem,
      insuranceList: this.proInsuranceList.filter( element => element.state == 1)
    };
    const dialogRef = this.dialog.open(AddEditOptionalServicesComponent, dialogConfig);
    dialogRef.beforeClosed().subscribe(data => {
      if (data) {
        if(data.notFromChargeMaster) {
          let encryptedServiceName = crypto.MD5(data.selectedService.serviceName);
          let newServiceObj = {
            isMandatory: false,
            is_bundled: data.selectedService.isBundled,
            provider: localStorage.getItem('proID'),
            // employee_id: localStorage.getItem('empID'),
            test_name: data.selectedService.serviceName.toUpperCase(),
            category: data.selectedService.categoryType,
            code_type: data.selectedService.codeType,
            cpt_code: data.selectedService.itemCode,
            master_id: data.selectedService.serviceID,
            quantity: Number.isInteger(data.selectedService.quantity) ? data.selectedService.quantity.toString() : '',
            description: data.selectedService.description,
            short_description: data.selectedService.shortDesc,
            std_price_ip: data.selectedService.standardPriceIP,
            std_price_op: data.selectedService.standardPriceOP,
            cash_price_ip: data.selectedService.cashPriceIP,
            cash_price_op: data.selectedService.cashPriceOP,
            outofnetwork_ip: data.selectedService.outNetworkPriceIP,
            outofnetwork_op: data.selectedService.outNetworkPriceOP,
            insurance_prices: data.proInsuranceList,
            Facility_fee: data.selectedService.facilityFee,
            Drug_fee: data.selectedService.drugFee,
            Physician_fee: data.selectedService.physicianFee,
            Other_fee: data.selectedService.otherFee,
            cptcode_type: `${localStorage.getItem('proID')}-${data.selectedService.itemCode}-${data.selectedService.codeType}`,
            master_key: `${localStorage.getItem('proID')}-${data.selectedService.itemCode}-${data.selectedService.codeType}-${encryptedServiceName}`,
          };
          let filtered = this.addedChildrenServicesList.filter( item => item.cpt_code == data.selectedService.itemCode && item.code_type == data.selectedService.codeType);
          if(filtered.length > 0) {
            this.snackbar.open(`${data.selectedService.serviceName} was already added`, 'Failed', {
              duration: 5000,
              panelClass: 'snackBar-failure'
            });
          } else {
            this.addedChildrenServicesList.push(newServiceObj);
            this.filterByCategories();
          }
        }
      }
    });
  }

  submitParentServices() {
    this.spinner.showSpinner('Loading...Please Wait');
    this.httpCall.postAuth(`${environment.providerDomain}/providerApi/AddBulkOptionalTest`, this.addedChildrenServicesList).subscribe(res => {
      this.spinner.stopSpinner();
      if (res['success']) {
        this.snackbar.open(res['message'], 'Success', {
          duration: 5000,
          panelClass: 'snackBar-success'
        });
        // this.router.navigate(['/proRegister/addOptionalTests']);
        this.backToPreviousPage();
      } else {
        this.snackbar.open(res['message'], 'Failed', {
          duration: 5000,
          panelClass: 'snackBar-failure'
        });
      }
    }, (err) => {
      this.spinner.stopSpinner();
      this.snackbar.open(err.error['message'], 'Failed', {
        duration: 5000,
        panelClass: 'snackBar-failure'
      });
    });
  }

  async viewServiceDetailDialog(selectedItem) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.minWidth = '30vw';
    dialogConfig.maxWidth = '100vw';
    dialogConfig.width = '750px';
    dialogConfig.data = {
      selectedItem: selectedItem
    };
    const dialogRef = this.dialog.open(ViewServiceDetailComponent, dialogConfig);
    dialogRef.beforeClosed().subscribe(data => {
      if (data) {

      }
    });
  }

}
