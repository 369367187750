<div *ngIf="text" class="ion-text-left">
    <div *ngIf="text.length <= limit">{{text}}</div>
    <div *ngIf="truncating && text.length > limit">
      {{(text | slice : 0 : limit) + '...'}}
      <a href="javascript:void(0);" (click)="truncating = false" style="font-weight: 500;color: black;text-decoration: underline;">view more</a>
    </div>
    <div *ngIf="!truncating && text.length > limit">
      {{text}}
      <a href="javascript:void(0);" (click)="truncating = true" style="font-weight: 500;color: black;text-decoration: underline;">view less</a>
    </div>
</div>