import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { HttpCallService } from 'src/app/services/http-call.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgForm } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  contactUsNumber = environment.contactUs_usa;

  reasonsList = ["Account", "Billing", "Technical Support", "Other"];
	vm: any = {
		fname: '',
		lname: '',
		email: '',
		type: '',
		contact: '',
		desc: '',
		userType: 1
	};

  constructor(
    private snackbar: MatSnackBar,
    private spinner: SpinnerService,
    private httpCall: HttpCallService,
    public router: Router,
    public googleAnalytics: GoogleAnalyticsService,
  ) { 
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.googleAnalytics.pageViews(event.urlAfterRedirects);
      }
    });
  }

  ngOnInit(): void { 
    if(this.httpCall?.currentUserAccess) {
      this.vm.fname = localStorage.getItem('');
      this.vm.lname = localStorage.getItem('');
      this.vm.email = localStorage.getItem('userEmail');
      this.vm.contact = localStorage.getItem('mobile');
    }
  }

  contactUsSubmit(passedData, form: NgForm) {
    this.spinner.showSpinner('Loading...Please Wait');
    this.httpCall.post(`${environment.providerDomain}/BackEnd/contactUs`, passedData).subscribe((res) => {
      this.spinner.stopSpinner();
      form.resetForm();
      if(res['success']) {
        this.snackbar.open(res['message'], 'Success', {
          duration: 5000,
          panelClass: 'snackBar-success'
        });
      } else {
        this.snackbar.open(res['message'], 'Failed', {
          duration: 5000,
          panelClass: 'snackBar-failure'
        });
      }
    },(err) => {
      this.spinner.stopSpinner();
      form.resetForm();
      this.snackbar.open(err.error['message'], 'Failed', {
        duration: 5000,
        panelClass: 'snackBar-failure'
      });
    });
  }

}
