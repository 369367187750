

<app-toolbar></app-toolbar>

<section class="bg-custom-primary-light mt-2">
    <div class="p-4">
        <div class="row">
            <div class="col-md-8">
                <div class="d-flex">
                    <button class="mr-3" mat-icon-button type="button" style="vertical-align: middle;" (click)="backToPreviousPage()">
                        <mat-icon style="font-size: 36px;width: 36px;height: 36px;">keyboard_backspace</mat-icon>
                    </button>
                    <div>
                        <p class="h4" style="font-weight: 400;">{{codeTypes[serviceObj?.code_type]?.name}}-{{serviceObj?.cpt_code}}</p>
                        <p class="h4">{{serviceObj?.test_name}}</p>
                        <div class="mt-5" *ngIf="addedChildrenServicesList.length > 0">
                            <mat-radio-group aria-label="Select an option" [(ngModel)]="showPricesType" (change)="radioTypeChanged($event.value)">
                                <mat-radio-button value="both">Show Both</mat-radio-button>
                                <mat-radio-button value="ip">Show Inpatient</mat-radio-button>
                                <mat-radio-button value="op">Show OutPatient</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="border bg-white p-2">
                    <div class="row m-0 p-2 bg-custom-gray">
                        <div class="col-md-6 text-center">Standard Price</div>
                        <div class="col-md-6 text-center" *ngIf="addedChildrenServicesList.length > 0">Total Standard Price</div>
                    </div>
                    <div class="row mt-2" *ngIf="showIP">
                        <div class="col-md-6 text-center">
                            <div class="p-1">
                                <p class="m-0 h5 custom-primary" style="font-weight: 500;">{{ serviceObj?.std_price_ip ? (serviceObj?.std_price_ip | currency:'USD') : 'N/A' }}</p>
                                <p class="m-0" style="font-size: smaller;">In Patient</p>
                            </div>
                        </div>
                        <div class="col-md-6 text-center" *ngIf="addedChildrenServicesList.length > 0 || oldServicesList.length > 0">
                            <div class="p-1 bg-custom-primary text-white">
                                <p class="m-0 h5" style="font-weight: 500;">{{ getTotalStandardPrices()[0] ? (getTotalStandardPrices()[0] | currency:'USD') : 'N/A' }}</p>
                                <p class="m-0" style="font-size: smaller;">In Patient</p>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2" *ngIf="showOP">
                        <div class="col-md-6 text-center">
                            <div class="p-1">
                                <p class="m-0 h5 custom-primary" style="font-weight: 500;">{{ serviceObj?.std_price_op ? (serviceObj?.std_price_op | currency:'USD') : 'N/A' }}</p>
                                <p class="m-0" style="font-size: smaller;">Out Patient</p>
                            </div>
                        </div>
                        <div class="col-md-6 text-center" *ngIf="addedChildrenServicesList.length > 0 || oldServicesList.length > 0">
                            <div class="p-1 bg-custom-primary text-white">
                                <p class="m-0 h5" style="font-weight: 500;">{{ getTotalStandardPrices()[1] ? (getTotalStandardPrices()[1] | currency:'USD') : 'N/A' }}</p>
                                <p class="m-0" style="font-size: smaller;">Out Patient</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="p-2" style="background-color: #80deea;">
    <div class="container">
        <div class="row">
            <div class="col-md-2" *ngFor="let item of feeTypeButtons">
                <button mat-button (click)="feeTypeButtonSelected(item)" [ngClass]="{'border border-dark': item?.name === selectedFeeButton}" class="font-weight-bold">
                    <img src="assets/img/fee-icons/{{item.name}}.svg" class="mr-2" style="width: 32px;"/>
                    <span>{{item.name}} ({{getEachTabCount(item.name)}})</span>
                </button>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">
    <mat-card class="my-4 mat-elevation-z2">
        <mat-card-content>
            <div class="d-flex">
                <div class="flex-shrink-1 p-1">
                    <mat-form-field appearance="fill">
                        <mat-label>Code Type</mat-label>
                        <mat-select name="codeType" ngModel #codeType="ngModel" [(ngModel)]="selectedCodeType">
                            <mat-option *ngFor="let item of codeTypes" [value]="item?.value">{{ item?.name }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!-- <div class="flex-shrink-1 p-1">
                    <mat-form-field appearance="fill">
                        <mat-label>Service item type</mat-label>
                        <mat-select name="serviceItemType" ngModel #serviceItemType="ngModel" [(ngModel)]="selectedServiceItemType">
                            <mat-option *ngFor="let item of serviceItemTypes" [value]="item?.item_type">{{ item?.item_type }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div> -->
                <div class="flex-shrink-1 p-1">
                    <mat-form-field appearance="fill">
                        <mat-label>Search by</mat-label>
                        <mat-select name="searchBy" [(ngModel)]="searchBy">
                            <mat-option value="1">Text</mat-option>
                            <mat-option value="2">Code</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="w-100 p-1">
                    <div>
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Search Services</mat-label>
                            <input matInput name="service" (click)="$event.target.select()" [(ngModel)]="enteredServiceName" (keyup)="getChangeMasterLookup($event)" [matAutocomplete]="autoService" type="text" autocomplete="off">
                            <mat-autocomplete #autoService="matAutocomplete">
                                <mat-option *ngFor="let item of chargeMasterServicesList" [value]="item?.test_name" (click)="serviceSelectedFromChargeMaster(item)">
                                    <span>{{ item?.test_name }}</span>
                                    <div class="d-flex" style="font-size: smaller;">
                                        <div>{{ codeTypes[item?.code_type].name }}-{{ item?.cpt_code }}</div>
                                        <div class="mx-4"></div>
                                        <div class="font-weight-bold">
                                            {{ item?.std_price_ip | currency:'USD' }}
                                        </div>
                                    </div>
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div class="text-right">
                        <a href="javascript:void(0)" (click)="openTestDialog()">Not provided by hospital(may be billed separately) +</a>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="addedChildrenServicesList.length > 0; index as i">
                <!-- <div>
                    <p class="h5 custom-primary">Selected Item/Services</p>
                    <div class="row">
                        <div class="col-md-4 mt-4" *ngFor="let item of addedChildrenServicesList">
                            <div class="card p-3">
                                <div class="d-flex align-items-center">
                                    <div class="w-100 trimText">
                                        <p class="h6 m-0" [matTooltip]="item?.test_name">{{ item?.test_name }}</p>
                                    </div>
                                    <div class="flex-shrink-1">
                                        <button mat-icon-button (click)="removeChildren(item)">
                                            <mat-icon aria-hidden="false">close</mat-icon>
                                        </button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <p>{{ codeTypes[item?.code_type].name }}/{{ item?.cpt_code }}</p>
                                    </div>
                                    <div class="col">
                                        <a href="javascript:void(0)" *ngIf="item?.std_price_ip || item?.std_price_op" (click)="viewServiceDetailDialog(item)">View details</a>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 text-center" *ngIf="showIP">
                                        <p class="m-0 h6 custom-primary" style="font-weight: 500;">{{ item?.std_price_ip ? (item?.std_price_ip | currency:'USD') : 'NPBH' }}</p>
                                        <p class="m-0" style="font-size: smaller;">Standard price (IP)</p>
                                    </div>
                                    <div class="col-md-6 text-center" *ngIf="showOP">
                                        <p class="m-0 h6 custom-primary" style="font-weight: 500;">{{ item?.std_price_op ? (item?.std_price_op | currency:'USD') : 'NPBH' }}</p>
                                        <p class="m-0" style="font-size: smaller;">Standard price (OP)</p>
                                    </div>
                                </div>
                                <div class="my-3">
                                    <mat-slide-toggle color="primary" name="isServicable" #isServicable="ngModel" ngModel [(ngModel)]="item.isServicable">Offered service</mat-slide-toggle>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-md-6">
                                        <mat-form-field appearance="fill">
                                            <mat-label>Service item type</mat-label>
                                            <mat-select ngModel name="serviceItemType" #serviceItemType="ngModel" [(ngModel)]="item.item_type" required>
                                                <mat-option *ngFor="let item of serviceItemTypes" [value]="item?.item_type">{{ item?.item_type }}</mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="serviceItemType.touched && serviceItemType.invalid">
                                                <mat-error *ngIf="serviceItemType.errors.required">Service item type is required</mat-error>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-6">
                                        <mat-form-field appearance="fill">
                                            <mat-label>Select physicians</mat-label>
                                            <mat-select ngModel name="physicians" #physicians="ngModel" multiple [(ngModel)]="item.doctors">
                                                <mat-option *ngFor="let item of physiciansList" [value]="item">Dr. {{item.firstName}} {{item.lastName}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                
                <div class="mt-5">
                    <mat-panel-title class="custom-primary" style="font-weight: 500;">Added Item/Services</mat-panel-title>
                    <p-table [value]="addedChildrenServicesList" [showCurrentPageReport]="true"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
                        <ng-template pTemplate="header" let-columns>
                            <tr class="align-baseline">
                                <th>CMS Shoppable Service Name</th>
                                <th style="width: 8rem;">Service Code</th>
                                <th style="width: 6rem;">Offered Service</th>
                                <th style="width: 12rem;">Service Item Type</th>
                                <th style="width: 8rem;">Standard Price (IP)</th>
                                <th style="width: 8rem;">Standard Price (OP)</th>
                                <th>Physicians</th>
                                <th style="width: 6rem;">Options</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-element>
                            <tr>
                                <td>{{element?.test_name}}</td>
                                <td>{{ codeTypes[element?.code_type].name }}-{{ element?.cpt_code }}</td>
                                <td>
                                    <mat-slide-toggle color="primary" name="isServicable" #isServicable="ngModel" ngModel [(ngModel)]="element.isServicable"></mat-slide-toggle>
                                </td>
                                <td>
                                    <mat-form-field appearance="fill">
                                        <mat-select ngModel name="serviceItemType" #serviceItemType="ngModel" [(ngModel)]="element.item_type" required>
                                            <mat-option *ngFor="let item of serviceItemTypes" [value]="item?.item_type">{{ item?.item_type }}</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="serviceItemType.touched && serviceItemType.invalid">
                                            <mat-error *ngIf="serviceItemType.errors.required">Service item type is required</mat-error>
                                        </mat-error>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <ng-container *ngIf="element?.isServicable == false; else otherPricesIP">
                                        <span>N/A</span>
                                    </ng-container>
                                    <ng-template #otherPricesIP>
                                        <span>{{ element?.std_price_ip ? (element?.std_price_ip | currency:'USD') : 'N/A' }}</span>
                                    </ng-template>
                                </td>
                                <td>
                                    <ng-container *ngIf="element?.isServicable == false; else otherPricesOP">
                                        <span>N/A</span>
                                    </ng-container>
                                    <ng-template #otherPricesOP>
                                        <span>{{ element?.std_price_op ? (element?.std_price_op | currency:'USD') : 'N/A' }}</span>
                                    </ng-template>
                                </td>
                                <td>
                                    <mat-form-field appearance="fill">
                                        <mat-select ngModel name="physicians" #physicians="ngModel" multiple [(ngModel)]="element.doctors">
                                            <mat-option *ngFor="let item of physiciansList" [value]="item">Dr. {{item.firstName}} {{item.lastName}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <!-- <button mat-icon-button (click)="removeChildren(item)">
                                        <mat-icon aria-hidden="false">close</mat-icon>
                                    </button> -->
                                    <button mat-icon-button [matMenuTriggerFor]="menu1" class="text-muted">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menu1="matMenu">
                                        <button mat-menu-item (click)="viewServiceDetailDialog(element)">View Details</button>
                                        <button mat-menu-item (click)="removeChildren(element)">Remove From List</button>
                                    </mat-menu>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </ng-container>

            <div class="mt-4 text-right">
                <button mat-raised-button color="primary" type="button" (click)="submitChildrenServices()" [hidden]="addedChildrenServicesList.length == 0">Save details</button>
            </div>

            <ng-container *ngIf="tempOldChildrenList.length > 0">
                <div class="mt-5">
                    <mat-accordion>
                        <mat-expansion-panel class="card" expanded="true" style="box-shadow: none;">
                            <mat-expansion-panel-header>
                                <mat-panel-title class="custom-primary" style="font-weight: 500;">Added Item/Services</mat-panel-title>
                                <mat-panel-description>
                                  <!-- Type your name and age -->
                                </mat-panel-description>
                            </mat-expansion-panel-header>

                            <p-treeTable #childrenTable [value]="tempOldChildrenList" [paginator]="true" [rows]="50" [rowsPerPageOptions]="[50, 100]" (onPage)="paginatorChanged($event)"
                                [showCurrentPageReport]="true" paginatorPosition="bottom" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                (onFilter)="onFilter($event)" (onNodeExpand)="onNodeExpand($event)" (onNodeCollapse)="onNodeCollapse($event)">
                                <ng-template pTemplate="header" let-columns>
                                    <tr class="align-baseline">
                                        <th>CMS Shoppable Service Name</th>
                                        <th style="width: 8rem;">Service Code</th>
                                        <th style="width: 6rem;">Offered Service</th>
                                        <th style="width: 12rem;">Service Item Type</th>
                                        <th style="width: 8rem;">Standard Price (IP)</th>
                                        <th style="width: 8rem;">Standard Price (OP)</th>
                                        <th>Physicians</th>
                                        <th style="width: 6rem;">Options</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
                                    <tr>
                                        <td>
                                            <div class="d-flex">
                                                <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                                                <span>{{rowData?.test_name}}</span>
                                            </div>
                                        </td>
                                        <td>{{rowData?.new_code}}</td>
                                        <td>{{rowData?.isServicable == true ? "Yes" : "No"}}</td>
                                        <td>{{rowData?.item_type}}</td>
                                        <td>
                                            <ng-container *ngIf="rowData?.isServicable == false; else otherPricesIP">
                                                <span>N/A</span>
                                            </ng-container>
                                            <ng-template #otherPricesIP>
                                                <span>{{ rowData?.std_price_ip ? (rowData?.std_price_ip | currency:'USD') : 'N/A' }}</span>
                                            </ng-template>
                                        </td>
                                        <td>
                                            <ng-container *ngIf="rowData?.isServicable == false; else otherPricesOP">
                                                <span>N/A</span>
                                            </ng-container>
                                            <ng-template #otherPricesOP>
                                                <span>{{ rowData?.std_price_op ? (rowData?.std_price_op | currency:'USD') : 'N/A' }}</span>
                                            </ng-template>
                                        </td>
                                        <td>
                                            <div class="d-flex flex-wrap" style="font-weight: 500;" *ngIf="rowData?.doctors.length > 0">
                                                <span *ngFor="let item of rowData?.doctors; index as i">
                                                    <span *ngIf="i < 4" class="bg-custom-primary-light rounded-circle p-2 border border-muted ml-n2" style="font-size: 12px;">{{item?.firstName.charAt(0).toUpperCase()}}{{item?.lastName.charAt(0).toUpperCase()}}</span>
                                                </span>
                                                <span *ngIf="rowData?.doctors.length > 4"> +{{rowData?.doctors.length - 4}} more</span>
                                            </div>
                                        </td>
                                        <td>
                                            <button mat-icon-button [matMenuTriggerFor]="menu" class="text-muted">
                                                <mat-icon>more_vert</mat-icon>
                                            </button>
                                            <mat-menu #menu="matMenu">
                                                <button mat-menu-item (click)="editTestDialog(false, rowData)">Edit</button>
                                                <button mat-menu-item (click)="deleteService(rowData)">Delete</button>
                                            </mat-menu>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-treeTable>

                            <!-- <div class="row">
                                <div class="col-md-4 mt-4" *ngFor="let item of tempOldChildrenList">
                                    <div class="card p-3">
                                        <div class="d-flex align-items-center">
                                            <div class="w-100 trimText">
                                                <p class="h6 m-0" [matTooltip]="item?.test_name">{{ item?.test_name }}</p>
                                            </div>
                                            <div class="flex-shrink-1"></div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <p>{{ codeTypes[item?.code_type].name }}/{{ item?.cpt_code }}</p>
                                            </div>
                                            <div class="col"></div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 text-center" *ngIf="showIP">
                                                <p class="m-0 h6 custom-primary" style="font-weight: 500;">{{ item?.std_price_ip ? (item?.std_price_ip | currency:'USD') : 'NPBH' }}</p>
                                                <p class="m-0" style="font-size: smaller;">Standard price (IP)</p>
                                            </div>
                                            <div class="col-md-6 text-center" *ngIf="showOP">
                                                <p class="m-0 h6 custom-primary" style="font-weight: 500;">{{ item?.std_price_op ? (item?.std_price_op | currency:'USD') : 'NPBH' }}</p>
                                                <p class="m-0" style="font-size: smaller;">Standard price (OP)</p>
                                            </div>
                                        </div>
                                        <div class="row mt-4">
                                            <div class="col-md-6">
                                                <p style="font-weight: 500;" class="custom-purple">{{item?.item_type}}</p>
                                            </div>
                                            <div class="col-md-6">
                                                <p style="font-weight: 500;" class="custom-purple">{{item?.isServicable == 1 ? 'Offered service' : 'Not offered service'}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->

                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </ng-container>
            
        </mat-card-content>
        <!-- <mat-card-actions class="text-right p-2">
            <button mat-raised-button color="primary" type="button" (click)="submitChildrenServices()" [hidden]="addedChildrenServicesList.length == 0">Save details</button>
        </mat-card-actions> -->
    </mat-card>
</div>