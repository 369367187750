<form #addServiceForm="ngForm" (ngSubmit)="submitPhysicianService()">
    <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
        <h2 class="headline m-0" fxFlex="auto">Add Physician service</h2>
        <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <mat-divider class="mb-4"></mat-divider>

    <mat-dialog-content fxLayout="column">

        <div class="row">
            <div class="col-md-6">
                <mat-form-field appearance="fill">
                    <mat-label>Code type</mat-label>
                    <mat-select name="codeType" ngModel #codeType="ngModel" [(ngModel)]="serviceObj.codeType" required>
                        <mat-option *ngFor="let item of codeTypes" [value]="item?.value">{{ item?.name }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="codeType.touched && codeType.invalid">
                        <mat-error *ngIf="codeType.errors.required">Code type is required</mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="fill">
                    <mat-label>Service code</mat-label>
                    <input matInput name="itemCode" ngModel #itemCode="ngModel" type="text" [(ngModel)]="serviceObj.itemCode" required>
                    <mat-error *ngIf="itemCode.touched && itemCode.invalid">
                        <mat-error *ngIf="itemCode.errors.required">Item code is required</mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <p>
            <mat-form-field appearance="fill">
                <mat-label>Service name</mat-label>
                <input matInput name="serviceName" [(ngModel)]="serviceObj.serviceName" (keyup)="getServicesLookup($event.target.value, serviceObj.codeType)" [formControl]="serviceNameControl" [matAutocomplete]="auto" type="text" autocomplete="off" required>
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let item of servicesList" [value]="item.test_name" (click)="selectService(item)">
                    {{ item.test_name }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </p>
        <p>
            <mat-form-field appearance="fill">
                <mat-label>Short description</mat-label>
                <textarea matInput name="shortDesc" ngModel #shortDesc="ngModel" name="shortDesc" type="text" [(ngModel)]="serviceObj.shortDesc" required></textarea>
                <mat-error *ngIf="shortDesc.touched && shortDesc.invalid">
                    <mat-error *ngIf="shortDesc.errors.required">Short description is required</mat-error>
                </mat-error>
            </mat-form-field>
        </p>
        <p>
            <mat-form-field appearance="fill">
                <mat-label>Description</mat-label>
                <textarea matInput rows="4" name="description" ngModel #description="ngModel" name="description" type="text" [(ngModel)]="serviceObj.description"></textarea>
                <mat-error *ngIf="description.touched && description.invalid">
                    <mat-error *ngIf="description.errors.required">Description is required</mat-error>
                </mat-error>
            </mat-form-field>
        </p>
        <div class="row">
            <div class="col-md-6">
                <mat-form-field appearance="fill">
                    <mat-label>Standard price-IP</mat-label>
                    <input matInput name="standardPriceIP" ngModel #standardPriceIP="ngModel" type="number" [(ngModel)]="serviceObj.standardPriceIP">
                    <mat-error *ngIf="standardPriceIP.touched && standardPriceIP.invalid">
                        <mat-error *ngIf="standardPriceIP.errors.required">Standard price-IP is required</mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="fill">
                    <mat-label>Standard price-OP</mat-label>
                    <input matInput name="standardPriceOP" ngModel #standardPriceOP="ngModel" type="number" [(ngModel)]="serviceObj.standardPriceOP">
                    <mat-error *ngIf="standardPriceOP.touched && standardPriceOP.invalid">
                        <mat-error *ngIf="standardPriceOP.errors.required">Standard price-OP is required</mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <mat-form-field appearance="fill">
                    <mat-label>Cash price-IP</mat-label>
                    <input matInput name="cashPriceIP" ngModel #cashPriceIP="ngModel" type="number" [(ngModel)]="serviceObj.cashPriceIP">
                    <mat-error *ngIf="cashPriceIP.touched && cashPriceIP.invalid">
                        <mat-error *ngIf="cashPriceIP.errors.required">Cash price-IP is required</mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="fill">
                    <mat-label>Cash price-OP</mat-label>
                    <input matInput name="cashPriceOP" ngModel #cashPriceOP="ngModel" type="number" [(ngModel)]="serviceObj.cashPriceOP">
                    <mat-error *ngIf="cashPriceOP.touched && cashPriceOP.invalid">
                        <mat-error *ngIf="cashPriceOP.errors.required">Cash price-OP is required</mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="mb-4">
            <mat-slide-toggle color="primary" name="isBundled" #isBundled="ngModel" ngModel [(ngModel)]="serviceObj.isBundled">Bundled service</mat-slide-toggle>
        </div>
        <div *ngFor="let item of docInsuranceList; index as i">
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field appearance="fill">
                        <mat-label>{{item?.carrier_name}}-IP</mat-label>
                        <input matInput name="{{item?.carrier_id}}ip" type="number" [(ngModel)]="docInsuranceList[i].ip_price">
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field appearance="fill">
                        <mat-label>{{item?.carrier_name}}-OP</mat-label>
                        <input matInput name="{{item?.carrier_id}}op" type="number" [(ngModel)]="docInsuranceList[i].op_price">
                    </mat-form-field>
                </div>
            </div>
        </div>

    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button [disabled]="addServiceForm.form.invalid" color="primary" mat-button type="submit">Save</button>
        <button mat-button mat-dialog-close type="button">Cancel</button>
    </mat-dialog-actions>
</form>