<div fxLayout="row" fxLayoutAlign="start start" mat-dialog-title>
    <div fxLayout="column" fxFlex="auto">
        <p>{{ codeTypes[selectedServiceObj?.code_type].name }}/{{ selectedServiceObj?.cpt_code }}</p>
        <h2 class="headline m-0">{{selectedServiceObj?.test_name}}</h2>
    </div>
    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
        <mat-icon>close</mat-icon>
    </button>
</div>

<mat-divider class="mb-4"></mat-divider>

<mat-dialog-content fxLayout="column">

    <!-- <div class="row">
        <div class="col-md-6">
            <p>Service Item Type</p>
        </div>
        <div class="col-md-6">
            <p>{{selectedServiceObj?.item_type}}</p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <p>Bundled Service</p>
        </div>
        <div class="col-md-6">
            <p>{{selectedServiceObj?.is_bundled == true ? 'Yes' : 'No'}}</p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <p>Servicable</p>
        </div>
        <div class="col-md-6">
            <p>{{selectedServiceObj?.isServicable == 1 ? 'Yes' : 'No'}}</p>
        </div>
    </div> -->

    <p-table #chargeMasterTable styleClass="p-datatable-gridlines" [columns]="columns" [value]="pricesList">
        <ng-template pTemplate="colgroup" let-columns>
            <colgroup>
                <col *ngFor="let col of columns">
            </colgroup>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns">{{col.header}}</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
                <td *ngFor="let col of columns">
                    <span *ngIf="col.field == 'name'; else priceColumns">
                        {{rowData[col.field]}}
                    </span>
                    <ng-template #priceColumns>
                        {{rowData[col.field] | currency:'USD'}}
                    </ng-template>
                </td>
            </tr>
        </ng-template>
    </p-table>
</mat-dialog-content>