import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { HttpCallService } from 'src/app/services/http-call.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  blogDomain = environment.blogDomain;
  @Input() snav: any;

  openLinkInNewWindow(link) {
    window.open(link, '_blank');
  }

  constructor(
    public router: Router,
    public httpCall: HttpCallService
  ) { }

  ngOnInit() {
  }


  getRouterLink(){
    const currentURL = this.router.url;
    if (currentURL === '/proLogin') {
      this.router.navigateByUrl('/proRegister/addBasicInfo')
  } else {
      this.router.navigateByUrl('/proLogin')
  }
}


  public localStorageItem() {
    return localStorage.getItem("token") ? true : false;
  }

  public getUserEmail() {
    return localStorage.getItem("userEmail");
  }

  public getProLocation() {
    return localStorage.getItem("proLocation");
  }

  rightNavHieght;
  openRightNav() {
    this.rightNavHieght = '300px';
  }

  closeRightNav() {
    this.rightNavHieght = '0px';
  }


}
