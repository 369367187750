import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent implements OnInit {

  @Input() isDisabled = false;
  @Input() default;
  @Output() timeRangeSelected = new EventEmitter<{startTime: string, endTime: string}>();

  selectedStartTime: string;
  selectedEndTime: string;
  times: string[] = [];

  ngOnInit() {
    this.generateTimes();
    this.selectedStartTime = this.default.from;
    this.selectedEndTime = this.default.to;
  }

  ngOnChanges() {
    this.selectedStartTime = this.default.from;
    this.selectedEndTime = this.default.to;
  }

  onTimeChange() {
    this.timeRangeSelected.emit({startTime: this.selectedStartTime, endTime: this.selectedEndTime});
  }

  generateTimes() {
    for(let i = 0; i < 24; i++) {
      for(let j = 0; j < 60; j+=30) {
        let hour = ("0" + i).slice(-2);
        let minute = ("0" + j).slice(-2); 
        this.times.push(`${hour}:${minute}`);
      }
    }
  }
  
}
