<app-toolbar></app-toolbar>
<div class="container">
    <div class="row">
        <div class="col-md-12">
            <h1 ><i class="pi pi-arrow-left" (click)="back()" style="cursor: pointer !important;"></i></h1>
        </div>
        <div class="col-md-12">
            <h1>Preview ZeaCard</h1>
        </div>
        <!-- <div class="col-md-12" *ngIf="!!cards">
            <div *ngFor="let card of cards">
                <div class="row">
                    <div class="col-md-2">
                        
                    </div>
                    <div class="col-md-5">
                        <img [src]="card.front_preview" alt="">
                    </div>
                    <div class="col-md-5">
                        <img [src]="card.preview_card" alt="">
                    </div>
                </div>
            </div>
        </div> -->
        <div class="container" *ngIf="galleryImgList.length > 0">
            <div class="row">
                <div class="col-md-5">
                    <app-zeacard-gallery [galleryImgList]="galleryImgList"></app-zeacard-gallery>
                    <p class="h6 text-muted">Price Includes</p>

                    <div class="d-flex flex-wrap mt-2">
                        <div class="mr-2" *ngFor="let item of cards?.salesOrder_conf?.price_includes">
                            <p class="m-0 px-2 py-1 fw-5 bg-custom-primary-light border border-dark rounded-pill mb-2">{{item}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-7">
                    <p class="h4 m-0">{{cards?.salesOrder_conf?.ss_name | titlecase}}</p>
                    <div>
                        <span class="mr-2">Price: </span>
                        <span class="h4 custom-primary">{{cards?.salesOrder_conf?.actual_price | currency:'USD':'symbol':'1.0'}}</span>
                    </div>
                    <div class="mt-3">
                        <div class="d-flex align-items-center mb-2">
                            <p class="m-0 h6 fw-6 mr-3">Provider accept the ZeaCard</p>
                            <button *ngIf="cards?.providers.length > 4" mat-stroked-button class="rounded" (click)="openAvailableProvidersModal(cards)">View All ({{cards.providers.length}})</button>
                        </div>
                        <div class="row">
                            <ng-container *ngFor="let item of cards?.providers; index as i">
                                <div class="col-md-6 px-1" *ngIf="i < 4">
                                    <div class="card mb-2">
                                        <div class="p-2">
                                            <p class="h6 m-0" style=" white-space: nowrap; width: 250px; overflow: hidden;text-overflow: ellipsis; ">{{item?.name}}</p>
                                            <star-rating [score]="item?.rating" [forDisplay]="true"></star-rating>
                                            <p class="text-muted trimText">{{item?.geo_location_addr}}</p>
                                            <div class="d-flex justify-content-between align-items-end" >
                                                <button mat-flat-button class="bg-custom-primary-light custom-primary-dark rounded fs-12" (click)="getDirectionsClicked(item?.location)">Get Directions</button>
                                                <img *ngIf="item?.providerLogo.length != 0" [src]="getProviderLogo(item)" style="max-width: 120px; cursor: pointer;" [routerLink]="['/zeacards/provider-card-results', item?._id, item?.name]"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>