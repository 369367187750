

<app-toolbar></app-toolbar>

<section class="container mt-4">
    <p class="step-title">Machine Readable Format</p>
    <mat-card class="mat-elevation-z2">
        <mat-card-content>
            <div class="d-flex justify-content-between">
                <div>
                    <mat-form-field appearance="fill">
                        <mat-label>Format type</mat-label>
                        <mat-select name="codeType" ngModel #codeType="ngModel" [(ngModel)]="selectedFormatType">
                            <mat-option *ngFor="let item of fileFormatTypes" [value]="item.value">{{ item.label }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <button mat-flat-button color="primary" class="p-2 px-4 ml-2" style="font-size:16px;vertical-align: super;" (click)="generateMachineReadableFormat()">Generate</button>
                </div>
                <div>
                    <mat-form-field appearance="fill">
                        <mat-label>Effective date</mat-label>
                        <input matInput [matDatepicker]="picker" (click)="picker.open()" name="date" ngModel [(ngModel)]="effectiveDate" placeholder="Effective Date" readonly required>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <button mat-flat-button color="primary" class="p-2 px-4 ml-2" style="font-size:16px;vertical-align: super;" (click)="updateEffectiveDate()">Update</button>
                </div>
            </div>
            
            <ng-container *ngIf="filesList.length > 0">
                <p-table #dt styleClass="p-datatable-gridlines" [value]="filesList" [filterDelay]="0"
                [paginator]="true" [rows]="5" [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>File Name</th>
                            <th style="width: 4rem;">Type</th>
                            <th class="text-truncate" style="width: 12rem;">Generated By</th>
                            <th style="width: 8rem;">Generated On</th>
                            <th style="width: 8rem;">Status</th>
                            <th style="width: 6rem;">Options</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-element>
                        <tr>
                            <td class="text-truncate" style="font-weight: 500;">
                                <span>{{ element?.fileUrl ? element?.fileName : 'Processing...' }}</span>
                            </td>
                            <td>{{element?.fileType}}</td>
                            <td>{{element?.firstName}} {{element?.lastName}}</td>
                            <td>{{element?.createdOn | date:'mediumDate'}}  {{ element?.createdOn | date:'shortTime' }}</td>
                            <td>
                                <mat-slide-toggle [hidden]="!element?.fileUrl" color="primary" [checked]="element.is_active" (click)="changeFileStatus(element)">Active</mat-slide-toggle>
                            </td>
                            <td>
                                <ng-container *ngIf="element?.fileUrl; else stillGenerating">
                                    <button mat-icon-button color="primary" (click)="downloadFile(element)"><mat-icon>get_app</mat-icon></button>
                                    <!-- <button mat-icon-button class="text-muted"><mat-icon>delete</mat-icon></button> -->
                                </ng-container>
                                <ng-template #stillGenerating>
                                    <img src="assets/img/processing.gif" width="32" height="32"/>
                                </ng-template>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </ng-container>
            
        </mat-card-content>
    </mat-card>
    <div class="mt-4">
        <button class="float-right" mat-raised-button color="primary" (click)="saveStep()">Save & Proceed</button>
        <!-- <button class="float-right mr-2" mat-raised-button color="primary" (click)="saveStep6(true)">Save as draft</button> -->
    </div>
</section>
