<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Shoppable Service Name</mat-label>
                <input matInput name="serviceName" [(ngModel)]="globalSearchInputValue" (keyup)="getServicesLookup()" [formControl]="serviceNameControl" [matAutocomplete]="auto" type="text" autocomplete="off" required>
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let item of servicesList" [value]="item?.name" (click)="selectService(item)">
                    {{ item?.name }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Quantity</mat-label>
                <input [(ngModel)]="quantity" matInput placeholder="Placeholder" required>
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Category</mat-label>
                <mat-select>
                  <mat-option *ngFor="let category of categories" [value]="category.name" (click)="selectCategory(category)">
                    {{category.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
        </div>
        <div class="col-md-6 pb-3 position-relative">
            <label for="from">From Date</label>
            <p-calendar id="from" placeholder="From" [(ngModel)]="selectedFromDate" (onSelect)="onFromDateSelected($event)"
            dateFormat="yy-mm-dd" timeFormat="HH:mm:ss.SSSZ" [minDate]="minDate"></p-calendar>
        </div>
        <div class="col-md-6 pb-3">
            <label for="to">To Date</label>
            <p-calendar id="to" placeholder="To" [(ngModel)]="selectedToDate" [readonlyInput]="true" class="w-100"
            dateFormat="yy-mm-dd" timeFormat="HH:mm:ss.SSSZ" [minDate]="minDate"></p-calendar>
        </div>
        <div class="col-md-6">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Discount</mat-label>
                <input matInput [(ngModel)]="discount" placeholder="Placeholder" required>
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <div class="d-flex flex-row justify-content-end w-100">
                <button mat-raised-button class="bg-custom-blue py-2 mt-1" style="color: #FFFFFF;" (click)="createInventory()">Create Inventory</button>
            </div>
        </div>
    </div>
</div>
