import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot, Route, CanLoad } from '@angular/router';
import { HttpCallService } from './http-call.service';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor() { }
}

@Injectable({
  providedIn: 'root'
})
export class ProviderGuardService implements CanActivate, CanLoad {

  constructor( private router: Router, public httpCall: HttpCallService ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // console.log("State",state.url)
    // const currentUser = this.httpCall.currentUserAccess;
    // if (currentUser) {
    //   // check if route is restricted by role
    //   if (route.data.roles && route.data.roles.indexOf(currentUser.role) === -1) {
    //     // role not authorised so redirect to home page
    //     this.router.navigate(['/']);
    //     return false;
    //   }
    //   // authorised so return true
    //   return true;
    // }
    // // not logged in so redirect to login page with the return url
    // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    // return false;


    if(localStorage.getItem('token')|| localStorage.getItem('refreshToken')) {
      this.httpCall.getProfile('Provider', environment.basicDomain).subscribe(res => {
        if(res['success']) {
          return true;
        } else {
          // localStorage.clear();
          this.router.navigate(['/proLogin'],{queryParams:{'redirect':state.url}});
          return false;
        }
      }, (err) => {
        // localStorage.clear();
        this.router.navigate(['/proLogin'],{queryParams:{'redirect':state.url}});
        // this.router.navigate(['/proLogin']);
      });
      return true;
    } else {
      // localStorage.clear();
      this.router.navigate(['/proLogin'],{queryParams:{'redirect':state.url}});
      // this.router.navigate(['/proLogin']);
      return false;
    }
  }

  canLoad(route: Route): boolean {
    if(localStorage.getItem('token') || localStorage.getItem('refreshToken')) {
      this.httpCall.getProfile('Provider', environment.basicDomain).subscribe(res => {
        if(res['success']) {
          return true;
        }
        else {
          localStorage.clear();
          this.router.navigate(['/proLogin']);
          return false;
        }
      }, (err) => {
        localStorage.clear();
        this.router.navigate(['/proLogin']);
      });
      return true;
    } else {
      localStorage.clear();
      this.router.navigate(['/proLogin']);
      return false;
    }
  }

  // canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  //   if(localStorage.getItem('token')) {
  //     this.httpCall.getProfile('Provider', environment.basicDomain).subscribe(res => {
  //       if(res['success']) {
  //         return true;
  //       }
  //       else {
  //         localStorage.clear();
  //         this.router.navigate(['/proLogin'], {queryParams: {redirectUrl: state.url}});
  //         return false;
  //       }
  //     }, (err) => {
  //       localStorage.clear();
  //       this.router.navigate(['/proLogin'], {queryParams: {redirectUrl: state.url}});
  //     });
  //     return true;
  //   } else {
  //     localStorage.clear();
  //     this.router.navigate(['/proLogin'], {queryParams: {redirectUrl: state.url}});
  //     return false;
  //   }
  // }

  // Working Old Code 15th Oct 2020 ------------------------
  // canActivate() {
  //   if(localStorage.getItem('token')) {
  //     this.service.getProfile('Provider', environment.basicDomain).subscribe(res => {
  //       if(res['success']) {
  //         return true;
  //       } else {
  //         localStorage.clear();
  //         this.router.navigate(['/proLogin']);
  //         return false;
  //       }
  //     }, (err) => {
  //       localStorage.clear();
  //       this.router.navigate(['/proLogin']);
  //     });
  //     return true;
  //   } else {
  //     localStorage.clear();
  //     this.router.navigate(['/proLogin']);
  //     return false;
  //   }
  // }
  
}