import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

interface DialogData {
  dataObj?: any;
}

@Component({
  selector: 'app-view-explain-video',
  templateUrl: './view-explain-video.component.html',
  styleUrls: ['./view-explain-video.component.scss']
})
export class ViewExplainVideoComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<ViewExplainVideoComponent>,
    private sanitizer: DomSanitizer
  ) { 
    
  }

  urlLink = this.sanitizer.bypassSecurityTrustResourceUrl(this.data?.dataObj?.videoLink);
  // getVideoLink() {
  //   return this.sanitizer.bypassSecurityTrustResourceUrl(this.data?.dataObj?.videoLink);
  // }

  ngOnInit(): void {
    // document.getElementById("myVideo")
  }

}
