import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateConversion'
})
export class DateConversionPipe implements PipeTransform {

  // transform(value: unknown, ...args: unknown[]): unknown {
  //   return null;
  // }

  transform(value: Date) :String {
    let changedTime = moment.parseZone(value).format('hh:mm A');
    return changedTime;
  }

}
