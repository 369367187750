<form #updateForm="ngForm" (ngSubmit)="submitRuleDetails()">
    <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
        <h2 class="headline m-0" fxFlex="auto">Update rule engine</h2>
        <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <mat-divider class="mb-4"></mat-divider>

    <mat-dialog-content fxLayout="column">
        <p>
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Status</mat-label>
                <mat-select name="status" [(ngModel)]="ruleObj.status">
                    <mat-option value="true">Pass</mat-option>
                    <mat-option value="false">Fail</mat-option>
                </mat-select>
            </mat-form-field>
        </p>
        <p>
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Reason</mat-label>
                <textarea matInput rows="4" name="description" ngModel #description="ngModel" name="description" type="text" [(ngModel)]="ruleObj.message" required></textarea>
                <mat-error *ngIf="description.touched && description.invalid">
                    <mat-error *ngIf="description.errors.required">Reason is required</mat-error>
                </mat-error>
            </mat-form-field>
        </p>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button [disabled]="updateForm.form.invalid" color="primary" mat-button type="submit">Update</button>
        <button mat-button mat-dialog-close type="button">Cancel</button>
    </mat-dialog-actions>
</form>