import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpCallService } from 'src/app/services/http-call.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { environment } from 'src/environments/environment.prod';

interface DialogData {
  selectedRuleItem?: any;
}

@Component({
  selector: 'app-update-rule-engine',
  templateUrl: './update-rule-engine.component.html',
  styleUrls: ['./update-rule-engine.component.scss']
})
export class UpdateRuleEngineComponent implements OnInit {

  ruleObj = {
    id: this.data.selectedRuleItem.id,
    status: this.data.selectedRuleItem.status.toString(),
    message: this.data.selectedRuleItem.errorText
  };
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<UpdateRuleEngineComponent>,
    private snackbar: MatSnackBar,
    private spinner: SpinnerService,
    private httpCall: HttpCallService
  ) { }

  ngOnInit(): void { }

  submitRuleDetails() {
    let body = {
      id: this.ruleObj.id,
      status: this.ruleObj.status == 'true' ? true : false,
      message: this.ruleObj.message
    };
    this.spinner.showSpinner('Loading...Please Wait');
    this.httpCall.postAuth(`${environment.ruleEngineDomain}/BackEnd/fixRule`, body).subscribe((res: any) => {
      this.spinner.stopSpinner();
      if(res['success']) {
        this.snackbar.open(res['message'], 'Success', {
          duration: 5000,
          panelClass: 'snackBar-success'
        });
        this.dialogRef.close(res);
      } else {
        this.snackbar.open(res['message'], 'Failed', {
          duration: 5000,
          panelClass: 'snackBar-failure'
        });
      }
    }, err => {
      this.spinner.stopSpinner();
      this.snackbar.open(err.error['message'], 'Failed', {
        duration: 5000,
        panelClass: 'snackBar-failure'
      });
    });
  }

}
