import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { HttpCallService } from 'src/app/services/http-call.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { AddEditMandatoryServicesComponent } from '../add-edit-mandatory-services/add-edit-mandatory-services.component';
import { SelectItem } from 'primeng/api';
import * as _ from 'underscore';
import * as crypto from 'crypto-js';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';
import { ViewServiceDetailComponent } from '../view-service-detail/view-service-detail.component';
import { Table } from 'primeng/table';
import { SpinnerService } from 'src/app/services/spinner.service';
declare var $: any;

@Component({
  selector: 'app-add-multi-children',
  templateUrl: './add-multi-children.component.html',
  styleUrls: ['./add-multi-children.component.scss']
})
export class AddMultiChildrenComponent implements OnInit {

  backToPreviousPage() {
    this.location.back();
  }

  toggleSelectAll(selectAllValue: boolean, selectedItem) {
    if(selectAllValue) {
      selectedItem.doctors = this.physiciansList;
    } else {
      selectedItem.doctors = [];
    }
  }
  myControl = new FormControl();
  filteredPhysiciansOptions: Observable<string[]>;

  // serviceItemTypeControl = new FormControl();
  // filteredServiceItemTypesOptions: Observable<string[]>;

  searchBy = '1';
  selectedCodeType = 1;
  codeTypes = environment.codeTypes;

  enteredServiceName;

  queryParamsData: any;

  constructor(
    public httpCall: HttpCallService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private snackbar: MatSnackBar,
    private spinner: SpinnerService,
    private location: Location
  ) {
    this.activatedRoute.queryParams.subscribe( params => {
      this.queryParamsData = params;
    });
  }

  
  serviceItemTypes = [
    { item_type: 'Facility Fee' },
    { item_type: 'Drug Fee' },
    { item_type: 'Physician Fee' },
    { item_type: 'Other Fee' },
  ];

  ngOnInit(): void {
    this.getServiceDetails(this.queryParamsData.serviceID);
    // this.getServiceItemTypes(localStorage.getItem('proID'));
    this.getPhysicians(localStorage.getItem('proID'));
    this.getProviderInsurances(localStorage.getItem('proID'));

    // this.filteredServiceItemTypesOptions = this.serviceItemTypeControl.valueChanges.pipe( startWith(''), map(value => this.filterServiceItemTypes(value)) );
    // this.filteredPhysiciansOptions = this.myControl.valueChanges.pipe( startWith(''), map(value => this.filterLookup(value)) );
  }

  // filterLookup(value: string) {
  //   const filterValue = value.toLowerCase();
  //   console.log(filterValue)
  //   return this.physiciansList.filter(element => element.firstName.toLowerCase().includes(filterValue));
  // }

  // filterServiceItemTypes(value: string) {
  //   const filterValue = value.toLowerCase();
  //   return this.serviceItemTypes.filter(element => element.item_type.toLowerCase().includes(filterValue));
  // }

  getEachTabCount(name) {
    if(name == 'All') {
      return this.addedChildrenServicesList.length > 0 ? this.addedChildrenServicesList.length : 0;
    } else {
      let filtered = this.addedChildrenServicesList.filter( element => element.item_type == name);
      return filtered.length > 0 ? filtered.length : 0;
    }
  }

  feeTypeButtons = [
    // { name: 'All', value: 0 },
    // { name: 'Facility Fee', value: 1 },
    // { name: 'Drug Fee', value: 2 },
    // { name: 'Physician Fee', value: 3 },
    // { name: 'Other Fee', value: 4 },
  ];

  selectedFeeButton = 'All';
  feeTypeButtonSelected(selectedItem) {
    this.selectedFeeButton = selectedItem.name;
    this.tempOldChildrenList = this.oldServicesList;
    if(this.selectedFeeButton != 'All') {
      let filtered = this.tempOldChildrenList.filter( element => element.data.item_type == this.selectedFeeButton);
      this.tempOldChildrenList = filtered;
    }
    this.makeRowsSameHeight();
  }

  serviceObj: any;
  getServiceDetails(serviceID) {
    this.spinner.showSpinner('Loading...Please Wait');
    this.httpCall.get(`${environment.providerDomain}/BackEnd/fetchNewSingleProviderTest/${serviceID}`).subscribe( res => {
      this.spinner.stopSpinner();
      if(res['success']) {
        this.serviceObj = res['response'][0];
        if(this.serviceObj.superKey && this.serviceObj.parentId) {
          this.feeTypeButtons.push(
            { name: 'All', value: 0 },
            { name: 'Facility Fee', value: 1 },
            { name: 'Drug Fee', value: 2 },
            { name: 'Physician Fee', value: 3 },
            { name: 'Other Fee', value: 4 },
          );
        } else {
          this.feeTypeButtons.push({ name: 'All', value: 0 });
          if(this.serviceObj.Facility_fee != 'NA') {
            this.feeTypeButtons.push({ name: 'Facility Fee', value: 1 });
          }
          if(this.serviceObj.Drug_fee != 'NA') {
            this.feeTypeButtons.push({ name: 'Drug Fee', value: 2 });
          }
          if(this.serviceObj.Physician_fee != 'NA') {
            this.feeTypeButtons.push({ name: 'Physician Fee', value: 3 });
          }
          if(this.serviceObj.Other_fee != 'NA') {
            this.feeTypeButtons.push({ name: 'Other Fee', value: 4 });
          }
        }
        // this.serviceObj.facilityFee = res['response'].Facility_fee;
        // this.serviceObj.drugFee = res['response'].Drug_fee;
        // this.serviceObj.physicianFee = res['response'].Physician_fee;
        // this.serviceObj.otherFee = res['response'].Other_fee;
        this.getAddedChildrenServices();
      } else {
        this.snackbar.open(res['message'], 'Failed', {
          duration: 5000,
          panelClass: 'snackBar-failure'
        });
      }
    }, (err) => {
      this.spinner.stopSpinner();
      this.snackbar.open(err.error['message'], 'Failed', {
        duration: 5000,
        panelClass: 'snackBar-failure'
      });
    });
  }

  oldChildrenList: any = [];
  tempOldChildrenList: any = [];
  getAddedChildrenServices() {
    this.selectedFeeButton = 'All';
    let body = {
      superKey: this.serviceObj.superKey && this.serviceObj.parentId ? this.serviceObj.superKey : this.serviceObj._id,
      parentId: this.serviceObj._id,
      providerId: localStorage.getItem('proID')
    };
    // this.spinner.showSpinner('Loading...Please Wait');
    // this.httpCall.getAuth(`${environment.providerDomain}/BackEnd/getItemTypePrices/${body.superKey}`).subscribe(res => {
    //   this.spinner.stopSpinner();
    //   if (res['success']) {
    //     this.oldChildrenList = res['response'];
    //     this.tempOldChildrenList = res['response'];
    //     console.log(this.oldChildrenList);
    //   }
    // }, (err) => {
    //   this.spinner.stopSpinner();
    //   this.snackbar.open(err.error['message'], 'Failed', {
    //     duration: 5000,
    //     panelClass: 'snackBar-failure'
    //   });
    // });
    this.spinner.showSpinner('Loading...Please Wait');
    this.httpCall.getAuth(`${environment.providerDomain}/providerApi/getLevelOne/${body.providerId}/${body.parentId}`).subscribe(res => {
      this.spinner.stopSpinner();
      if (res['success']) {
        this.oldServicesList = res['response'].data;
        this.tempOldChildrenList = res['response'].data;
        // this.headerColumnsArray = res['response'].head;
        this.setTableColumns();
      }
    }, (err) => {
      this.spinner.stopSpinner();
      this.snackbar.open(err.error['message'], 'Failed', {
        duration: 5000,
        panelClass: 'snackBar-failure'
      });
    });
  }

  selectedServiceItemType;
  // serviceItemTypes: any = [];
  // getServiceItemTypes(proID) {
  //   this.spinner.showSpinner('Loading...Please Wait');
  //   this.httpCall.getAuth(`${environment.providerDomain}/BackEnd/getServiceTypes/${proID}`).subscribe(res => {
  //     this.spinner.stopSpinner();
  //     if (res['success']) {
  //       this.serviceItemTypes = res['response'];
  //     } else {
  //       this.snackbar.open(res['message'], 'Failed', {
  //         duration: 5000,
  //         panelClass: 'snackBar-failure'
  //       });
  //     }
  //   }, (err) => {
  //     this.spinner.stopSpinner();
  //     this.snackbar.open(err.error['message'], 'Failed', {
  //       duration: 5000,
  //       panelClass: 'snackBar-failure'
  //     });
  //   });
  // }

  // selectedPhysicians: any = [];
  physiciansList: any = [];
  // public selectedPhysicians: Array<SelectItem> = new Array<SelectItem>();
  // physiciansList: SelectItem[];
  getPhysicians(proID) {
    let body = {
      providerId: proID
    };
    this.spinner.showSpinner('Loading...Please Wait');
    this.httpCall.postAuth(`${environment.providerDomain}/providerApi/getAllDoctors`, body).subscribe(res => {
      this.spinner.stopSpinner();
      if (res['success']) {
        this.physiciansList = res['response'];
      } else {
        this.snackbar.open(res['message'], 'Failed', {
          duration: 5000,
          panelClass: 'snackBar-failure'
        });
      }
    }, (err) => {
      this.spinner.stopSpinner();
      this.snackbar.open(err.error['message'], 'Failed', {
        duration: 5000,
        panelClass: 'snackBar-failure'
      });
    });
  }

  proInsuranceList: any = [];
  getProviderInsurances(proID) {
    this.spinner.showSpinner('Loading...Please Wait');
    this.httpCall.getAuth(`${environment.providerDomain}/BackEnd/getProviderInsurances/${proID}`).subscribe(res => {
      this.spinner.stopSpinner();
      if (res['success']) {
        this.proInsuranceList = res['response'];
      } else {
        this.snackbar.open(res['message'], 'Failed', {
          duration: 5000,
          panelClass: 'snackBar-failure'
        });
      }
    }, (err) => {
      this.spinner.stopSpinner();
      this.snackbar.open(err.error['message'], 'Failed', {
        duration: 5000,
        panelClass: 'snackBar-failure'
      });
    });
  }

  chargeMasterServicesList: any = [];
  getChangeMasterLookup(event: any) {
    if(event.target.value.length > 0) {
      let apiUrl: string;
      if(this.selectedFeeButton !== 'All') {
        apiUrl = `providerApi/masterLookup/${localStorage.getItem('proID')}/${event.target.value}?cpt=${this.selectedCodeType}&searchType=${parseInt(this.searchBy)}&serviceType=${this.selectedFeeButton}`;
      } else {
        apiUrl = `providerApi/masterLookup/${localStorage.getItem('proID')}/${event.target.value}?cpt=${this.selectedCodeType}&searchType=${parseInt(this.searchBy)}`;
      }
      this.httpCall.getAuth(`${environment.providerDomain}/${apiUrl}`).subscribe(res => {
        if (res['success']) {
          this.chargeMasterServicesList = res['response'];
        }
      }, (err) => {
        this.snackbar.open(err.error['message'], 'Failed', {
          duration: 5000,
          panelClass: 'snackBar-failure'
        });
      });
    }
  }

  totalStandardPriceIP: number = 0;
  totalStandardPriceOP: number = 0;

  addedChildrenServicesList: any = [];
  serviceSelectedFromChargeMaster(selectedService) {
    this.enteredServiceName = '';
    let newServiceObj = {
      _id: selectedService._id,
      master_key: selectedService.master_key,
      // isMandatory: this.queryParamsData.isMandatory,
      isMandatory: false,
      isServicable: !!selectedService.isServicable,
      isShoppable: true,
      is_bundled: selectedService.is_bundled,
      superKey: this.serviceObj.superKey && this.serviceObj.parentId ? this.serviceObj.superKey : this.serviceObj._id,
      parentId: this.serviceObj._id,
      provider: localStorage.getItem('proID'),
      test_name: selectedService.test_name.toUpperCase(),
      item_type: selectedService.item_type,
      code_type: selectedService.code_type,
      cpt_code: selectedService.cpt_code,
      master_id: selectedService.master_id,
      quantity: Number.isInteger(selectedService.quantity)?selectedService.quantity.toString(): '',
      std_price_ip: selectedService.std_price_ip,
      std_price_op: selectedService.std_price_op,
      cash_price_ip: selectedService.cash_price_ip,
      cash_price_op: selectedService.cash_price_op,
      outofnetwork_ip: selectedService.outofnetwork_ip,
      outofnetwork_op: selectedService.outofnetwork_op,
      min_price_ip: selectedService.min_price_ip,
      min_price_op: selectedService.min_price_op,
      max_price_ip: selectedService.max_price_ip,
      max_price_op: selectedService.max_price_op,
      short_description: selectedService.short_description,
      insurance_prices: selectedService.insurance_prices,
      cptcode_type: `${localStorage.getItem('proID')}-${selectedService.cpt_code}-${selectedService.code_type}`,
      doctors: [],
      codeKey: `${selectedService.cpt_code}-${selectedService.code_type}`
      // doctors:Joi.array().items(
      //     Joi.object().keys({
      //         doctorId:Joi.string().alphanum().allow(nu
      //         isActive:Joi.boolean().allow(null,'')
      //     })
      // ),
    };
    //let super_key=this.serviceObj.superKey && this.serviceObj.parentId ? this.serviceObj.superKey : this.serviceObj._id
    //if(_.contains(this.addedChildrenServicesList,super_key))
    let count = this.addedChildrenServicesList.filter(aa=>aa.cpt_code==selectedService.cpt_code && aa.code_type==selectedService.code_type && aa.test_name==selectedService.test_name)
    if(count==0) {
      this.addedChildrenServicesList.push(newServiceObj);
      // this.tempAddedChildrenServicesList = this.addedChildrenServicesList;
      // if(this.selectedFeeButton != 'All') {
      //   let filtered = this.tempAddedChildrenServicesList.filter( element => element.item_type == this.selectedFeeButton);
      //   this.tempAddedChildrenServicesList = filtered;
      // }
    }
    // this.selectedPhysicians = [];
  }

  showPricesType = 'both';
  showIP: boolean = true;
  showOP: boolean = true;
  radioTypeChanged(value) {
    if(value == 'ip') {
      this.showIP = true;
      this.showOP = false;
    } else if(value == 'op') {
      this.showIP = false;
      this.showOP = true;
    } else {
      this.showIP = true;
      this.showOP = true;
    }
  }

  getTotalStandardPrices() {
    let newArr = this.addedChildrenServicesList.filter( element => element.isServicable == true);
    let childStandardArrayIP = _.pluck(newArr, 'std_price_ip');
    let childStandardArrayOP = _.pluck(newArr, 'std_price_op');
    
    let oldChildStandardArrayIP = [];
    let oldChildStandardArrayOP = [];
    this.oldServicesList.forEach(element => {
      if(element.data.isServicable == true) {
        oldChildStandardArrayIP.push(element.data.std_price_ip);
        oldChildStandardArrayOP.push(element.data.std_price_op);
      }
    });
    return [
      (oldChildStandardArrayIP.reduce((a, b) => a + b, 0) + childStandardArrayIP.reduce((a, b) => a + b, 0)) || 0,
      (oldChildStandardArrayOP.reduce((a, b) => a + b, 0) + childStandardArrayOP.reduce((a, b) => a + b, 0)) || 0
    ];
  }

  removeChildren(selectedService) {
    let filteredIndex = this.addedChildrenServicesList.findIndex( item => item.cpt_code == selectedService.cpt_code);
    this.addedChildrenServicesList.splice(filteredIndex, 1);
  }

  async openTestDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.minWidth = '40vw';
    dialogConfig.data = {
      isNew: true,
      proID: localStorage.getItem('proID'),
      isChild: true,
      insuranceList: this.proInsuranceList.filter(element => element.state == 1)
    };
    const dialogRef = this.dialog.open(AddEditMandatoryServicesComponent, dialogConfig);
    dialogRef.beforeClosed().subscribe(data => {
      if (data) {
        if(data.notFromChargeMaster) {
          let encryptedServiceName = crypto.MD5(data.selectedService.serviceName);
          let newServiceObj = {
            master_key: `${this.serviceObj._id}-${data.selectedService.itemCode}-${data.selectedService.codeType}-${encryptedServiceName}`,
            // isMandatory: this.queryParamsData.isMandatory,
            isMandatory: false,
            isServicable: !!data.selectedService.isServicable,
            isShoppable: data.selectedService.isShoppable,
            is_bundled: data.selectedService.isBundled,
            superKey: this.serviceObj.superKey && this.serviceObj.parentId ? this.serviceObj.superKey : this.serviceObj._id,
            parentId: this.serviceObj._id,
            provider: localStorage.getItem('proID'),
            test_name: data.selectedService.serviceName.toUpperCase(),
            item_type: data.selectedService.serviceItemType,
            code_type: data.selectedService.codeType,
            cpt_code: data.selectedService.itemCode,
            master_id: data.selectedService.serviceID,
            quantity:  Number.isInteger(data.selectedService.quantity)? data.selectedService.quantity.toString(): '',
            prerequisite: "",
            Steps: "",
            description: data.selectedService.description,
            short_description: data.selectedService.shortDesc,
            std_price_ip: data.selectedService.standardPriceIP,
            std_price_op: data.selectedService.standardPriceOP,
            cash_price_ip: data.selectedService.cashPriceIP,
            cash_price_op: data.selectedService.cashPriceOP,
            outofnetwork_ip: data.selectedService.outNetworkPriceIP,
            outofnetwork_op: data.selectedService.outNetworkPriceOP,
            insurance_prices: data.proInsuranceList,
            cptcode_type: `${localStorage.getItem('proID')}-${data.selectedService.itemCode}-${data.selectedService.codeType}`,
            doctors: [],
            codeKey: `${data.selectedService.itemCode}-${data.selectedService.codeType}`
          };
          let count = this.addedChildrenServicesList.filter(aa=>aa.cpt_code==newServiceObj.cpt_code && aa.code_type==newServiceObj.code_type && aa.test_name==newServiceObj.test_name)
          if(count==0) {
            this.addedChildrenServicesList.push(newServiceObj);
            // this.tempAddedChildrenServicesList = this.addedChildrenServicesList;
            // if(this.selectedFeeButton != 'All') {
            //   let filtered = this.tempAddedChildrenServicesList.filter( element => element.item_type == this.selectedFeeButton);
            //   this.tempAddedChildrenServicesList = filtered;
            // }
          }
        }
      }
    });
  }

  submitChildrenServices() {
    let filtered = this.addedChildrenServicesList.filter( element => element.item_type == '');
    if(filtered.length > 0) {
      this.snackbar.open('Please fill service item type field for all services', 'Failed', {
        duration: 5000,
        panelClass: 'snackBar-failure'
      });
    } else {
      this.spinner.showSpinner('Loading...Please Wait');
      this.addedChildrenServicesList.forEach(element => {
        let newDocArray = [];
        element.doctors.forEach(item => {
          newDocArray.push({ doctorId: item._id, isActive: item.isActive });
        });
        element.doctors = newDocArray;
        element.isServicable = element.isServicable;
      });
      this.httpCall.postAuth(`${environment.providerDomain}/providerApi/AddBulkNewTest`, this.addedChildrenServicesList).subscribe(res => {
        this.spinner.stopSpinner();
        if (res['success']) {
          this.snackbar.open(res['message'], 'Success', {
            duration: 5000,
            panelClass: 'snackBar-success'
          });
          this.backToPreviousPage();
        } else {
          this.snackbar.open(res['message'], 'Failed', {
            duration: 5000,
            panelClass: 'snackBar-failure'
          });
        }
      }, (err) => {
        this.spinner.stopSpinner();
        this.snackbar.open(err.error['message'], 'Failed', {
          duration: 5000,
          panelClass: 'snackBar-failure'
        });
      });
    }
  }

  async viewServiceDetailDialog(selectedItem) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.minWidth = '30vw';
    dialogConfig.maxWidth = '100vw';
    dialogConfig.width = '750px';
    dialogConfig.data = {
      selectedItem: selectedItem
    };
    const dialogRef = this.dialog.open(ViewServiceDetailComponent, dialogConfig);
    dialogRef.beforeClosed().subscribe(data => {
      if (data) {

      }
    });
  }

  // Children Table View ------------------------------------------------------

  _selectedColumns: any[];
  frozenCols: any[] = [
    { field: 'test_name', header: 'CMS Shoppable Service Name' },
    { field: 'new_code', header: 'Service Code' },
  ];
  scrollableCols: any[];
  oldServicesList: any = [];

  headerColumnsArray: any[] = [];
  filterColumns: any[] = [];
  optionalColumns: any[] = [
    // { field: 'short_description', header: 'Shoppable Service Name', width: '300px' }
  ];
  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    //restore original order
    this._selectedColumns = this.filterColumns.filter(col => val.includes(col));
  }

  onFilter(event) {
    this.makeRowsSameHeight();
  }
  
  paginatorChanged(event) {
    this.makeRowsSameHeight();
  }

  makeRowsSameHeight() {
    setTimeout(() => {
      if ($('.p-treetable-scrollable-wrapper').length) {
        let wrapper = $('.p-treetable-scrollable-wrapper');
        wrapper.each(function () {
          let w = $(this);
          let frozen_rows: any = w.find('.p-treetable-frozen-view tr');
          let unfrozen_rows = w.find('.p-treetable-unfrozen-view tr');
          for (let i = 0; i < frozen_rows.length; i++) {
            if (frozen_rows.eq(i).height() > unfrozen_rows.eq(i).height()) {
              unfrozen_rows.eq(i).height(frozen_rows.eq(i).height());
            } else if (frozen_rows.eq(i).height() < unfrozen_rows.eq(i).height()) {
              frozen_rows.eq(i).height(unfrozen_rows.eq(i).height());
            }
          }
        });
      }
    });
  }

  @ViewChild('childrenTable') dataTable: Table;
  
  columnFilter(event: any, field) {
    // console.log("before: ",this.dataTable)
    this.dataTable.filter(event.target.value, field, 'contains');
    // console.log("After", this.dataTable)
  }

  tableViewTypevalue: number = 1;
  tableViewTypes: SelectItem[] = [
    { label: 'Tree View', value: 1 },
    { label: 'Group View', value: 2 }
  ];
  tableViewTypeChanged(value) {
    // this.getAddedTests(localStorage.getItem('proID'));
  }

  patientTypevalue: number = 2;
  patientTypes: SelectItem[] = [
    { label: 'Out Patient', value: 2 },
    { label: 'In Patient', value: 1 },
  ];
  patientTypeChanged(value) {
    this.setTableColumns();
  }

  setTableColumns() {
    this.filterColumns = this.optionalColumns;
    this.scrollableCols = [];
    if (this.patientTypevalue == 1) {
      this.scrollableCols = [
        // { field: 'category', header: 'Category type', width: '200px' },
        { field: 'std_price_ip', header: 'Gross Price(IP)', width: '180px' },
        { field: 'std_price_op', header: 'Gross Price(OP)', width: '180px' },
        // { field: 'cash_price_ip', header: 'Cash price(IP)', width: '150px' },
        // { field: 'min_price_ip', header: 'Min price(IP)', width: '150px' },
        // { field: 'max_price_ip', header: 'Max price(IP)', width: '150px' },
        // { field: 'outofnetwork_ip', header: 'Out of Network price(IP)', width: '200px' },
      ];
      this.headerColumnsArray.forEach(element => {
        if (element['field'].includes('_ip')) {
          this.scrollableCols.push({ field: `${element['field']}`, header: element['header'] });
        }
      });
    } else {
      this.scrollableCols = [
        { field: 'category', header: 'Category Type', width: '200px' },
        { field: 'std_price_op', header: 'Gross Price(OP)', width: '180px' },
        { field: 'cash_price_op', header: 'Cash Price(OP)', width: '150px' },
        { field: 'min_price_op', header: 'Min Price(OP)', width: '150px' },
        { field: 'max_price_op', header: 'Max Price(OP)', width: '150px' },
        { field: 'outofnetwork_op', header: 'Out of Network Price(OP)', width: '200px' },
      ];
      this.headerColumnsArray.forEach(element => {
        if (element['field'].includes('_op')) {
          this.scrollableCols.push({ field: `${element['field']}`, header: element['header'] });
        }
      });
    }
    this.filterColumns = [...this.filterColumns, ...this.scrollableCols];
    this._selectedColumns = [...this.scrollableCols];
    this.makeRowsSameHeight();
  }

  onNodeExpand(event) {
    const node = event.node;
    if(this.tableViewTypevalue == 1) {
      let body = {
        superKey: node.data.superKey && node.data.parentId ? node.data.superKey : node.data.id,
        parentId: node.data.id
      };
      this.spinner.showSpinner('Loading...Please Wait');
      this.httpCall.postAuth(`${environment.providerDomain}/providerApi/getAllTestProviderChildren`, body).subscribe(res => {
        this.spinner.stopSpinner();
        if (res['success']) {
          node.children = res['response'];
          this.oldServicesList = [...this.oldServicesList];
        } else {
          this.snackbar.open(res['message'], '', {
            duration: 5000,
            panelClass: 'snackBar-failure'
          });
        }
        this.makeRowsSameHeight();
      }, (err) => {
        this.spinner.stopSpinner();
        this.snackbar.open(err.error['message'], 'Failed', {
          duration: 5000,
          panelClass: 'snackBar-failure'
        });
      });
    } else {
      if(node.data.is_category) {
        this.spinner.showSpinner('Loading...Please Wait');
        this.httpCall.getAuth(`${environment.providerDomain}/BackEnd/getServiceDetails/${node.data.superKey}/${node.data.test_name}`).subscribe(res => {
          this.spinner.stopSpinner();
          if (res['success']) {
            node.children = res['response'].data;
            this.oldServicesList = [...this.oldServicesList];
          } else {
            this.snackbar.open(res['message'], 'Failed', {
              duration: 5000,
              panelClass: 'snackBar-failure'
            });
          }
          this.makeRowsSameHeight();
        }, (err) => {
          this.spinner.stopSpinner();
          this.snackbar.open(err.error['message'], 'Failed', {
            duration: 5000,
            panelClass: 'snackBar-failure'
          });
        });
      } else {
        this.spinner.showSpinner('Loading...Please Wait');
        this.httpCall.getAuth(`${environment.providerDomain}/BackEnd/getServiceTypePrices/${node.data.id}`).subscribe(res => {
          this.spinner.stopSpinner();
          if (res['success']) {
            node.children = res['response'].data;
            this.oldServicesList = [...this.oldServicesList];
          } else {
            this.snackbar.open(res['message'], '', {
              duration: 5000,
              panelClass: 'snackBar-failure'
            });
          }
          this.makeRowsSameHeight();
        }, (err) => {
          this.spinner.stopSpinner();
          this.snackbar.open(err.error['message'], 'Failed', {
            duration: 5000,
            panelClass: 'snackBar-failure'
          });
        });
      }
    }
  }

  onNodeCollapse(event) {
    this.makeRowsSameHeight();
  }

  async editTestDialog(isNew: boolean, selectedItem?: any, isChildNode?: boolean) {
    if(!isChildNode && selectedItem) {
      isChildNode = selectedItem?.superKey && selectedItem?.parentId ? true : false;
    } 
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.minWidth = '30vw';
    dialogConfig.data = {
      isNew: isNew,
      proID: localStorage.getItem('proID'),
      isChild: isChildNode,
      serviceItem: selectedItem,
      insuranceList: this.proInsuranceList.filter( element => element.state == 1)
    };
    const dialogRef = this.dialog.open(AddEditMandatoryServicesComponent, dialogConfig);
    dialogRef.beforeClosed().subscribe(data => {
      if (data) {
        if (data.success) {
          this.getAddedChildrenServices();
        }
      }
    });
  }

  deleteService(selectedItem: any) {
    this.spinner.showSpinner('Loading...Please Wait');
    this.httpCall.getAuth(`${environment.providerDomain}/providerApi/deleteProItem/${selectedItem.id}`).subscribe(res => {
      this.spinner.stopSpinner();
      if (res['success']) {
        this.snackbar.open(res['message'], 'Success', {
          duration: 5000,
          panelClass: 'snackBar-success'
        });
        this.getAddedChildrenServices();
      } else {
        this.snackbar.open(res['message'], 'Failed', {
          duration: 5000,
          panelClass: 'snackBar-failure'
        });
      }
    }, (err) => {
      this.spinner.stopSpinner();
      this.snackbar.open(err.error['message'], 'Failed', {
        duration: 5000,
        panelClass: 'snackBar-failure'
      });
    });
  }

}
