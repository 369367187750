import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment.prod';

interface DialogData {
  dataList?: any;
  messageText?: string;
}

@Component({
  selector: 'app-view-chunck-data',
  templateUrl: './view-chunck-data.component.html',
  styleUrls: ['./view-chunck-data.component.scss']
})
export class ViewChunckDataComponent implements OnInit {

  codeTypes = environment.codeTypes;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<ViewChunckDataComponent>,
  ) { 
  }

  ngOnInit(): void {
  }

}
