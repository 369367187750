import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpCallService } from 'src/app/services/http-call.service';
import { environment } from 'src/environments/environment.prod';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SpinnerService } from 'src/app/services/spinner.service';

interface DialogData {
  isNew: boolean;
  proID: string;
  empID: string;
  selectedItem?: any;
}

@Component({
  selector: 'app-add-edit-primary-contacts',
  templateUrl: './add-edit-primary-contacts.component.html',
  styleUrls: ['./add-edit-primary-contacts.component.scss']
})
export class AddEditPrimaryContactsComponent implements OnInit {

  pcObj = { 
    f_name: '',
    l_name: '',
    emp_email: '',
    emp_phone: null,
    code: '+1',
    role_id: ''
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<AddEditPrimaryContactsComponent>,
    private snackbar: MatSnackBar,
    private spinner: SpinnerService,
    private httpCall: HttpCallService
  ) {}

  ngOnInit() {
    if(!this.data.isNew) {
      this.getOneEmployeeDetails(this.data.selectedItem._id);
    }
    this.getProviderRoles(this.data.proID);
  }

  getOneEmployeeDetails(empID) {
    let body = {
      id: empID
    };
    this.httpCall.postAuth(`${environment.providerDomain}/providerApi/getOneEmployee`, body).subscribe(res => {
      if (res['success']) {
        this.pcObj.f_name = res['response'][0].f_name;
        this.pcObj.l_name = res['response'][0].l_name;
        this.pcObj.emp_email = res['response'][0].emp_email;
        this.pcObj.emp_phone = res['response'][0].emp_phone;
        this.pcObj.code = res['response'][0].code;
        this.pcObj.role_id = res['response'][0].access._id;
      } else {
        this.snackbar.open(res['message'], 'Failed', {
          duration: 5000,
          panelClass: 'snackBar-failure'
        });
      }
    }, (err) => {
      this.snackbar.open(err.error['message'], 'Failed', {
        duration: 5000,
        panelClass: 'snackBar-failure'
      });
    });
  }

  providerRolesList: any [];
  getProviderRoles(proID) {
    this.httpCall.getAuth(`${environment.providerDomain}/providerApi/getrolesProvider/${proID}`).subscribe(res => {
      if (res['success']) {
        this.providerRolesList = res['response'];
        console.log(this.providerRolesList);
      } else {
        this.snackbar.open(res['message'], 'Failed', {
          duration: 5000,
          panelClass: 'snackBar-failure'
        });
      }
    }, (err) => {
      this.snackbar.open(err.error['message'], 'Failed', {
        duration: 5000,
        panelClass: 'snackBar-failure'
      });
    });
  }

  submitPrimaryContactDetails(passedData) {
    let body;
    if(this.data.isNew) {
      body = {
        firstName: passedData.f_name,
        lastName: passedData.l_name,
        // role: passedData.emp_role,
        access_id: passedData.role_id,
        email: passedData.emp_email,
        mobile: passedData.emp_phone,
        countryCode: passedData.code,
        provider_id: localStorage.getItem('proID'),
        createdBy: this.data.empID
      };
      this.spinner.showSpinner('Loading...Please Wait');
      this.httpCall.postAuth(`${environment.providerDomain}/Provider/createEmployee/${this.data.empID}`, body).subscribe( res => {
        this.spinner.stopSpinner();
        if(res['success']) {
          this.snackbar.open(res['message'], 'Success', {
            duration: 5000,
            panelClass: 'snackBar-success'
          });
          this.dialogRef.close(res);
        } else {
          this.snackbar.open(res['message'], 'Failed', {
            duration: 5000,
            panelClass: 'snackBar-failure'
          });
        }
      }, (err) => {
        this.spinner.stopSpinner();
        this.snackbar.open(err.error['message'], 'Failed', {
          duration: 5000,
          panelClass: 'snackBar-failure'
        });
      });
    } else {
      body = {
        // id: this.data.selectedItem._id,
        email: passedData.emp_email,
        f_name: passedData.f_name,
        l_name: passedData.l_name,
        phone: passedData.emp_phone,
        // role: passedData.emp_role,
        access_id: passedData.role_id,
        code: passedData.code,
        updatedBy: this.data.empID
      };
      this.spinner.showSpinner('Loading...Please Wait');
      this.httpCall.postAuth(`${environment.providerDomain}/Provider/updateEmployeeDetails/${this.data.selectedItem._id}`, body).subscribe( res => {
        this.spinner.stopSpinner();
        if(res['success']) {
          this.snackbar.open(res['message'], 'Success', {
            duration: 5000,
            panelClass: 'snackBar-success'
          });
          this.dialogRef.close(res);
        } else {
          this.snackbar.open(res['message'], 'Failed', {
            duration: 5000,
            panelClass: 'snackBar-failure'
          });
        }
      }, (err) => {
        this.spinner.stopSpinner();
        this.snackbar.open(err.error['message'], 'Failed', {
          duration: 5000,
          panelClass: 'snackBar-failure'
        });
      });
    }
  }

}
