<app-toolbar></app-toolbar>

<div class="landing-img1" id="landing-top">
    <div class="container py-5 landingText">
        <div class="row">
            <div class="col-md-6">
                <div class="py-2">
                    <p class="fs-36 font-weight-bold">
                        Give us your prices <br/>
                        and we’ll give you <br/>
                        patients.
                    </p>
                    <p class="fs-20" style="line-height: 2rem;">
                        Our goal is to connect patients directly with providers like never before.
                        Providers can make their services shoppable and bookable, creating a streamlined
                        way to gain new cash-pay patients and get paid instantly with no hassle of billing
                        and collections.
                    </p>
                    <div class="row mt-5">
                        <div class="col-auto">
                            <button mat-flat-button color="primary" (click)="openLinkInNewWindow('https://zeatool.com')" class="p-2 px-4 fs-16">SCHEDULE A DEMO</button>
                        </div>
                        <div class="col-auto">
                            <button mat-flat-button color="primary" (click)="openLinkInNewWindow('https://zeatool.com')" class="p-2 px-4 fs-16">SIGN UP FOR OUR NEWSLETTER</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <img src="assets/img/landing/landing.svg" class="ml-5 w-100"/>
            </div>
        </div>
    </div>
</div>

<section class="bg-custom-primary">
    <div class="container py-5">
        <div class="text-white text-center">
            <p class="h2 fw-6">
                ZeaMed is making healthcare feel better for providers.
            </p>
            <div class="container">
                <div class="py-4">
                    <p class="fs-20" style="line-height: 2rem;">
                        People are shopping online for healthcare. We’ve created a new marketplace to allow you to list
                        your services and prices, so that people can buy them.
                    </p>
                </div>
            </div>
        </div>
        <div>
            <mat-card class="mb-5">
                <mat-card-content class="p-4">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="text-center">
                                <img src="assets/img/landing/list-services.png" style="height: 250px;"/>
                            </div>
                        </div>
                        <div class="col-md-8">
                            <p class="h3 fw-6" style="line-height: 2.4rem;">Easily list your practice and your services online</p>
                            <p class="fs-20" style="line-height: 2rem;">
                                Almost half of all Americans are underinsured or uninsured,
                                and may patients delay care because they do not know
                                ahead of time how much it will cost. By listing your most
                                common services and their cash-pay prices online, patients
                                in your area can discover your practice and find healthcare
                                that fits their budget.
                            </p>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
            <mat-card class="mb-5">
                <mat-card-content class="p-4">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="text-center">
                                <img src="assets/img/landing/book-appointments.png" style="height: 250px;"/>
                            </div>
                        </div>
                        <div class="col-md-8">
                            <p class="h3 fw-6" style="line-height: 2.4rem;">Book appointments and collect payments instantly</p>
                            <p class="fs-20" style="line-height: 2rem;">
                                ZeaMed integrates with your existing scheduling
                                system, meaning patients can see open appointment
                                times and book their slots online. ZeaMed collects the
                                payment for the service and sends it to your bank
                                account in 3-5 business days. We handle the appointment 
                                reminders and any cancellations or reschedules.
                            </p>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
            <mat-card class="mb-5">
                <mat-card-content class="p-4">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="text-center">
                                <img src="assets/img/landing/insights.png" style="height: 250px;"/>
                            </div>
                        </div>
                        <div class="col-md-8">
                            <p class="h3 fw-6" style="line-height: 2.4rem;">Get advanced insights into your healthcare and your patient base</p>
                            <p class="fs-20" style="line-height: 2rem;">
                                We give you healthcare data and insights that will help you
                                expand your practice and offer services your community is
                                looking for. You can also see how your prices and practice
                                compare to other providers in your area.
                            </p>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>

        <div class="text-center">
            <button mat-flat-button class="p-2 px-4 fs-16 bg-custom-warning" (click)="openLinkInNewWindow('https://zeatool.com')">SCHEDULE A DEMO</button>
        </div>
    </div>
    <!-- <img src="assets/img/landing/page-image.png" class="w-100"/> -->
</section>

<section class="bg-white">
    <div class="container py-5">
        <p class="fs-36 fw-6">
            We believe that everyone deserves to know how much their healthcare services actually cost.
        </p>
        <p class="fs-20" style="line-height: 2rem;">
            We're created a full stack of solutions that help healthcare become more transparence, 
            accesible and affordable for both provider and consumers. Check out our other solutions.
        </p>
        <div class="mt-5">
            <div class="row">
                <div class="col-md-6">
                    <img src="assets/img/zea-tool-logo.png" class="mb-4"/>
                    <p class="h5 fw-6">THE HOSPITAL PRICE TRANSPARENCY TOOL</p>
                    <p>
                        ZeaTool is a price transparency tool that lets hospital easily list all
                        their negotiated prices and shoppable services right on their websites.
                    </p>
                </div>
                <div class="col-md-6">
                    <iframe style="width: 100%;height: 250px;" src="https://www.youtube.com/embed/OQCGR3H1KWs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="d-none bg-white">
    <div class="container py-5">
        <div class="row align-items-center">
            <div class="col-md-6">
                <div class="p-5">
                    <div class="text-center">
                        <img src="assets/img/landing/list-services.png" style="width: 50%;"/>
                    </div>
                    <p class="h3 fw-6" style="line-height: 2.4rem;">Easily list your practice and your services online</p>
                    <p>
                        Almost half of all Americans are underinsured or uninsured,
                        and may patients delay care because they do not know
                        ahead of time how much it will cost. By listing your most
                        common services and their cash-pay prices online, patients
                        in your area can discover your practice and find healthcare
                        that fits their budget.
                    </p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="p-5">
                    <div class="text-center">
                        <img src="assets/img/landing/book-appointments.png" style="width: 50%;"/>
                    </div>
                    <p class="h3 fw-6" style="line-height: 2.4rem;">Book appointments and collect payments instantly</p>
                    <p>
                        ZeaMed integrates with your existing scheduling
                        system, meaning patients can see open appointment
                        times and book their slots online. ZeaMed collects the
                        payment for the service and sends it to your bank
                        account in 3-5 business days. We handle the appointment 
                        reminders and any cancellations or reschedules.
                    </p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="p-5">
                    <div class="text-center">
                        <img src="assets/img/landing/fill-empty-appointments.png" style="width: 50%;"/>
                    </div>
                    <p class="h3 fw-6" style="line-height: 2.4rem;">Fill empty appointment slots and sell appointments in bulk with ZeaBid and ZeaCards</p>
                    <p>
                        Increase your practice’s revenue by offering discounts on
                        unfilled appointment slots with ZeaBid. With our patented
                        ZeaCards process, we purchase 50+ appointment slots
                        for select services from you at one time, and resell them
                        online and in physical locations in your area.
                    </p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="p-5">
                    <div class="text-center">
                        <img src="assets/img/landing/insights.png" style="width: 50%;"/>
                    </div>
                    <p class="h3 fw-6" style="line-height: 2.4rem;">Get advanced insights into your healthcare and your patient base</p>
                    <p>
                        We give you healthcare data and insights that will help you
                        expand your practice and offer services your community is
                        looking for. You can also see how your prices and practice
                        compare to other providers in your area.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="d-none bg-custom-primary py-4">
    <div class="text-center text-white">
        <p class="h2 fw-6">
            <span class="mr-2"><mat-icon style="width: 36px;height: 36px;font-size: 36px;vertical-align: middle;">play_circle_outline</mat-icon></span>
            <span>Schedule A Demo</span>
        </p>
        <p>Give us 15 minutes, and we can show you how ZeaMed could work for you.</p>
    </div>
</section>

<section class="d-none bg-white py-5">
    <div class="container">
        <div class="row mt-4">
            <div class="col-md-3">
                <div class="text-center">
                    <img src="assets/img/landing/bottom-image.svg" class="w-100"/>
                </div>
            </div>
            <div class="col-md-9">
                <p class="h3 fw-6" style="line-height: 2.4rem;">We’re getting closer every day to making ZeaMed a reality, but we can’t do it without you.</p>
                <p class="fs-20" style="line-height: 2rem;">
                    Sign up for our newsletter and we will send you updates
                    and you can be the first to know when you can start
                    saving on healthcare in your area.
                </p>
            </div>
        </div>

        <div class="my-4">
            <form #newsLetterForm="ngForm" (ngSubmit)="submitNewsLetterForm(newsLetterForm)">
                <div class="mt-5">
                    <p class="h4 fw-6">Tell us about you.</p>
                    <div class="row">
                        <div class="col-md-6">
                            <p>
                                <mat-form-field appearance="outline">
                                    <mat-label>First name</mat-label>
                                    <input matInput name="firstName" ngModel #firstName="ngModel" type="text" [(ngModel)]="providerDataObj.firstName" required>
                                    <mat-error *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)">
                                        <mat-error *ngIf="firstName.errors.required">First name is required</mat-error>
                                    </mat-error>
                                </mat-form-field>
                            </p>
                        </div>
                        <div class="col-md-6">
                            <p>
                                <mat-form-field appearance="outline">
                                    <mat-label>Last name</mat-label>
                                    <input matInput name="lastName" ngModel #lastName="ngModel" type="text" [(ngModel)]="providerDataObj.lastName" required>
                                    <mat-error *ngIf="lastName.touched && lastName.invalid">
                                        <mat-error *ngIf="lastName.errors.required">Last name is required</mat-error>
                                    </mat-error>
                                </mat-form-field>
                            </p>
                        </div>
                        <div class="col-md-6">
                            <p>
                                <mat-form-field appearance="outline">
                                    <mat-label>Email</mat-label>
                                    <input matInput name="userEmail" ngModel #userEmail="ngModel" type="email" [(ngModel)]="providerDataObj.userEmail" pattern="^[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,125}[a-zA-Z]{2,63}$" required>
                                    <mat-error *ngIf="userEmail.touched && userEmail.invalid">
                                        <mat-error *ngIf="userEmail.errors.required">Email is required</mat-error>
                                        <mat-error *ngIf="userEmail.errors && userEmail.errors.pattern">Invalid email</mat-error>
                                    </mat-error>
                                </mat-form-field>
                            </p>
                        </div>
                        <div class="col-md-6">
                            <p>
                                <mat-form-field appearance="outline">
                                    <mat-label>Mobile Number</mat-label>
                                    <input matInput name="mobile" ngModel #mobile="ngModel" type="text" [(ngModel)]="providerDataObj.phoneNumber" pattern="^\+?\d{10}$" minlength="10" maxlength="10" required>
                                    <mat-error *ngIf="mobile.touched && mobile.invalid">
                                        <mat-error *ngIf="mobile.errors.required">Mobile number is required</mat-error>
                                        <mat-error *ngIf="mobile.errors.minlength || mobile.errors.pattern">Invaid Mobile number</mat-error>
                                    </mat-error>
                                </mat-form-field>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="my-4">
                    <p class="h4 fw-6">What is your role in healthcare? (Check all that apply.)</p>
                    <mat-radio-group color="primary" aria-label="Select a role" name="role" [(ngModel)]="providerDataObj.role" required>
                        <mat-radio-button class="mr-4" value="Patient">Patient</mat-radio-button>
                        <mat-radio-button class="mr-4" value="Physician">Physician</mat-radio-button>
                        <mat-radio-button class="mr-4" value="Practice Owner">Practice Owner</mat-radio-button>
                        <mat-radio-button class="mr-4" value="Hospital Administrator">Hospital Administrator</mat-radio-button>
                    </mat-radio-group>
                </div>
                
                <div class="mt-5">
                    <p class="h4 fw-6">Where do you work?</p>
                    <div class="row">
                        <div class="col-md-12">
                            <p>
                                <mat-form-field appearance="outline">
                                    <mat-label>Name of Business</mat-label>
                                    <input matInput ngModel #businessName="ngModel" name="businessName" type="text" [(ngModel)]="workLocation.businessName">
                                    <mat-error *ngIf="businessName.touched && businessName.invalid">
                                    <mat-error *ngIf="businessName.errors.required">Name of Business is required</mat-error>
                                    </mat-error>
                                </mat-form-field>
                            </p>
                        </div>
                        <div class="col-md-12">
                            <p>
                                <mat-form-field appearance="outline">
                                    <mat-label>Address line 1</mat-label>
                                    <input matInput ngModel #addressValue="ngModel" name="addressValue" type="text" [(ngModel)]="workLocation.addressLine1">
                                    <mat-error *ngIf="addressValue.touched && addressValue.invalid">
                                    <mat-error *ngIf="addressValue.errors.required">Address line-1 is required</mat-error>
                                    </mat-error>
                                </mat-form-field>
                            </p>
                        </div>
                        <div class="col-md-12">
                            <p>
                                <mat-form-field appearance="outline">
                                    <mat-label>Address line 2</mat-label>
                                    <input matInput ngModel #addressValue2="ngModel" name="addressValue2" type="text" [(ngModel)]="workLocation.addressLine2">
                                    <mat-error *ngIf="addressValue2.touched && addressValue2.invalid">
                                    <mat-error *ngIf="addressValue2.errors.required">Address line-2 is required</mat-error>
                                    </mat-error>
                                </mat-form-field>
                            </p>
                        </div>
                        <div class="col-md-4">
                            <p>
                                <mat-form-field appearance="outline">
                                    <mat-label>City</mat-label>
                                    <input matInput name="city" ngModel #city="ngModel" name="city" type="text" [(ngModel)]="workLocation.city">
                                    <mat-error *ngIf="city.touched && city.invalid">
                                        <mat-error *ngIf="city.errors.required">City is required</mat-error>
                                    </mat-error>
                                </mat-form-field>
                            </p>
                        </div>
                        <div class="col-md-4">
                            <p>
                                <mat-form-field appearance="outline">
                                    <mat-label>State</mat-label>
                                    <input matInput name="state" ngModel #state="ngModel" name="state" type="text" [(ngModel)]="workLocation.state">
                                    <mat-error *ngIf="state.touched && state.invalid">
                                        <mat-error *ngIf="state.errors.required">State is required</mat-error>
                                    </mat-error>
                                </mat-form-field>
                            </p>
                        </div>
                        <div class="col-md-4">
                            <p>
                                <mat-form-field appearance="outline">
                                    <mat-label>Zip code</mat-label>
                                    <input matInput name="zip" ngModel #zip="ngModel" name="zip" type="text" [(ngModel)]="workLocation.zipcode">
                                    <mat-error *ngIf="zip.touched && zip.invalid">
                                        <mat-error *ngIf="zip.errors.required">Zip is required</mat-error>
                                    </mat-error>
                                </mat-form-field>
                            </p>
                        </div>
                        <div class="m-3">
                            <mat-checkbox [(ngModel)]="workLocation.providesHealthcare" name="providesHealthcare">Check here if this business provides healthcare services.</mat-checkbox>
                        </div>
                    </div>
                </div>
                <button mat-flat-button color="primary" class="p-2 px-4 fs-16" [disabled]="newsLetterForm.form.invalid" type="submit">SIGN UP FOR OUR NEWSLETTER</button>
            </form>
        </div>
    </div>
</section>
