import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpCallService } from 'src/app/services/http-call.service';
import { environment } from 'src/environments/environment.prod';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'underscore';
import { SpinnerService } from 'src/app/services/spinner.service';

interface DialogData {
  isNew: boolean;
  proID: string;
  roleID?: any;
}

@Component({
  selector: 'app-add-edit-role',
  templateUrl: './add-edit-role.component.html',
  styleUrls: ['./add-edit-role.component.scss']
})
export class AddEditRoleComponent implements OnInit {

  roleObj = {
    roleName: '',
    selectedRoles: []
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<AddEditRoleComponent>,
    private spinner: SpinnerService,
    private snackbar: MatSnackBar,
    private httpCall: HttpCallService
  ) { }

  ngOnInit(): void {
    if(!this.data.isNew) {
      this.getRoleDetails();
    }
    this.getProviderSubscriptionFeatures();
  }

  featuresList: any;
  getProviderSubscriptionFeatures() {
    this.httpCall.getAuth(`${environment.providerDomain}/providerApi/getfeaturesProvider/${this.data.proID}`).subscribe( res => {
      if(res['success']) {
        this.featuresList = res['response'];
      } else {
        this.snackbar.open(res['message'], 'Failed', {
          duration: 5000,
          panelClass: 'snackBar-failure'
        });
      }
    }, (err) => {
      this.snackbar.open(err.error['message'], 'Failed', {
        duration: 5000,
        panelClass: 'snackBar-failure'
      });
    });
  }

  getRoleDetails() {
    let body = {
      id: this.data.roleID
    };
    this.httpCall.postAuth(`${environment.providerDomain}/providerApi/getAccessPermissionDetails`, body).subscribe( res => {
      if(res['success']) {
        this.roleObj.selectedRoles = _.pluck(res['response'], '_id');
      } else {
        this.snackbar.open(res['message'], 'Failed', {
          duration: 5000,
          panelClass: 'snackBar-failure'
        });
      }
    }, (err) => {
      this.snackbar.open(err.error['message'], 'Failed', {
        duration: 5000,
        panelClass: 'snackBar-failure'
      });
    });
  }

  submitRoleDetails() {
    let body = {
      providerId: this.data.proID,
      name: this.roleObj.roleName,
      features: this.roleObj.selectedRoles
    };
    this.spinner.showSpinner('Loading...Please Wait');
    this.httpCall.postAuth(`${environment.providerDomain}/providerApi/createProviderRole`, body).subscribe( res => {
      this.spinner.stopSpinner();
      if(res['success']) {
        this.snackbar.open(res['message'], 'Success', {
          duration: 5000,
          panelClass: 'snackBar-success'
        });
        this.dialogRef.close(res);
      } else {
        this.snackbar.open(res['message'], 'Failed', {
          duration: 5000,
          panelClass: 'snackBar-failure'
        });
      }
    }, (err) => {
      this.spinner.stopSpinner();
      this.snackbar.open(err.error['message'], 'Failed', {
        duration: 5000,
        panelClass: 'snackBar-failure'
      });
    });
  }

}
