export const environment = {
  production: true,
  linkUpFEDomain: 'https://zeatool.com',
  zeamedDomain: 'https://zeamed.com',

  // https://mecheri-akram.medium.com/how-to-disable-the-annoying-google-chrome-form-autocomplete-with-angular-bdcf213008b3

  // Dev Environment ----------------------------------
  // currentFEDomain: 'https://cdn.zeatool.com/#',
  // GoogleAnalyticsKey: 'G-J8W87MEZWB',
  // basicDomain: 'https://5o0h2sur3g.execute-api.us-east-1.amazonaws.com/dev',
  // providerDomain: 'https://5o0h2sur3g.execute-api.us-east-1.amazonaws.com/dev',
  // providerDomain2: 'https://test-api.zeatool.com:1001',
  // ruleEngineDomain: 'https://5errm4n9a6.execute-api.us-east-1.amazonaws.com/dev',
  // pythonDomain: 'https://test-api.zeatool.com:5000',
  // mrfDomain: 'https://test-api.zeatool.com:1001',
  // // zeacardDomain:'http://localhost:3000/dev',
  // zeacardDomain: 'https://osnk7baac7.execute-api.us-east-1.amazonaws.com/dev',

  // Prod Environment ----------------------------------
  currentFEDomain: 'https://app.zeatool.com/#',
  GoogleAnalyticsKey: 'G-LETNT6TB53',
  basicDomain: 'https://mc-api.zeatool.com',
  providerDomain: 'https://mc-api.zeatool.com',
  providerDomain2: 'https://mc-api.zeatool.com',
  ruleEngineDomain: 'https://mc-api.zeatool.com',
  mrfDomain: 'https://mc-api.zeatool.com',
  pdpDomain:'https://mc-api.zeatool.com',
  pythonDomain: 'https://data-stub.zeatool.com:5000',
  zeacardDomain: 'https://osnk7baac7.execute-api.us-east-1.amazonaws.com/dev',



  // DEV
  // currentFEDomain: 'https://cdn.zeatool.com/#',
  // GoogleAnalyticsKey: 'G-LETNT6TB53',
  // basicDomain: 'https://dev.shctech.io/zeatool-dev',
  // providerDomain: 'https://dev.shctech.io/zeatool-dev',
  // providerDomain2: 'https://dev.shctech.io/zeatool-dev',
  // ruleEngineDomain: 'https://dev.shctech.io/zeatool-dev',
  // mrfDomain: 'https://dev.shctech.io/zeatool-dev',
  // pdpDomain:'https://dev.shctech.io/zeatool-dev',
  // pythonDomain: 'https://mc-api.zeatool.com/data',
  // zeacardDomain: 'https://osnk7baac7.execute-api.us-east-1.amazonaws.com/dev',


  // currentFEDomain: 'https://cdn.zeatool.com/#',
  // GoogleAnalyticsKey: 'G-LETNT6TB53',
  // basicDomain: 'https://dev.shctech.io/zeatool-dev',
  // providerDomain: 'https://dev.shctech.io/zeatool-dev',
  // providerDomain2: 'https://dev.shctech.io/zeatool-dev',
  // ruleEngineDomain: 'https://dev.shctech.io/zeatool-dev',
  // mrfDomain: 'https://dev.shctech.io/zeatool-dev',
  // pdpDomain: 'https://dev.shctech.io/zeatool-dev',
  // pythonDomain: 'https://api.zeatool.com:6001/custom',
  // zeacardDomain: 'https://osnk7baac7.execute-api.us-east-1.amazonaws.com/dev',



  bidding:'https://a3i1u6i9k0.execute-api.us-east-1.amazonaws.com/dev/api',
  inventory:'https://73vzn970nc.execute-api.us-east-1.amazonaws.com/dev',
  queue_domain:'https://ng9diun4tf.execute-api.us-east-1.amazonaws.com/dev',
  contactUs_usa: '(844)-336-2244',

  blogDomain: 'https://blog.zeamed.com',

  SECRET: 'cQ2mM<PPrennUWWikincskk:L:PCJKWNcnmamewi64212019',
  passwordRegEx: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*()<>/?\+\=\-\_])(?=.{8,})', // https://codinglatte.com/posts/angular/cool-password-validation-angular/

  // https://medium.com/letsboot/quick-start-with-angular-material-and-flex-layout-1b065aa1476c
  // https://stackblitz.com/edit/angular-8-role-based-authorization-example

  providerTypes: [
    { name: 'Acute Care Hospitals', value: 0 },
    { name: 'Ambulatory Surgery Center', value:2 },
    { name: 'Long Term Acute Care Hospitals', value: 7 },
    { name: 'Inpatient Rehabilitation Hospitals', value: 8 },
    { name: 'Critical Access Hospitals', value: 6 },
    { name: 'Childrens', value: 9 },
    { name: 'Psychiatric', value: 10 },
  ],

  codeTypes: [
    { name: 'HCPCS', value: 0 },
    { name: 'CPT', value: 1 },
    { name:'APC', value: 2 },
    { name: 'APR-DRG', value: 3 },
    { name: 'DRG', value: 4 },
    { name: 'NDC', value: 5 },
    { name: 'Other', value: 6 },
  ],

  mandatoryTestCategories: [
    { name: 'ALL', masterID: 'all', divColor: '#000' },
    { name: 'EVALUATION & MANAGEMENT SERVICES', masterID: '60095f2f23b5e2083c46a652', divColor: '#ED9D21' },
    { name: 'LABORATORY & PATHOLOGY SERVICES', masterID: '60095f2f23b5e2083c46a638', divColor: '#2A417C' },
    { name: 'RADIOLOGY SERVICES', masterID: '60095f2f23b5e2083c46a63f', divColor: '#78CFDD' },
    { name: 'MEDICINE & SURGERY SERVICES', masterID: '60095f2f23b5e2083c46a654', divColor: '#0A7670' },
    { name: 'OTHERS', masterID: '606ebd93aa30040b0b5c5889', divColor: '#78CFDD' },
  ],
  optionalTestCategories: [
    { name: 'ALL', masterID: 'all', divColor: '#000' },
    { name: 'EVALUATION & MANAGEMENT SERVICES', masterID: '60095f2f23b5e2083c46a652', divColor: '#ED9D21' },
    { name: 'LABORATORY & PATHOLOGY SERVICES', masterID: '60095f2f23b5e2083c46a638', divColor: '#2A417C' },
    { name: 'RADIOLOGY SERVICES', masterID: '60095f2f23b5e2083c46a63f', divColor: '#78CFDD' },
    { name: 'MEDICINE & SURGERY SERVICES', masterID: '60095f2f23b5e2083c46a654', divColor: '#0A7670' },
    { name: 'OTHERS', masterID: '606ebd93aa30040b0b5c5889', divColor: '#78CFDD' },
  ],

  explainerItems: {
    basicInfo: {
      docLink: '',
      videoLink: 'https://www.youtube.com/embed/GnD2GrmXp7A?autoplay=1'
    },
    insurances: {
      docLink: '',
      videoLink: 'https://www.youtube.com/embed/_UP_udXvqCY?autoplay=1'
    },
    chargeMaster: {
      docLink: '',
      videoLink: 'https://www.youtube.com/embed/cSsTNb1fg-4?autoplay=1'
    },
    physicians: {
      docLink: '',
      videoLink: 'https://www.youtube.com/embed/7BB9-MNpR04?autoplay=1'
    },
    mandatoryTests: {
      docLink: '',
      videoLink: 'https://www.youtube.com/embed/CPxwvzGFt2E?autoplay=1'
    },
    optionalTests: {
      docLink: '',
      videoLink: 'https://www.youtube.com/embed/rMdYGUE84M8?autoplay=1'
    },
  },

  fileFormatTypes: [
    { label: 'CSV Tall', value: 1 },
    { label: 'CSV Wide', value: 2 },
    { label: 'JSON', value: 3 },
  ],

  workingDays: [ 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday' ],

  paymentModes: [

    { name: 'Card', value: 0 },
    { name: 'Cash', value: 1 },
    { name: 'ZeaCredits', value: 2 },
    { name: 'ZeaCredits + Card', value: 3 },
    { name: 'ZeaCredits + Cash', value: 4 },
    { name: 'Zea-Card', value: 5 },
  ],

  slotDivisionTypes: [
    { name: 'Morning', value: 1 },
    { name: 'Afternoon', value: 2 },
    { name: 'Evening', value: 3 },
  ],

  colors: {
    customPrimary: '#20b04b',
    customDanger: '#ea1d25',
    customInfo: '#68b3cd',
  },

  powerBIConfig: {
    zeaConversion: {
      reportName: 'Zea Conversion Report',
      reportID: '1cd2b1c0-226c-4975-8380-9a98e805c193',
      embedURL: 'https://app.powerbi.com/reportEmbed?reportId=1cd2b1c0-226c-4975-8380-9a98e805c193&ctid=74ccf5c6-2d64-43fe-80a5-948fcff94515&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXdlc3QtdXMtYy1wcmltYXJ5LXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9',
      addFilter: true
    },
    zeaConversionMap: {
      reportName: 'Zea Conversion Map Report',
      reportID: '61afac08-72b3-4fb0-bf88-ea427da74474',
      embedURL: 'https://app.powerbi.com/reportEmbed?reportId=61afac08-72b3-4fb0-bf88-ea427da74474&ctid=74ccf5c6-2d64-43fe-80a5-948fcff94515&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXdlc3QtdXMtYy1wcmltYXJ5LXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9',
      addFilter: true
    },
    lostOpportunity: {
      reportName: 'Lost Oppotunity Report',
      reportID: '60590a22-ea68-4844-969e-7f0b10845f4e',
      embedURL: 'https://app.powerbi.com/reportEmbed?reportId=60590a22-ea68-4844-969e-7f0b10845f4e&ctid=74ccf5c6-2d64-43fe-80a5-948fcff94515&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXdlc3QtdXMtYy1wcmltYXJ5LXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9',
      addFilter: true
    },
    lostOpportunityMap: {
      reportName: 'Lost Oppotunity Map Report',
      reportID: 'af337eaf-9df0-41b0-9978-3db2d58a1030',
      embedURL: 'https://app.powerbi.com/reportEmbed?reportId=af337eaf-9df0-41b0-9978-3db2d58a1030&ctid=74ccf5c6-2d64-43fe-80a5-948fcff94515&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXdlc3QtdXMtYy1wcmltYXJ5LXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9',
      addFilter: true
    },
    zeaCompare: {
      reportName: 'Zea Compare Report',
      reportID: 'e50960de-7104-4895-b4f6-c9147467ab9e',
      embedURL: 'https://app.powerbi.com/reportEmbed?reportId=e50960de-7104-4895-b4f6-c9147467ab9e&ctid=74ccf5c6-2d64-43fe-80a5-948fcff94515&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXdlc3QtdXMtYy1wcmltYXJ5LXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9',
      addFilter: true
    },
    cmCompare: {
      reportName: 'Charge Master Services Report',
      reportID: '3df38a31-d5b1-4304-8dc7-12094a7c8fbd',
      embedURL: 'https://app.powerbi.com/reportEmbed?reportId=3df38a31-d5b1-4304-8dc7-12094a7c8fbd&ctid=74ccf5c6-2d64-43fe-80a5-948fcff94515&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXdlc3QtdXMtYy1wcmltYXJ5LXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9'
    },
    ssCompare: {
      reportName: 'Shoppable Services Report',
      reportID: '4cd98f08-dbbb-43cf-ac85-392fbc723b80',
      embedURL: 'https://app.powerbi.com/reportEmbed?reportId=4cd98f08-dbbb-43cf-ac85-392fbc723b80&ctid=74ccf5c6-2d64-43fe-80a5-948fcff94515&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXdlc3QtdXMtYy1wcmltYXJ5LXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9'
    },
  },

  zeacardStates: [
		{ name: "Alabama", abbreviation: "AL" },
		{ name: "Alaska", abbreviation: "AK" },
		{ name: "Arizona", abbreviation: "AZ" },
		{ name: "Arkansas", abbreviation: "AR" },
		{ name: "California", abbreviation: "CA" },
		{ name: "Colorado", abbreviation: "CO" },
		{ name: "Connecticut", abbreviation: "CT" },
		{ name: "Delaware", abbreviation: "DE" },
		{ name: "Florida", abbreviation: "FL" },
		{ name: "Georgia", abbreviation: "GA" },
		{ name: "Hawaii", abbreviation: "HI" },
		{ name: "Idaho", abbreviation: "ID" },
		{ name: "Illinois", abbreviation: "IL" },
		{ name: "Indiana", abbreviation: "IN" },
		{ name: "Iowa", abbreviation: "IA" },
		{ name: "Kansas", abbreviation: "KS" },
		{ name: "Kentucky", abbreviation: "KY" },
		{ name: "Louisiana", abbreviation: "LA" },
		{ name: "Maine", abbreviation: "ME" },
		{ name: "Maryland", abbreviation: "MD" },
		{ name: "Massachusetts", abbreviation: "MA" },
		{ name: "Michigan", abbreviation: "MI" },
		{ name: "Minnesota", abbreviation: "MN" },
		{ name: "Mississippi", abbreviation: "MS" },
		{ name: "Missouri", abbreviation: "MO" },
		{ name: "Montana", abbreviation: "MT" },
		{ name: "Nebraska", abbreviation: "NE" },
		{ name: "Nevada", abbreviation: "NV" },
		{ name: "New Hampshire", abbreviation: "NH" },
		{ name: "New Jersey", abbreviation: "NJ" },
		{ name: "New Mexico", abbreviation: "NM" },
		{ name: "New York", abbreviation: "NY" },
		{ name: "North Carolina", abbreviation: "NC" },
		{ name: "North Dakota", abbreviation: "ND" },
		{ name: "Ohio", abbreviation: "OH" },
		{ name: "Oklahoma", abbreviation: "OK" },
		{ name: "Oregon", abbreviation: "OR" },
		{ name: "Pennsylvania", abbreviation: "PA" },
		{ name: "Rhode Island", abbreviation: "RI" },
		{ name: "South Carolina", abbreviation: "SC" },
		{ name: "South Dakota", abbreviation: "SD" },
		{ name: "Tennessee", abbreviation: "TN" },
		{ name: "Texas", abbreviation: "TX" },
		{ name: "Utah", abbreviation: "UT" },
		{ name: "Vermont", abbreviation: "VT" },
		{ name: "Virginia", abbreviation: "VA" },
		{ name: "Washington", abbreviation: "WA" },
		{ name: "West Virginia", abbreviation: "WV" },
		{ name: "Wisconsin", abbreviation: "WI" },
		{ name: "Wyoming", abbreviation: "WY" },
		{ name: "American Samoa", abbreviation: "AS" },
		{ name: "District of Columbia", abbreviation: "DC" },
		{ name: "Federated States of Micronesia", abbreviation: "FM" },
		{ name: "Guam", abbreviation: "GU" },
		{ name: "Marshall Islands", abbreviation: "MH" },
		{ name: "Northern Mariana Islands", abbreviation: "MP" },
		{ name: "Palau", abbreviation: "PW" },
		{ name: "Puerto Rico", abbreviation: "PR" },
		{ name: "Virgin Islands", abbreviation: "VI" }
	],

  bidRequestStatus:{
      0:"New",
      1:"Pending",
      2:"Rejected",
      4:"Paid",
      5:"Done"
  },

  annual_fpg:[
    {
      "year":2023,
      "state":"CO",
      "annual":[14580,19720,24860,30000,35140,40280,45420,50560,55700,60840,65980,71120,76260,81400,86540,91680]
    }
  ]



  // pdpDomain:'https://ulb8lnr961.execute-api.us-east-1.amazonaws.com/dev',
  // basicDomain: 'https://zeamedhealthcare.com',
  // providerDomain: 'https://zeamedhealthcare.com',
  // providerDomain2: 'https://zeamedhealthcare.com',
  // ruleEngineDomain: 'https://zeamedhealthcare.com',
  // pythonDomain: 'https://test-api.zeatool.com:5000',
  // mrfDomain: 'https://zeamedhealthcare.com',
  // zeacardDomain: 'https://osnk7baac7.execute-api.us-east-1.amazonaws.com/dev',
  // pdpDomain:'https://zeamedhealthcare.com',
  // basicDomain: 'https://api.zeatool.com:3001',
  // providerDomain: 'https://api.zeatool.com:3001',
  // providerDomain2: 'https://api.zeatool.com:1001',
  // ruleEngineDomain: 'https://tk5y3vgs91.execute-api.us-east-1.amazonaws.com/prod',
  // pythonDomain: 'https://data-stub.zeatool.com:5000',
  // mrfDomain: 'https://api.zeatool.com:1001',
  // zeacardDomain: 'https://osnk7baac7.execute-api.us-east-1.amazonaws.com/dev',
  // pdpDomain:'https://ulb8lnr961.execute-api.us-east-1.amazonaws.com/dev',
};
