import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
// https://codepen.io/sreekanth2108/pen/NvWowV
// https://www.npmjs.com/package/ngx-bar-rating#options

@Component({
  selector: 'star-rating',
  template: `
  <span class='star-rating'>
    <ng-container *ngIf='!forDisplay'>
      <i *ngFor='let n of range; let $index = index;' class='material-icons to-rate' (click)='mark($index)'>{{isMarked($index)}}</i>
    </ng-container>
    <ng-container *ngIf='forDisplay'>
      <i *ngFor='let n of range; let $index = index;' class="to-display material-icons">{{isMarked($index)}}</i>
    </ng-container>
  </span>`
})

export class StarRatingComponent implements OnInit {
	@Input() score;
	@Input() maxScore = 5;
	@Input() forDisplay = false;
	@Output() rateChanged = new EventEmitter();
  
  // <span class='star-rating'>
  //   <ng-container *ngIf='!forDisplay'>
  //     <i *ngFor='let n of range; let $index = index;' class='fa to-rate' (click)='mark($index)' [ngClass]='isMarked($index)'></i>
  //   </ng-container>
  //   <ng-container *ngIf='forDisplay'>
  //     <i *ngFor='let n of range; let $index = index;' class="to-display fa" [ngClass]='isMarked($index)'></i>
  //   </ng-container>
  // </span>


  /* Front end side html */ 
  // <div class="block">
  //   <div>To Rate</div>
  //   <star-rating [maxScore]="5" [forDisplay]="false" (rateChanged)="onRateChange($event)"></star-rating>
  //   <div>Rating: {{score}}</div>
  // </div>

  range = [];
  marked = -1;

	constructor() { }

	ngOnInit() {
    for (var i = 0; i < this.maxScore; i++) {
      this.range.push(i);
    }
  }
  
  public mark = (index) => {
    this.marked = this.marked == index ? index - 1 : index;
    this.score = this.marked + 1;
    this.rateChanged.next(this.score);
  }

  public isMarked = (index) => {
    if (!this.forDisplay) {
      if (index <= this.marked) {
        return 'star';
      } else {
        return 'star_border';
      }
    } else {
      if (this.score >= index + 1) {
        return 'star';
      } else if (this.score > index && this.score < index + 1) {
        return 'star_half';
      } else {
        return 'star_border';
      }
    }
  }
}