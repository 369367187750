import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpCallService } from 'src/app/services/http-call.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  constructor(
    private snackbar: MatSnackBar,
    private spinner: SpinnerService,
    private httpCall: HttpCallService,
  ) { }

  ngOnInit(): void {
    this.getFAQ();
  }

  faqList: any = [];
  getFAQ() {
    this.spinner.showSpinner('Loading...Please Wait');
    this.httpCall.get(`${environment.providerDomain}/BackEnd/FAQs/zeatool`).subscribe((res) => {
      this.spinner.stopSpinner();
      if(res['success']) {
        this.faqList = res['data'];
      }
    },(err) => {
      this.spinner.stopSpinner();
      this.snackbar.open(err.error['message'], 'Failed', {
        duration: 5000,
        panelClass: 'snackBar-failure'
      });
    });
  }

}
