import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProviderGuardService } from './services/auth-guard.service';
import { ProviderLandingComponent } from './provider-landing/provider-landing.component';
import { UserResolver } from './services/auth_resolver';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'proLogin'
  },
  {
    path: 'proLanding',
    component: ProviderLandingComponent
  },
  {
    path: 'proLogin',
    loadChildren: () => import('./non-auth/pro-login/pro-login.module').then(m => m.ProLoginModule)
  },
  {
    path: 'proRegister',
    loadChildren: () => import('./non-auth/register/register.module').then(m => m.RegisterModule)
  },
  {
    path: 'proRegisterDraft',
    loadChildren: () => import('./non-auth/register/register.module').then(m => m.RegisterModule)
  },
  {
    path: 'verify',
    loadChildren: () => import('./non-auth/register/register.module').then(m => m.RegisterModule)
  },
  {
    path: 'provider-detail/:providerNumber',
    loadChildren: () => import('./provider-detail/provider-detail.module').then(m => m.ProviderDetailModule)
    // loadChildren:() => import('./provider-detail-new/provider-detail-new.module').then(m=> m.ProviderDetailNewModule)
  },
  {
    path:'newPdp/:providerNumber',
    loadChildren:() => import('./provider-detail-new/provider-detail-new.module').then(m=> m.ProviderDetailNewModule)
  },
  {
    path: 'provider-detail-preview/:providerNumber',
    loadChildren: () => import('./provider-detail/provider-detail.module').then(m => m.ProviderDetailModule)
  },
  {
    path: 'provider-detail-new/:providerNumber',
    loadChildren: () => import('./provider-detail-new/provider-detail-new.module').then(m => m.ProviderDetailNewModule)
  },
  {
    path: 'proAuth',
    // canLoad: [ProviderGuardService],
    loadChildren: () => import('./manage-provider/manage-provider.module').then(m => m.ManageProviderModule),
  },
  {
    path: 'shared',
    loadChildren: () => import('./shared/shared.module').then(m => m.SharedModule)
  },
  {
    path: 'zeamedReg',
    loadChildren: () => import('./non-auth/zeamed-register/zeamed-register.module').then(m => m.ZeamedRegisterModule)
  },
  {
    path: 'setPassword/:token',
    loadChildren: () => import('./non-auth/set-password/set-password.module').then(m => m.SetPasswordModule)
  },
  {
    path: 'forgotPassword',
    loadChildren: () => import('./non-auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
  },
  {
    path:'calculator',
    loadChildren:()=> import('./fpg-calculator/fpg-calculator.module').then(m=> m.FPGCalculatorModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
