<mat-toolbar class="customNavbar staticNav sm-hide">
    <mat-toolbar-row>
        <button mat-icon-button *ngIf="router.url.includes('/proAuth') && !httpCall?.currentUserAccess?.showAllProvidersTable && !httpCall?.currentUserAccess?.fullDashboardTableAccess" (click)="snav.toggle()"><mat-icon>menu</mat-icon></button>
        <!-- <span>ZeaTool</span> -->
        <span><img id="navLogo" style="cursor:pointer;" alt="ZeaTool" src="assets/img/zea-tool-logo.png" width="150" /></span>
        <span class="nvSpacer"></span>
        <span class="custom-primary" *ngIf="!httpCall?.currentUserAccess?.showAllProvidersTable">
            <span>{{ httpCall.getProName() }}</span><br/>
            <span class="text-black fs-14">{{httpCall.getProLocation()}}</span>
        </span>
        <span class="custom-primary" *ngIf="httpCall?.currentUserAccess?.showAllProvidersTable">
            <span>{{ getUserEmail() }}</span><br/>
        </span>
        <span *ngIf="localStorageItem() == true" class="navIcon"><button mat-stroked-button color="secondary" (click)="httpCall.logOut()">Log Out</button></span>
        <!-- <span *ngIf="localStorageItem() != 'true'" class="navIcon"><button mat-button [routerLink]="[ '/consumer/priceCompare/landing' ]" (click)="spinner.show()">Get ZeaPrice</button></span> -->
        <span *ngIf="localStorageItem() != true" class="navIcon" (click)="openLinkInNewWindow('https://zeamed.com/#/')"><button mat-button>For Patients</button></span>
        <span *ngIf="localStorageItem() != true" class="navIcon"><a class="onHoverLink" href="{{blogDomain}}" target="_blank" mat-button>Blog</a></span>
        <span *ngIf="localStorageItem() != true" class="navIcon"><button mat-button [routerLink]="[ '/contactUs' ]">Help</button></span>
        <span *ngIf="localStorageItem() != true" class="navIcon"><button mat-button [routerLink]="[ '/FAQ' ]">FAQ</button></span>
        <span *ngIf="localStorageItem() != true" class="navIcon"><button mat-stroked-button class="border-outline-primary" (click)="getRouterLink()">SignUp / Login</button></span>
        <!-- <button *ngIf="localStorageItem() == true" mat-icon-button (click)="openRightNav()"><mat-icon>menu</mat-icon></button> -->
    </mat-toolbar-row>
</mat-toolbar>

<!-- responsive navbar starts-->
<mat-toolbar class="staticNav customNavbar sm-show">
    <mat-toolbar-row>
        <span><img id="navLogo" style="cursor:pointer;" alt="ZeaTool" src="assets/img/zea-tool-logo.png" width="150"/></span>
        <span class="nvSpacer"></span>
        <span *ngIf="localStorageItem() != true">
            <button mat-icon-button (click)="openRightNav()"><mat-icon>menu</mat-icon></button>
        </span>
        <span *ngIf="localStorageItem() == true">
            <button mat-icon-button *ngIf="router.url.includes('/proAuth') && !httpCall?.currentUserAccess?.showAllProvidersTable && !httpCall?.currentUserAccess?.fullDashboardTableAccess" (click)="snav.toggle()"><mat-icon>menu</mat-icon></button>
        </span>
    </mat-toolbar-row>
</mat-toolbar>
<!-- responsive navbar starts-->

<div id="mySidenav" class="sidenav" [style.width]="rightNavHieght">
    <a href="javascript:void(0)" class="closebtn" (click)="closeRightNav()">&times;</a>
    <a href="javascript:void(0)" class="custom-white" *ngIf="localStorageItem() == true">Welcome,
        {{firstName | titlecase }}</a>
    <!-- <a href="javascript:void(0)" class="custom-white" *ngIf="localStorageItem() != true"><img src="assets/img/logo/logo-full-white.svg" style="max-width:150px;"/></a> -->
    <hr class="text-center bg-custom-white" style="max-width:90%;" />
    <a [routerLink]="[ '/proLanding' ]">For&nbsp;Providers</a>
    <a class="onHoverLink" href="{{blogDomain}}" target="_blank">Blog</a>
    <a [routerLink]="[ '/contactUs' ]">Help</a>
    <a [routerLink]="[ '/FAQ' ]">FAQ</a>
    <a *ngIf="localStorageItem() != true; else notLogIn" [routerLink]="[ '/proLogin' ]">SignUp / Login</a>
    <ng-template #notLogIn>
        <a href="javascript:void(0)" (click)="httpCall.logOut()">Logout</a>
    </ng-template>
</div>
