import { Component, OnInit, Inject } from '@angular/core';
import { HttpCallService } from 'src/app/services/http-call.service';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment.prod';
import { SpinnerService } from 'src/app/services/spinner.service';

interface DialogData {
  proID: string;
  selectedItem?: any;
}

@Component({
  selector: 'app-add-edit-service-insurance-prices',
  templateUrl: './add-edit-service-insurance-prices.component.html',
  styleUrls: ['./add-edit-service-insurance-prices.component.scss']
})
export class AddEditServiceInsurancePricesComponent implements OnInit {

  insuranceObj = {
    id: '',
    ip_price: null,
    op_price: null
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<AddEditServiceInsurancePricesComponent>,
    public httpCall: HttpCallService,
    public router: Router,
    public dialog: MatDialog,
    private snackbar: MatSnackBar,
    private spinner: SpinnerService
  ) { }

  ngOnInit(): void {
    this.getSingleInsurancePricesData(this.data.selectedItem._id);
  }

  getSingleInsurancePricesData(id) {
    this.httpCall.getAuth(`${environment.providerDomain}/providerApi/fetchNewSingleProviderInsuranceItem/${id}`).subscribe(res => {
      if (res['success']) {
        this.insuranceObj.id = res['response'][0]._id;
        this.insuranceObj.ip_price = res['response'][0].ip_price;
        this.insuranceObj.op_price = res['response'][0].op_price;
      }
    }, (err) => {
      this.snackbar.open(err.error['message'], 'Failed', {
        duration: 5000,
        panelClass: 'snackBar-failure'
      });
    });
  }

  sumbitInsurancePrices() {
    let body = {
      updatedBy: localStorage.getItem('empID'),
      id: this.insuranceObj.id,
      ip_price: this.insuranceObj.ip_price,
      op_price: this.insuranceObj.op_price
    };
    this.spinner.showSpinner('Loading...Please Wait');
    this.httpCall.postAuth(`${environment.providerDomain}/providerApi/updateProviderTestInsuranceItem`, body).subscribe( res => {
      this.spinner.stopSpinner();
      if(res['success']) {
        this.snackbar.open(res['message'], 'Success', {
          duration: 5000,
          panelClass: 'snackBar-success'
        });
        this.dialogRef.close(res);
      } else {
        this.snackbar.open(res['message'], 'Failed', {
          duration: 5000,
          panelClass: 'snackBar-failure'
        });
      }
    }, (err) => {
      this.spinner.stopSpinner();
      this.snackbar.open(err.error['message'], 'Failed', {
        duration: 5000,
        panelClass: 'snackBar-failure'
      });
    });
  }

}
