import { Injectable, Inject } from "@angular/core";
import { catchError, switchMap, tap } from "rxjs/operators";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse, HttpEventType } from "@angular/common/http";
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpCallService } from "./http-call.service";
import {  Observable, throwError } from 'rxjs';

@Injectable()
export class MyInterceptor implements HttpInterceptor {

  constructor(private router: Router, private snackbar: MatSnackBar,private httpCall:HttpCallService) { }

  fourDigitedErrors = [404];
  fiveDigitedErrors = [500, 501, 502, 503, 504, 505, 511];
  
  uploadedPercentage = 0;
  mainReq:any;
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if(request.url.includes('/BackEnd') == false && request.url.includes('/dev/api') == false){
   
    }

    if (request.url.indexOf('/refresh') > -1) {
      return next.handle(request);
    }

    this.mainReq = request;
    
    return next.handle(request).pipe(
      tap(
        event => {
          if (event instanceof HttpRequest) {
          }
        },
        error => {
          if (error instanceof HttpErrorResponse) {
            if (error.status == 401) {
              return this.handle401Error(request, next, error);
            } else {
              return throwError(() => error);
            }
          }
        }
      )
    );

  }

  private handle401Error(req: HttpRequest<any>,next: HttpHandler,originalError: any){
      let lastUrl = this.router.routerState.snapshot.url;
      console.log("originalError",this.mainReq)
      this.httpCall.refreshService().subscribe((res)=>{
        if(res['success'] == true){
          localStorage.setItem('token',res['response']);
          this.router.navigate([`${lastUrl}`]);
          return next.handle(this.mainReq);
        }else{
          localStorage.clear()
          this.router.navigate(['/proLogin']);
        }
      })
  }
}