import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { HttpCallService } from 'src/app/services/http-call.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-add-mrf',
  templateUrl: './add-mrf.component.html',
  styleUrls: ['./add-mrf.component.scss']
})
export class AddMrfComponent implements OnInit {

  effectiveDate;
  selectedFormatType = 1;
  fileFormatTypes = environment.fileFormatTypes;
  
  constructor(
    public httpCall: HttpCallService,
    private snackbar: MatSnackBar,
    public router: Router,
    private spinner: SpinnerService
  ) { }

  functionCall;
  ngOnInit(): void {
    this.getMachineReadableFiles();
    this.getEffectiveDate();
    this.functionCall = setInterval( () => {
      this.getMachineReadableFiles();
    }, 10000);
  }

  ngOnDestroy() {
    clearInterval(this.functionCall);
  }

  generateMachineReadableFormat() {
    let body = {
      provider: localStorage.getItem('proID'),
      employee: localStorage.getItem('empID'),
      file_type: this.selectedFormatType
    };
    this.spinner.showSpinner('Loading...Please Wait');
    this.httpCall.postAuth(`${environment.pythonDomain}/machine_readable_generation`, body).subscribe(res => {
      this.spinner.stopSpinner();
      if (res['success']) {
        this.snackbar.open(res['message'], 'Success', {
          duration: 5000,
          panelClass: 'snackBar-success'
        });
      } else {
        this.snackbar.open(res['message'], 'Failed', {
          duration: 5000,
          panelClass: 'snackBar-failure'
        });
      }
    }, (err) => {
      this.spinner.stopSpinner();
      clearInterval(this.functionCall);
      this.snackbar.open(err.error['message'], 'Failed', {
        duration: 5000,
        panelClass: 'snackBar-failure'
      });
    });
  }

  pendingList: any[] = [];
  filesList: any[] = [];
  getMachineReadableFiles() {
    // this.spinner.showSpinner('Loading...Please Wait');
    this.httpCall.getAuth(`${environment.providerDomain}/providerApi/fetchReadable`).subscribe(res => {
      this.spinner.stopSpinner();
      if (res['success']) {
        this.filesList = res['response'];
        // this.filesList = res['response'].filter( element => element.state == 2);
        // this.pendingList = res['response'].filter( element => element.state == 1);
      } else {
        this.snackbar.open(res['message'], 'Failed', {
          duration: 5000,
          panelClass: 'snackBar-failure'
        });
      }
    }, (err) => {
      this.spinner.stopSpinner();
      this.snackbar.open(err.error['message'], 'Failed', {
        duration: 5000,
        panelClass: 'snackBar-failure'
      });
    });
  }

  changeFileStatus(item) {
    this.spinner.showSpinner('Loading...Please Wait');
    this.httpCall.getAuth(`${environment.providerDomain}/providerApi/disable/${item._id}/${!item.is_active}`).subscribe(res => {
      this.spinner.stopSpinner();
      if (res['success']) {
        this.snackbar.open(res['message'], 'Success', {
          duration: 5000,
          panelClass: 'snackBar-success'
        });
        this.getMachineReadableFiles();
      } else {
        this.snackbar.open(res['message'], 'Failed', {
          duration: 5000,
          panelClass: 'snackBar-failure'
        });
      }
    }, (err) => {
      this.spinner.stopSpinner();
      this.snackbar.open(err.error['message'], 'Failed', {
        duration: 5000,
        panelClass: 'snackBar-failure'
      });
    });
  }

  downloadFile(item) {
    window.open(item?.fileUrl);
  }

  getEffectiveDate() {
    this.spinner.showSpinner('Loading...Please Wait');
    this.httpCall.getAuth(`${environment.providerDomain}/providerApi/getdates/${localStorage.getItem('proID')}`).subscribe(res => {
      this.spinner.stopSpinner();
      if (res['success']) {
        this.effectiveDate = res['response'].effective_date;
      }
    }, (err) => {
      this.spinner.stopSpinner();
      this.snackbar.open(err.error['message'], 'Failed', {
        duration: 5000,
        panelClass: 'snackBar-failure'
      });
    });
  }

  updateEffectiveDate() {
    let body = {
      provider: localStorage.getItem('proID'),
      effective_date: this.effectiveDate
    };
    this.spinner.showSpinner('Loading...Please Wait');
    this.httpCall.postAuth(`${environment.providerDomain}/providerApi/updateEffectiveDate`, body).subscribe(res => {
      this.spinner.stopSpinner();
      this.getEffectiveDate();
      if (res['success']) {
        this.snackbar.open(res['message'], 'Success', {
          duration: 5000,
          panelClass: 'snackBar-success'
        });
      } else {
        this.snackbar.open(res['message'], 'Failed', {
          duration: 5000,
          panelClass: 'snackBar-failure'
        });
      }
    }, (err) => {
      this.spinner.stopSpinner();
      this.snackbar.open(err.error['message'], 'Failed', {
        duration: 5000,
        panelClass: 'snackBar-failure'
      });
    });
  }

  saveStep() {
    this.spinner.showSpinner('Loading...Please Wait');
    this.httpCall.getAuth(`${environment.providerDomain}/providerApi/updateInserted/${localStorage.getItem('proID')}`).subscribe(res => {
      if (res['success']) {
        this.spinner.stopSpinner();
        this.snackbar.open(res['message'], 'Success', {
          duration: 5000,
          panelClass: 'snackBar-success'
        });
        this.router.navigate(['/shared/rule-engine']);
      } else {
        this.spinner.stopSpinner();
        this.snackbar.open(res['message'], 'Failed', {
          duration: 5000,
          panelClass: 'snackBar-failure'
        });
      }
    }, (err) => {
      this.spinner.stopSpinner();
      this.snackbar.open(err.error['message'], 'Failed', {
        duration: 5000,
        panelClass: 'snackBar-failure'
      });
    });
  }

}
