import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpCallService } from 'src/app/services/http-call.service';
import { environment } from 'src/environments/environment.prod';
import { FormControl } from '@angular/forms';

interface DialogData {
  isNew: boolean;
  docID: string;
  isChild?: boolean;
  serviceItem?: any;
  insuranceList?: any;
}

@Component({
  selector: 'app-add-edit-physician-service',
  templateUrl: './add-edit-physician-service.component.html',
  styleUrls: ['./add-edit-physician-service.component.scss']
})
export class AddEditPhysicianServiceComponent implements OnInit {

  serviceObj = {
    codeType: 1,
    itemCode: '',
    serviceName: '',
    serviceID: '',
    shortDesc: '',
    description: '',
    standardPriceIP: null,
    standardPriceOP: null,
    cashPriceIP: null,
    cashPriceOP: null,
    isBundled: false
  };

  docInsuranceList: any = [];

  serviceNameControl: FormControl = new FormControl();
  servicesDataList: any = [];

  codeTypes = environment.codeTypes;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<AddEditPhysicianServiceComponent>,
    // private spinner: SpinnerService,
    private httpCall: HttpCallService
  ) { 
    if(data != null) {
      this.data.insuranceList.forEach(element => {
        let newItem = {
          carrier_name: element.name,
          carrier_id: element.carrierId,
          ip_price: null,
          op_price: null
        }
        this.docInsuranceList.push(newItem);
      });
    }
  }

  ngOnInit(): void {
    if(!this.data.isNew) {
      this.getServiceDetails(this.data.serviceItem.id);
    }
  }

  servicesList: any = []
  getServicesLookup(serviceName, codeType) {
    // this.spinner.spin$.next(true);
    this.httpCall.getAuth(`${environment.providerDomain}/BackEnd/testsLookupForProvider/${serviceName}`).subscribe( res => {
      // this.spinner.spin$.next(false);
      if(res['success']) {
        this.servicesList = res['response'];
      } else {
        // this.snackbar.open(res['message'], 'Failed', {
        //   duration: 5000
        // });
      }
    }, (err) => {
      // this.spinner.spin$.next(false);
      // this.snackbar.open(err.message, 'Failed', {
      //   duration: 5000
      // });
    });
  }

  getServiceDetails(serviceID) {
    // this.spinner.spin$.next(true);
    this.httpCall.getAuth(`${environment.providerDomain}/providerApi/getSingleDoctorTest/${serviceID}/${this.data.docID}`).subscribe( res => {
      // this.spinner.spin$.next(false);
      if(res['success']) {
        this.serviceObj.codeType = res['response'].code_type;
        this.serviceObj.itemCode = res['response'].cpt_code;
        this.serviceObj.serviceName = res['response'].test_name;
        this.serviceObj.serviceID = res['response'].master_id;
        this.serviceObj.shortDesc = res['response'].short_description;
        this.serviceObj.description = res['response'].description;
        this.serviceObj.standardPriceIP = res['response'].std_price_ip;
        this.serviceObj.standardPriceOP = res['response'].std_price_op;
        this.serviceObj.cashPriceIP = res['response'].cash_price_ip;
        this.serviceObj.cashPriceOP = res['response'].cash_price_op;
        this.serviceObj.isBundled = res['response'].is_bundled;
        
        this.docInsuranceList.forEach(element => {
          let filtered = res['response'].insurance_prices.filter( item => item.carrier_id == element.carrier_id);
          if(filtered) {
            element.ip_price = filtered[0].ip_price;
            element.op_price = filtered[0].op_price;
          }
        });
      } else {
        alert(res['message']);
        // this.snackbar.open(res['message'], 'Failed', {
        //   duration: 5000
        // });
      }
    }, (err) => {
      // this.spinner.spin$.next(false);
      // this.snackbar.open(err.message, 'Failed', {
      //   duration: 5000
      // });
    });
  }

  selectService(selectedItem) {
    this.serviceObj.serviceID = selectedItem._id;
  }

  submitPhysicianService() {
    let body;
    if(this.data.isNew) {
      if(this.data.isChild) {
        // Add child service
        body = {
          superKey: this.data.serviceItem.superKey ? this.data.serviceItem.superKey : this.data.serviceItem.id,
          parentId: this.data.serviceItem.parentId ? this.data.serviceItem.parentId : this.data.serviceItem.id,
          doctorId: this.data.docID,
          test_name: this.serviceObj.serviceName,
          code_type: this.serviceObj.codeType,
          cpt_code: this.serviceObj.itemCode,
          description: this.serviceObj.description,
          master_id: this.serviceObj.serviceID,
          prerequisite: '',
          Steps: '',
          short_description: this.serviceObj.shortDesc,
          is_bundled: this.serviceObj.isBundled,
          std_price_ip: this.serviceObj.standardPriceIP,
          std_price_op: this.serviceObj.standardPriceOP,
          cash_price_ip: this.serviceObj.cashPriceIP,
          cash_price_op: this.serviceObj.cashPriceOP,
          insurance_prices: this.docInsuranceList
        };
        // this.spinner.spin$.next(true);
        this.httpCall.postAuth(`${environment.providerDomain}/providerApi/addSubTest`, body).subscribe( res => {
          // this.spinner.spin$.next(false);
          if(res['success']) {
            this.dialogRef.close(res);
          } else {
            alert(res['message']);
            // this.snackbar.open(res['message'], 'Failed', {
            //   duration: 5000
            // });
          }
        }, (err) => {
          // this.spinner.spin$.next(false);
          // this.snackbar.open(err.message, 'Failed', {
          //   duration: 5000
          // });
        });
      } else {
        // Add parent service
        body = {
          doctorId: this.data.docID,
          test_name: this.serviceObj.serviceName,
          code_type: this.serviceObj.codeType,
          cpt_code: this.serviceObj.itemCode,
          description: this.serviceObj.description,
          master_id: this.serviceObj.serviceID,
          prerequisite: '',
          Steps: '',
          short_description: this.serviceObj.shortDesc,
          is_bundled: this.serviceObj.isBundled,
          std_price_ip: this.serviceObj.standardPriceIP,
          std_price_op: this.serviceObj.standardPriceOP,
          cash_price_ip: this.serviceObj.cashPriceIP,
          cash_price_op: this.serviceObj.cashPriceOP,
          insurance_prices: this.docInsuranceList
        };
        // this.spinner.spin$.next(true);
        this.httpCall.postAuth(`${environment.providerDomain}/providerApi/mapDoctorService`, body).subscribe( res => {
          // this.spinner.spin$.next(false);
          if(res['success']) {
            this.dialogRef.close(res);
          } else {
            alert(res['message']);
            // this.snackbar.open(res['message'], 'Failed', {
            //   duration: 5000
            // });
          }
        }, (err) => {
          // this.spinner.spin$.next(false);
          // this.snackbar.open(err.message, 'Failed', {
          //   duration: 5000
          // });
        });
      }
    } else {
      if(this.data.isChild) {
        // Edit child service
        body = {
          testId: this.data.serviceItem.id,
          superKey: this.data.serviceItem.superKey ? this.data.serviceItem.superKey : this.data.serviceItem.id,
          parentId: this.data.serviceItem.parentId ? this.data.serviceItem.parentId : this.data.serviceItem.id,
          doctorId: this.data.docID,
          test_name: this.serviceObj.serviceName,
          code_type: this.serviceObj.codeType,
          cpt_code: this.serviceObj.itemCode,
          description: this.serviceObj.description,
          master_id: this.serviceObj.serviceID,
          prerequisite: '',
          Steps: '',
          short_description: this.serviceObj.shortDesc,
          is_bundled: this.serviceObj.isBundled,
          std_price_ip: this.serviceObj.standardPriceIP,
          std_price_op: this.serviceObj.standardPriceOP,
          cash_price_ip: this.serviceObj.cashPriceIP,
          cash_price_op: this.serviceObj.cashPriceOP,
          insurance_prices: this.docInsuranceList
        };
        // this.spinner.spin$.next(true);
        this.httpCall.putAuth(`${environment.providerDomain}/providerApi/updateDoctorService`, body).subscribe( res => {
          // this.spinner.spin$.next(false);
          if(res['success']) {
            this.dialogRef.close(res);
          } else {
            alert(res['message']);
            // this.snackbar.open(res['message'], 'Failed', {
            //   duration: 5000
            // });
          }
        }, (err) => {
          // this.spinner.spin$.next(false);
          // this.snackbar.open(err.message, 'Failed', {
          //   duration: 5000
          // });
        });
      } else {
        // Edit parent service
        body = {
          testId: this.data.serviceItem.id,
          doctorId: this.data.docID,
          test_name: this.serviceObj.serviceName,
          code_type: this.serviceObj.codeType,
          cpt_code: this.serviceObj.itemCode,
          description: this.serviceObj.description,
          master_id: this.serviceObj.serviceID,
          prerequisite: '',
          Steps: '',
          short_description: this.serviceObj.shortDesc,
          is_bundled: this.serviceObj.isBundled,
          std_price_ip: this.serviceObj.standardPriceIP,
          std_price_op: this.serviceObj.standardPriceOP,
          cash_price_ip: this.serviceObj.cashPriceIP,
          cash_price_op: this.serviceObj.cashPriceOP,
          insurance_prices: this.docInsuranceList
        };
        // this.spinner.spin$.next(true);
        this.httpCall.putAuth(`${environment.providerDomain}/providerApi/updateDoctorService`, body).subscribe( res => {
          // this.spinner.spin$.next(false);
          if(res['success']) {
            this.dialogRef.close(res);
          } else {
            alert(res['message']);
            // this.snackbar.open(res['message'], 'Failed', {
            //   duration: 5000
            // });
          }
        }, (err) => {
          // this.spinner.spin$.next(false);
          // this.snackbar.open(err.message, 'Failed', {
          //   duration: 5000
          // });
        });
      }
    }
  }

}
