import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpCallService } from 'src/app/services/http-call.service';
import { environment } from 'src/environments/environment.prod';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { SpinnerService } from 'src/app/services/spinner.service';

interface DialogData {
  isNew: boolean;
  proID: string;
  isChild?: boolean;
  serviceItem?: any;
  insuranceList?: any;
}

@Component({
  selector: 'app-add-edit-optional-services',
  templateUrl: './add-edit-optional-services.component.html',
  styleUrls: ['./add-edit-optional-services.component.scss']
})
export class AddEditOptionalServicesComponent implements OnInit {

  optionalTestCategories = [
    { name: 'EVALUATION & MANAGEMENT SERVICES', masterID: '60095f2f23b5e2083c46a652' },
    { name: 'LABORATORY & PATHOLOGY SERVICES', masterID: '60095f2f23b5e2083c46a638' },
    { name: 'RADIOLOGY SERVICES', masterID: '60095f2f23b5e2083c46a63f' },
    { name: 'MEDICINE & SURGERY SERVICES', masterID: '60095f2f23b5e2083c46a654' },
    { name: 'OTHERS', masterID: '606ebd93aa30040b0b5c5889' }
  ];

  serviceObj = {
    codeType: 1,
    itemCode: '',
    serviceName: '',
    serviceID: '',
    shortDesc: '',
    description: '',
    standardPriceIP: null,
    standardPriceOP: null,
    cashPriceIP: null,
    cashPriceOP: null,
    outNetworkPriceIP: null,
    outNetworkPriceOP: null,
    // minPriceIP: null,
    // minPriceOP: null,
    // maxPriceIP: null,
    // maxPriceOP: null,
    isBundled: false,
    isShoppable: true,
    isServicable: true,
    categoryType: '',
    serviceItemType: '',
    // billingID: '',
    quantity: '',
    // inclusive: '',
    // exclusive: '',
    facilityFee: '',
    drugFee: '',
    physicianFee: '',
    otherFee: ''
  };

  feeTypes = [
    { name: 'Included', value: 'Y' },
    { name: 'Not Included', value: 'N' },
    { name: 'Partially Included', value: 'P' },
    { name: 'Not Applicable', value: 'NA' },
  ];

  proInsuranceList: any = [];

  serviceNameControl: FormControl = new FormControl();
  servicesDataList: any = [];

  serviceItemTypeControl = new FormControl();
  filteredServiceItemTypesOptions: Observable<string[]>;

  codeTypes = environment.codeTypes;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<AddEditOptionalServicesComponent>,
    private snackbar: MatSnackBar,
    private spinner: SpinnerService,
    private httpCall: HttpCallService
  ) { 
    if(data != null) {
      this.data.insuranceList.forEach(element => {
        let newItem = {
          carrier_name: element.name,
          carrier_id: element.carrier_id,
          ip_price: null,
          op_price: null
        }
        this.proInsuranceList.push(newItem);
      });
    }
  }
  
  serviceItemTypes = [
    { item_type: 'Facility Fee' },
    { item_type: 'Drug Fee' },
    { item_type: 'Physician Fee' },
    { item_type: 'Other Fee' },
  ];

  ngOnInit(): void {
    if(!this.data.isNew) {
      this.getServiceDetails(this.data.serviceItem.id);
    }
    // this.getServiceItemTypes(localStorage.getItem('proID'));
    // this.filteredServiceItemTypesOptions = this.serviceItemTypeControl.valueChanges.pipe( startWith(''), map(value => this.filterServiceItemTypes(value)) );
  }

  // filterServiceItemTypes(value: string) {
  //   if(value) {
  //     const filterValue = value.toLowerCase();
  //     return this.serviceItemTypes.filter(element => element.item_type.toLowerCase().includes(filterValue));
  //   }
  // }

  searchBy = '1';
  servicesList: any = []
  getServicesLookup(serviceName, codeType) {
    if(serviceName) {
      let body = {
        code_type: codeType,
        text: serviceName,
        type: parseInt(this.searchBy)
      };
      this.httpCall.postAuth(`${environment.providerDomain}/providerApi/drgLookup`, body).subscribe( res => {
        // this.spinner.spin$.next(false);
        if(res['success']) {
          this.servicesList = res['response'];
        } else {
          // this.snackbar.open(res['message'], 'Failed', {
          //   duration: 5000
          // });
        }
      }, (err) => {
        // this.spinner.spin$.next(false);
        // this.snackbar.open(err.message, 'Failed', {
        //   duration: 5000
        // });
      });
    }
  }

  // serviceItemTypes: any = [];
  // getServiceItemTypes(proID) {
  //   this.spinner.showSpinner('Loading...Please Wait');
  //   this.httpCall.getAuth(`${environment.providerDomain}/BackEnd/getServiceTypes/${proID}`).subscribe(res => {
  //     this.spinner.stopSpinner();
  //     if (res['success']) {
  //       this.serviceItemTypes = res['response'];
  //     } else {
  //       this.snackbar.open(res['message'], 'Failed', {
  //         duration: 5000,
  //         panelClass: 'snackBar-failure'
  //       });
  //     }
  //   }, (err) => {
  //     this.spinner.stopSpinner();
  //     this.snackbar.open(err.error['message'], 'Failed', {
  //       duration: 5000,
  //       panelClass: 'snackBar-failure'
  //     });
  //   });
  // }

  getServiceDetails(serviceID) {
    this.httpCall.getAuth(`${environment.providerDomain}/providerApi/getSingleProviderTest/${serviceID}/${this.data.proID}`).subscribe( res => {
      if(res['success']) {
        this.serviceObj.codeType = res['response'].code_type;
        this.serviceObj.itemCode = res['response'].cpt_code;
        this.serviceObj.serviceItemType = res['response'].item_type;
        this.serviceObj.serviceName = res['response'].test_name;
        this.serviceObj.serviceID = res['response'].master_id;
        this.serviceObj.shortDesc = res['response'].short_description;
        this.serviceObj.description = res['response'].description;
        this.serviceObj.categoryType = res['response'].department;
        this.serviceObj.standardPriceIP = res['response'].std_price_ip;
        this.serviceObj.standardPriceOP = res['response'].std_price_op;
        this.serviceObj.cashPriceIP = res['response'].cash_price_ip;
        this.serviceObj.cashPriceOP = res['response'].cash_price_op;
        this.serviceObj.outNetworkPriceIP = res['response'].outofnetwork_ip;
        this.serviceObj.outNetworkPriceOP = res['response'].outofnetwork_op;
        // this.serviceObj.minPriceIP = res['response'].min_price_ip;
        // this.serviceObj.minPriceOP = res['response'].min_price_op;
        // this.serviceObj.maxPriceIP = res['response'].max_price_ip;
        // this.serviceObj.maxPriceOP = res['response'].max_price_op;
        this.serviceObj.isBundled = res['response'].is_bundled;
        this.serviceObj.isServicable = res['response'].isServicable;
        
        this.proInsuranceList.forEach(element => {
          let filtered = res['response'].insurance_prices.filter( item => item.carrier_id == element.carrier_id);
          if(filtered) {
            element.ip_price = filtered[0].ip_price;
            element.op_price = filtered[0].op_price;
          }
        });
        this.serviceObj.facilityFee = res['response'].Facility_fee;
        this.serviceObj.drugFee = res['response'].Drug_fee;
        this.serviceObj.physicianFee = res['response'].Physician_fee;
        this.serviceObj.otherFee = res['response'].Other_fee;
        // this.serviceObj.inclusive = res['response'].inclusive;
        // this.serviceObj.exclusive = res['response'].exclusive;
      } else {
        this.snackbar.open(res['message'], 'Failed', {
          duration: 5000,
          panelClass: 'snackBar-failure'
        });
      }
    }, (err) => {
      this.snackbar.open(err.error['message'], 'Failed', {
        duration: 5000,
        panelClass: 'snackBar-failure'
      });
    });
  }

  selectService(selectedItem) {
    this.serviceObj.serviceID = selectedItem._id;
    this.serviceObj.itemCode = selectedItem.cpt_code;
    this.serviceObj.shortDesc = selectedItem.test_name;
  }

  submitServiceDetails() {
    let body;
    if(this.data.isNew) {
      // body = {
      //   // _id: this.serviceObj._id,
      //   // master_id: this.serviceObj.master_id,
      //   // master_key: this.serviceObj.master_key,
      //   // superKey: this.serviceObj._id,
      //   // parentId: this.serviceObj._id,
      //   // isServicable: this.serviceObj.isServicable,
      //   is_bundled: this.serviceObj.isBundled,
      //   std_price_ip: this.serviceObj.standardPriceIP,
      //   std_price_op: this.serviceObj.standardPriceOP,
      //   cash_price_ip: this.serviceObj.cashPriceIP,
      //   cash_price_op: this.serviceObj.cashPriceOP,
      //   isMandatory: false,
      //   isShoppable: this.serviceObj.isShoppable,
      //   code_type: this.serviceObj.codeType,
      //   provider: localStorage.getItem('proID'),
      //   // billingId: this.serviceObj.billingID,
      //   test_name: this.serviceObj.serviceName,
      //   short_description: this.serviceObj.shortDesc,
      //   cpt_code: this.serviceObj.itemCode,
      //   quantity: this.serviceObj.quantity,
      //   item_type: this.serviceObj.serviceItemType,
      //   department: this.serviceObj.categoryType,
      //   insurance_prices: this.proInsuranceList
      // };
      // this.dialogRef.close(body);
      if(this.data.isChild) {

      } else {
        this.serviceObj.shortDesc = this.serviceObj.shortDesc ? this.serviceObj.shortDesc : this.serviceObj.serviceName;
        let returnedData = {
          notFromChargeMaster: true,
          selectedService: this.serviceObj,
          proInsuranceList: this.proInsuranceList
        }
        this.dialogRef.close(returnedData);
      }
    } else {
      if(this.data.isChild) {
        // Edit child service
        body = {
          testId: this.data.serviceItem.id,
          superKey: this.data.serviceItem.superKey ? this.data.serviceItem.superKey : this.data.serviceItem.id,
          parentId: this.data.serviceItem.parentId ? this.data.serviceItem.parentId : this.data.serviceItem.id,
          providerId: this.data.proID,
          test_name: this.serviceObj.serviceName.toUpperCase(),
          code_type: this.serviceObj.codeType,
          cpt_code: this.serviceObj.itemCode,
          description: this.serviceObj.description,
          master_id: this.serviceObj.serviceID,
          prerequisite: '',
          Steps: '',
          short_description: this.serviceObj.shortDesc,
          is_bundled: this.serviceObj.isBundled,
          std_price_ip: this.serviceObj.standardPriceIP,
          std_price_op: this.serviceObj.standardPriceOP,
          cash_price_ip: this.serviceObj.cashPriceIP,
          cash_price_op: this.serviceObj.cashPriceOP,
          insurance_prices: this.proInsuranceList
        };
        // this.spinner.spin$.next(true);
        this.httpCall.putAuth(`${environment.providerDomain2}/providerApi/updatedTest`, body).subscribe( res => {
          // this.spinner.spin$.next(false);
          if(res['success']) {
            this.dialogRef.close(res);
          } else {
            alert(res['message']);
            // this.snackbar.open(res['message'], 'Failed', {
            //   duration: 5000
            // });
          }
        }, (err) => {
          // this.spinner.spin$.next(false);
          // this.snackbar.open(err.message, 'Failed', {
          //   duration: 5000
          // });
        });
      } else {
        // Edit parent service
        body = {
          testId: this.data.serviceItem.id,
          providerId: this.data.proID,
          test_name: this.serviceObj.serviceName.toUpperCase(),
          code_type: this.serviceObj.codeType,
          cpt_code: this.serviceObj.itemCode,
          description: this.serviceObj.description,
          master_id: this.serviceObj.serviceID,
          prerequisite: '',
          Steps: '',
          short_description: this.serviceObj.shortDesc,
          is_bundled: this.serviceObj.isBundled,
          std_price_ip: this.serviceObj.standardPriceIP,
          std_price_op: this.serviceObj.standardPriceOP,
          cash_price_ip: this.serviceObj.cashPriceIP,
          cash_price_op: this.serviceObj.cashPriceOP,
          insurance_prices: this.proInsuranceList,
          Facility_fee: this.serviceObj.facilityFee,
          Drug_fee: this.serviceObj.drugFee,
          Physician_fee: this.serviceObj.physicianFee,
          Other_fee: this.serviceObj.otherFee,
          // inclusive: this.serviceObj.inclusive,
          // exclusive: this.serviceObj.exclusive,
        };
        // this.spinner.spin$.next(true);
        this.httpCall.putAuth(`${environment.providerDomain2}/providerApi/updatedTest`, body).subscribe( res => {
          // this.spinner.spin$.next(false);
          if(res['success']) {
            this.dialogRef.close(res);
          } else {
            alert(res['message']);
            // this.snackbar.open(res['message'], 'Failed', {
            //   duration: 5000
            // });
          }
        }, (err) => {
          // this.spinner.spin$.next(false);
          // this.snackbar.open(err.message, 'Failed', {
          //   duration: 5000
          // });
        });
      }
    }
  }
}
