<form #addForm="ngForm" (ngSubmit)="submitRoleDetails()">
    <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
        <h2 class="headline m-0" fxFlex="auto">{{ data.isNew ? "Add" : "Update"}} Role</h2>
        <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <mat-divider class="mb-4"></mat-divider>

    <mat-dialog-content fxLayout="column">
        <p>
            <mat-form-field appearance="fill">
                <mat-label>Role Name</mat-label>
                <input matInput name="roleName" ngModel #roleName="ngModel" type="text" [(ngModel)]="roleObj.roleName" required>
                <mat-error *ngIf="roleName.touched && roleName.invalid">
                    <mat-error *ngIf="roleName.errors.required">Role Name is required</mat-error>
                </mat-error>
            </mat-form-field>
        </p>
        <p>
            <mat-form-field appearance="fill">
                <mat-label>Features</mat-label>
                <mat-select ngModel name="features" #features="ngModel" [(ngModel)]="roleObj.selectedRoles" multiple required>
                    <mat-option *ngFor="let feature of featuresList" [value]="feature._id">{{ feature.Flag_name }}</mat-option>
                </mat-select>
                <mat-error *ngIf="features.touched && features.invalid">
                    <mat-error *ngIf="features.errors.required">Features is required</mat-error>
                </mat-error>
            </mat-form-field>
        </p>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button [disabled]="addForm.form.invalid" color="primary" mat-button type="submit">Save</button>
        <button mat-button mat-dialog-close type="button">Cancel</button>
    </mat-dialog-actions>
</form>