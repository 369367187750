import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SelectItem } from 'primeng/api';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { HttpCallService } from 'src/app/services/http-call.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-create-new-inventory',
  templateUrl: './create-new-inventory.component.html',
  styleUrls: ['./create-new-inventory.component.scss']
})
export class CreateNewInventoryComponent implements OnInit {

  myControl = new FormControl();
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;
  minDate: Date;
  servicesList: any;

  constructor(
    private snackbar: MatSnackBar,
    private spinner: SpinnerService,
    private httpCall : HttpCallService,
    public dialogRef: MatDialogRef<CreateNewInventoryComponent>
  ) { 
    this.minDate = new Date();
  }

  categories:any;

  ngOnInit(): void {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
    this.categories = environment.optionalTestCategories;
  }

  selectedFromDate: Date | null = null;
  selectedToDate: Date | null = null;

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  quantity:string;
  discount:string;

  createInventory(){
    let body = {
      provider: localStorage.getItem('proID'),
      items:[
        {
          resource: this.selectedService._id,
          qty: parseInt(this.quantity),
          discount: parseInt(this.discount),
          from: this.convertToCustomFormat(this.selectedFromDate),
          to: this.convertToCustomFormat(this.selectedToDate),
          category: this.selectedCategory.masterID
        }
      ]
    }
    this.httpCall.postAuth(`${environment.inventory}/submitInventory`, body).subscribe( res => {
      if (res['success']) {
        if (res['response'].length > 0) {
          this.servicesList = res['response'];
          this.snackbar.open(res['message'], 'Success', {
            duration: 5000,
            panelClass: 'snackBar-success'
          });
        }
      } else {
        this.snackbar.open(res['response'], 'Failed', {
          duration: 5000,
          panelClass: 'snackBar-failure'
        });
      }
      this.dialogRef.close();
    }, (err) => {
      this.snackbar.open(err.error['message'], 'Failed', {
        duration: 5000,
        panelClass: 'snackBar-failure'
      });
      this.dialogRef.close();
    } )
  }

  onFromDateSelected(event: any) {
    const fromDate = event;
    this.selectedFromDate = fromDate;
    this.selectedToDate = this.getToDate(fromDate);
    console.log(this.selectedFromDate);
  }

  getToDate(fromDate: Date): Date {
    const toDate = new Date(fromDate);
    toDate.setDate(toDate.getDate() + 365);
    return toDate;
  }

  globalSearchInputValue:any;
  serviceNameControl: FormControl = new FormControl();
  selectedGlobalSearchType: number = 1;
  selectedYear:number= 2023;

  getServicesLookup() {
    if (this.globalSearchInputValue) {
      let body = {
        type: this.selectedGlobalSearchType,
        text: this.globalSearchInputValue.toUpperCase(),
        providerId: "5fe4ed7b50124b16fd34714f",
        year:this.selectedYear

      };
      this.httpCall.get(`${environment.queue_domain}/searchTest/${this.globalSearchInputValue.toUpperCase()}`).subscribe(res => {
        if (res['success']) {
          if (res['response'].length > 0) {
            this.servicesList = res['response'];
          }
        } else {
          this.snackbar.open(res['response'], 'Failed', {
            duration: 5000,
            panelClass: 'snackBar-failure'
          });
        }
      }, (err) => {
        this.snackbar.open(err.error['message'], 'Failed', {
          duration: 5000,
          panelClass: 'snackBar-failure'
        });
      });
    }
  }

  selectedService:any;
  selectedCategory:any;

  selectService(item){
    this.selectedService = item;
  }

  selectCategory(item){
    this.selectedCategory = item;
  }

  convertToCustomFormat(date) {
    let year = date.getFullYear();
    let month = ('0' + (date.getMonth() + 1)).slice(-2);
    let day = ('0' + date.getDate()).slice(-2);
    let hours = ('0' + date.getHours()).slice(-2);
    let minutes = ('0' + date.getMinutes()).slice(-2);
    let seconds = ('0' + date.getSeconds()).slice(-2);
    let timezoneOffset = date.getTimezoneOffset();
    let timezoneOffsetHours = Math.abs(Math.floor(timezoneOffset / 60));
    let timezoneOffsetMinutes = Math.abs(timezoneOffset % 60);
    let timezoneOffsetSign = timezoneOffset >= 0 ? '+' : '-';
    let timezoneOffsetFormatted = timezoneOffsetSign + ('0' + timezoneOffsetHours).slice(-2) + ':' + ('0' + timezoneOffsetMinutes).slice(-2);
  
    let convertedDateString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${timezoneOffsetFormatted}`;
  
    return convertedDateString;
  }

}
