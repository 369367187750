<!-- <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
    <h2 class="headline m-0" fxFlex="auto">Video Link</h2>
    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
        <mat-icon>close</mat-icon>
    </button>
</div>
  
<mat-divider class="mb-4"></mat-divider>
  
<mat-dialog-content fxLayout="column">
    <div>
        <iframe [src]="getVideoLink()" style="width: 100%;height: 400px;"></iframe>
    </div>
</mat-dialog-content> -->

<div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
    <h2 class="headline m-0" fxFlex="auto">Watch Video</h2>
    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
        <mat-icon>close</mat-icon>
    </button>
</div>

<mat-divider class="mb-4"></mat-divider>

<mat-dialog-content class="text-center">
    <iframe width="560" height="315" [src]="urlLink" title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</mat-dialog-content>