import { Component, OnInit, Input } from '@angular/core';
import { HttpCallService } from 'src/app/services/http-call.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment.prod';
import { TreeNode } from 'primeng/api';
import { AddEditPhysicianServiceComponent } from '../add-edit-physician-service/add-edit-physician-service.component';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-manage-physician-service',
    templateUrl: './manage-physician-service.component.html',
    styleUrls: ['./manage-physician-service.component.scss']
})
export class ManagePhysicianServiceComponent implements OnInit {

    @Input() docData;

    constructor(
        public httpCall: HttpCallService,
        public http: HttpClient,
        // public router: Router,
        // public activatedRoute: ActivatedRoute,
        private spinner: SpinnerService,
        public dialog: MatDialog,
    ) { }

    panelExpanded() {
        if (this.physicianServicesList.length > 0) {
            return false;
        } else {
            return true;
        }
    }

    downloadPhysicianServiceTemplate() {
        window.open(`${environment.providerDomain}/BackEnd/downloadDoctorServiceCSV/${this.docData._id}`);
    }

    onPhysicianServiceCSVUpload(event) {
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
                const fd: FormData = new FormData();
                let selectedFile = <File>event.target.files[0];
                fd.set('file', selectedFile, selectedFile.name);
                let headers = new HttpHeaders({
                    'authorization': localStorage.getItem('token')
                });
                // let options = {
                //     headers: headers
                // };
                // this.http.post(`${environment.providerDomain}/providerApi/uploadDoctorServiceCsv/${localStorage.getItem('proID')}/${this.docData._id}`, fd, options).subscribe((res: any) => {
                //     this.spinner.spin$.next(false);
                //     if(res['success']) {
                //         this.getAddedPhysicianServices(this.docData._id);
                //     } else {
                //       // this.snackbar.open(res['message'], 'Failed', {
                //       //   duration: 5000
                //       // });
                //     }
                // }, (err) => {
                //     this.spinner.spin$.next(false);
                //     // this.snackbar.open(err.message, 'Failed', {
                //     //   duration: 5000
                //     // });
                // });
            }
            reader.readAsDataURL(event.target.files[0]);
        }
    }

    ngOnInit(): void {

        this.physicianServiceCols = [
            { field: 'test_name', header: 'Service Name' },
            { field: 'cpt_code', header: 'Service Code' },
            { field: 'cash_price_ip', header: 'Cash Price(IP)' },
            { field: 'cash_price_op', header: 'Cash Price(OP)' },
            { field: 'std_price_ip', header: 'Standard Price(IP)' },
            { field: 'std_price_op', header: 'Standard Price(OP)' },
        ];

        this.getPhysicianInsurances(this.docData._id);

        this.getAddedPhysicianServices(this.docData._id);
    }

    physicianServicesList: TreeNode[] = [];
    physicianServiceCols: any = [];
    getAddedPhysicianServices(docID) {
        // this.spinner.spin$.next(true);
        this.httpCall.getAuth(`${environment.providerDomain}/providerApi/getDoctorService/${docID}`).subscribe(res => {
            // this.spinner.spin$.next(false);
            if (res['success']) {
                this.physicianServiceCols = [ ...this.physicianServiceCols, ...res['response'].head ];
                this.physicianServicesList = res['response'].data;
            } else {
                // this.snackbar.open(res['message'], 'Failed', {
                //   duration: 5000
                // });
            }
        }, (err) => {
            // this.spinner.spin$.next(false);
            // this.snackbar.open(err.message, 'Failed', {
            //   duration: 5000
            // });
        });
    }

    onNodeExpand(event) {
        const node = event.node;
        let body = {
            superKey: node.data.id,
            parentId: node.data.id
        };
        this.httpCall.postAuth(`${environment.providerDomain}/providerApi/getAllTestChildren`, body).subscribe(res => {
            // this.spinner.spin$.next(false);
            if (res['success']) {
                node.children = res['response'];
                this.physicianServicesList = [...this.physicianServicesList];
            } else {
                // this.snackbar.open(res['message'], 'Failed', {
                //   duration: 5000
                // });
            }
        }, (err) => {
            // this.spinner.spin$.next(false);
            // this.snackbar.open(err.message, 'Failed', {
            //   duration: 5000
            // });
        });
    }

    docInsuranceList: any = [];
    getPhysicianInsurances(docID) {
        this.httpCall.getAuth(`${environment.providerDomain}/providerApi/getDoctorInsurances/${docID}`).subscribe(res => {
            // this.spinner.spin$.next(false);
            if (res['success']) {
                this.docInsuranceList = res['response'];
            } else {
                // this.snackbar.open(res['message'], 'Failed', {
                //   duration: 5000
                // });
            }
        }, (err) => {
            // this.spinner.spin$.next(false);
            // this.snackbar.open(err.message, 'Failed', {
            //   duration: 5000
            // });
        });
    }

    async openPhysicianServiceDialog(isNew: boolean, selectedItem?: any, isChildNode?: boolean) {
        if(!isChildNode && selectedItem) {
            isChildNode = selectedItem?.superKey && selectedItem?.parentId ? true : false;
        }
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.minWidth = '30vw';
        dialogConfig.data = {
            isNew: isNew,
            docID: this.docData._id,
            isChild: isChildNode,
            serviceItem: selectedItem,
            insuranceList: this.docInsuranceList.filter( element => element.state == 1)
        };
        const dialogRef = this.dialog.open(AddEditPhysicianServiceComponent, dialogConfig);
        dialogRef.beforeClosed().subscribe(data => {
            if (data) {
                if (data.success) {
                    this.getAddedPhysicianServices(this.docData._id);
                }
            }
        });
    }

}
