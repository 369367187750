<form #addForm="ngForm" (ngSubmit)="submitInsuranceDetails()">
    <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
        <h2 class="headline m-0" fxFlex="auto">Update insurances</h2>
        <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <mat-divider class="mb-4"></mat-divider>

    <mat-dialog-content fxLayout="column">
        <p>
            <mat-form-field appearance="fill">
                <mat-label>Search Insurance Company</mat-label>
                <input matInput name="insurance" [(ngModel)]="enteredInsurance" (click)="$event.target.select()" [matAutocomplete]="autoInsurance" type="text" autocomplete="off">
                <mat-autocomplete #autoInsurance="matAutocomplete">
                    <mat-option *ngFor="let item of insuranceList" (click)="addInsurance(item)" [value]="item?.Name">
                    {{ item?.Name }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </p>
        <ng-container *ngIf="selectedInsuranceList.length > 0">
            <div>
                <h4>New insurances:</h4>
                <div class="p-2 card my-2" *ngFor="let item of selectedInsuranceList; index as i;">
                    <div class="d-flex align-items-center">
                        <div class="w-100">{{ item?.name }}</div>
                        <div class="flex-shrink-1">
                            <button mat-icon-button (click)="removeInsurance(item)">
                                <mat-icon aria-hidden="false">close</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="addedInsurancesList.length > 0">
            <div>
                <h4>Added insurances:</h4>
                <div class="p-2 card my-2" *ngFor="let item of addedInsurancesList; index as i;">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <p class="m-0">{{ item?.name }}</p>
                            <div class="badge badge-primary">{{item?.state == 1 ? "Active" : "Inactive"}}</div>
                        </div>
                        <div class="flex-shrink-1">
                            <button mat-stroked-button (click)="changeInsuranceStatus(item._id, item?.state)">{{item?.state == 1 ? "Inactive" : "Active"}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button [disabled]="addForm.form.invalid" color="primary" mat-button type="submit">Save</button>
        <button mat-button mat-dialog-close type="button">Cancel</button>
    </mat-dialog-actions>
</form>