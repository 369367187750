import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import { GooglePlacesDirective } from '../services/google-places.directive';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { FooterComponent } from './footer/footer.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AddEditPhysicianComponent } from './add-edit-physician/add-edit-physician.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule, MatNativeDateModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AddEditPhysicianServiceComponent } from './add-edit-physician-service/add-edit-physician-service.component';
import { ManagePhysicianServiceComponent } from './manage-physician-service/manage-physician-service.component';
import { TreeTableModule } from 'primeng/treetable';
import { TableModule } from 'primeng/table';
import { TimelineModule } from "primeng/timeline";
import { CardModule } from "primeng/card";
import { GalleriaModule } from 'primeng/galleria';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { StarRatingComponent } from './star-rating.component';
import { StarLargeRatingComponent } from './star-large-rating.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MAT_COLOR_FORMATS, NgxMatColorPickerModule, NGX_MAT_COLOR_FORMATS } from '@angular-material-components/color-picker';

import { ManagePhysicianInsurancesModalComponent } from './manage-physician-insurances-modal/manage-physician-insurances-modal.component';
import { AddEditMandatoryServicesComponent } from './add-edit-mandatory-services/add-edit-mandatory-services.component';
import { AddEditOptionalServicesComponent } from './add-edit-optional-services/add-edit-optional-services.component';
import { AddEditPrimaryContactsComponent } from './add-edit-primary-contacts/add-edit-primary-contacts.component';
import { AddMultiChildrenComponent } from './add-multi-children/add-multi-children.component';
import { AddEditChargeMasterServiceComponent } from './add-edit-charge-master-service/add-edit-charge-master-service.component';
import { AddMultiParentComponent } from './add-multi-parent/add-multi-parent.component';
import { ViewServiceDetailComponent } from './view-service-detail/view-service-detail.component';
import { ReadMoreComponent } from './read-more/read-more.component';
import { RuleEngineComponent } from './rule-engine/rule-engine.component';
import { ViewPhysicianDetailComponent } from './view-physician-detail/view-physician-detail.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { NgxPrintModule } from 'ngx-print';
import { ViewChunckDataComponent } from './view-chunck-data/view-chunck-data.component';
import { ViewExplainVideoComponent } from './view-explain-video/view-explain-video.component';
import { DateConversionPipe } from '../services/date-conversion.pipe';
import { AddEditZeaTestComponent } from './add-edit-zea-test/add-edit-zea-test.component';
import { AddEditRoleComponent } from './add-edit-role/add-edit-role.component';
import { DeleteRoleComponent } from './delete-role/delete-role.component';
import { AddEditCmInsurancePricesComponent } from './add-edit-cm-insurance-prices/add-edit-cm-insurance-prices.component';
import { ViewCmInsurancePricesComponent } from './view-cm-insurance-prices/view-cm-insurance-prices.component';
import { ViewServiceInsurancePricesComponent } from './view-service-insurance-prices/view-service-insurance-prices.component';
import { AddEditServiceInsurancePricesComponent } from './add-edit-service-insurance-prices/add-edit-service-insurance-prices.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { NumberSuffixPipe } from '../services/number-suffix.pipe';
import { UpdateRuleEngineComponent } from './update-rule-engine/update-rule-engine.component';
import { CropImageComponent } from './crop-image/crop-image.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AddMrfComponent } from './add-mrf/add-mrf.component';
import { FaqComponent } from './faq/faq.component';
import { PricePipe } from '../services/price.pipe';
import { CommonSpinnerComponent } from './common-spinner/common-spinner.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxEchartsModule } from 'ngx-echarts';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { EmbeddComponent } from './embedd/embedd.component';
import { AddSurgeryComponent } from './add-surgery/add-surgery.component';
import { CreateNewInventoryComponent } from './create-new-inventory/create-new-inventory.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { ViewInventoryComponent } from './view-inventory/view-inventory.component';
import { ZeacardGalleryComponent } from './zeacard-gallery/zeacard-gallery.component';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { InsurancePricesComponent } from './insurance-prices/insurance-prices.component';

@NgModule({
  declarations: [
    GooglePlacesDirective,
    ToolbarComponent,
    FooterComponent,
    StarRatingComponent,
    StarLargeRatingComponent,
    ManagePhysicianServiceComponent,
    ManagePhysicianInsurancesModalComponent,
    AddEditPhysicianComponent,
    AddEditPhysicianServiceComponent,
    AddEditMandatoryServicesComponent,
    AddEditOptionalServicesComponent,
    AddEditPrimaryContactsComponent,
    AddMultiChildrenComponent,
    AddEditChargeMasterServiceComponent,
    AddMultiParentComponent,
    ViewServiceDetailComponent,
    ReadMoreComponent,
    RuleEngineComponent,
    ViewPhysicianDetailComponent,
    ViewChunckDataComponent,
    ViewExplainVideoComponent,
    AddEditZeaTestComponent,
    AddEditRoleComponent,
    DeleteRoleComponent,
    AddEditCmInsurancePricesComponent,
    ViewCmInsurancePricesComponent,
    ViewServiceInsurancePricesComponent,
    AddEditServiceInsurancePricesComponent,
    ContactUsComponent,
    DateConversionPipe,
    NumberSuffixPipe,
    UpdateRuleEngineComponent,
    CropImageComponent,
    AddMrfComponent,
    FaqComponent,
    PricePipe,
    CommonSpinnerComponent,
    TimePickerComponent,
    EmbeddComponent,
    AddSurgeryComponent,
    CreateNewInventoryComponent,
    ViewInventoryComponent,
    ZeacardGalleryComponent,
    InsurancePricesComponent
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    GalleriaModule,
    MatToolbarModule,
    MatIconModule,
    MatDialogModule,
    CalendarModule,
    MatDividerModule,
    MatInputModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatOptionModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatTableModule,
    MatCardModule,
    MatMenuModule,
    MatExpansionModule,
    MatTooltipModule,
    MatRadioModule,
    TableModule,
    TreeTableModule,
    CardModule,
    TimelineModule,
    MultiSelectModule,
    DropdownModule,
    NgxMatSelectSearchModule,
    FlexLayoutModule,
    NgxSpinnerModule,
    NgxPrintModule,
    AutoCompleteModule,
    ImageCropperModule,
    NgxImageZoomModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),

    NgxMatColorPickerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule
  ],
  exports: [
    GooglePlacesDirective,
    ToolbarComponent,
    FooterComponent,
    StarRatingComponent,
    StarLargeRatingComponent,
    ReadMoreComponent,
    ManagePhysicianServiceComponent,
    NgxSpinnerModule,
    NgxPrintModule,
    DateConversionPipe,
    NumberSuffixPipe,
    PricePipe,
    TimePickerComponent,
    EmbeddComponent,
    AddSurgeryComponent
  ],
  entryComponents: [
    AddEditPhysicianComponent,
    ManagePhysicianServiceComponent,
    ManagePhysicianInsurancesModalComponent,
    AddEditMandatoryServicesComponent,
    AddEditPrimaryContactsComponent,
    AddEditChargeMasterServiceComponent,
    ViewChunckDataComponent
  ],
  providers: [
    { provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS }
  ]
})
export class SharedModule { }
