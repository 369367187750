import { Component, OnInit } from '@angular/core';
import { HttpCallService } from '../services/http-call.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment.prod';
import { NgForm } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { GoogleAnalyticsService } from '../services/google-analytics.service';
import { SpinnerService } from '../services/spinner.service';

@Component({
  selector: 'app-provider-landing',
  templateUrl: './provider-landing.component.html',
  styleUrls: ['./provider-landing.component.scss']
})
export class ProviderLandingComponent implements OnInit {

  constructor(
    public httpCall: HttpCallService,
    private snackbar: MatSnackBar,
    private spinner: SpinnerService,
    public router: Router,
    public googleAnalytics: GoogleAnalyticsService,
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.googleAnalytics.pageViews(event.urlAfterRedirects);
      }
    });
  }

  ngOnInit(): void {
  }

  providerDataObj = {
    firstName: '',
    lastName: '',
    userEmail: '',
    phoneNumber: '',
    role: '',
  };
  workLocation = {
    businessName: '',
    providesHealthcare: false,
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipcode: ''
  };

  submitNewsLetterForm(form: NgForm) {
    let body = {
      firstName: this.providerDataObj.firstName,
      lastName: this.providerDataObj.lastName,
      userEmail: this.providerDataObj.userEmail,
      phoneNumber: this.providerDataObj.phoneNumber,
      role: this.providerDataObj.role,
      userType: 'provider',
      workLocation: {
        businessName: this.workLocation.businessName,
        providesHealthcare: this.workLocation.providesHealthcare,
        addressLine1: this.workLocation.addressLine1,
        addressLine2: this.workLocation.addressLine2,
        city: this.workLocation.city,
        state: this.workLocation.state,
        zipcode: this.workLocation.zipcode
      }
    };
    this.spinner.showSpinner('Loading...Please Wait');
    this.httpCall.post(`https://test-api.zeamed.com:8000/BackEnd/addNewsLetter`, body).subscribe(res => {
      this.spinner.stopSpinner();
      if (res['success']) {
        this.snackbar.open(res['message'], 'Success', {
          duration: 5000,
          panelClass: 'snackBar-success'
        });
      } else {
        this.snackbar.open(res['message'], 'Failed', {
          duration: 5000,
          panelClass: 'snackBar-failure'
        });
      }
      form.resetForm();
    }, (err) => {
      this.spinner.stopSpinner();
      this.snackbar.open(err.error['message'], 'Failed', {
        duration: 5000,
        panelClass: 'snackBar-failure'
      });
    });
  }

  openLinkInNewWindow(link) {
    window.open(link, '_blank');
  }

}
