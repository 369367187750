import { Component, OnInit, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpCallService } from 'src/app/services/http-call.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { environment } from 'src/environments/environment.prod';

interface DialogData {
  isNew: boolean;
  proID: string;
  serviceItem?: any;
  proObj: any;
}

@Component({
  selector: 'app-add-edit-zea-test',
  templateUrl: './add-edit-zea-test.component.html',
  styleUrls: ['./add-edit-zea-test.component.scss']
})
export class AddEditZeaTestComponent implements OnInit {

  serviceNameControl: FormControl = new FormControl();
  servicesDataList: any = [];

  codeTypes = environment.codeTypes;
  
  serviceObj = {   
    codeType: 1,
    itemCode: '',
    serviceName: '',
    serviceID: '',
    packagePrice: null,
    discount: null,
    zeaPrice: null,
    duration: null,
    canBook: true,
    physicians: [],
    isEligibleForBid: false,
    is_zeacard_accepted: false,
  };
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<AddEditZeaTestComponent>,
    private snackbar: MatSnackBar,
    private spinner: SpinnerService,
    private httpCall: HttpCallService
  ) { }

  currentUserAccess: any;
  ngOnInit(): void {
    this.currentUserAccess = this.httpCall.currentUserAccess;
    if(!this.data.isNew) {
      this.serviceObj.serviceID = this.data.serviceItem._id;
      this.getServiceDetails(this.serviceObj.serviceID);
      this.getPhysicians(this.data.proID);
    }
  }

  physiciansList: any = [];
  getPhysicians(proID) {
    let body = {
      providerId: proID
    };
    this.httpCall.postAuth(`${environment.providerDomain}/providerApi/getAllDoctors`, body).subscribe(res => {
      if (res['success']) {
        this.physiciansList = res['response'];
      } else {
        this.snackbar.open(res['message'], 'Failed', {
          duration: 5000,
          panelClass: 'snackBar-failure'
        });
      }
    }, (err) => {
      this.snackbar.open(err.error['message'], 'Failed', {
        duration: 5000,
        panelClass: 'snackBar-failure'
      });
    });
  }

  searchBy = '1';
  servicesList: any = []
  getServicesLookup(serviceName, codeType) {
    let body = {
      id: this.data.proID,
      // code_type: codeType,
      text: serviceName,
      // type: parseInt(this.searchBy)
    };
    this.httpCall.postAuth(`${environment.providerDomain}/providerApi/bookingAutoComplete`, body).subscribe( res => {
      if(res['success']) {
        this.servicesList = res['response'];
      }
    }, (err) => {
      // this.snackbar.open(err.message, 'Failed', {
      //   duration: 5000
      // });
    });
  }

  selectService(selectedItem) {
    this.serviceObj.serviceID = selectedItem._id;
  }

  getServiceDetails(serviceID) {
    this.httpCall.getAuth(`${environment.providerDomain}/providerApi/getSingleProviderTest/${serviceID}/${this.data.proID}`).subscribe( res => {
      if(res['success']) {
        this.serviceObj.serviceName = res['response'].test_name != undefined ? res['response'].test_name : '';
        this.serviceObj.duration = parseInt(res['response'].duration);
        this.serviceObj.canBook = res['response'].can_book;
        this.serviceObj.codeType = res['response'].code_type;
        this.serviceObj.itemCode = res['response'].cpt_code;
        this.serviceObj.zeaPrice = res['response'].zea_price;
        this.serviceObj.packagePrice = res['response'].Bundled_Price;
        this.serviceObj.discount = res['response'].discount;
        this.serviceObj.isEligibleForBid = res['response'].isEligibleForBid;
        this.serviceObj.is_zeacard_accepted = res['response'].is_zeacard_accepted;
        this.serviceObj.physicians = res['response'].doctors;


      } else {
        this.snackbar.open(res['message'], 'Failed', {
          duration: 5000,
          panelClass: 'snackBar-failure'
        });
      }
    }, (err) => {
      this.snackbar.open(err.error['message'], 'Failed', {
        duration: 5000,
        panelClass: 'snackBar-failure'
      });
    });
  }
  
  compareObjects(o1: any, o2: any): boolean {
    return  o1._id === o2._id;
  }

// _id:Joi.string().alphanum().required(),
// zea_price:Joi.number().allow(null,''),
// doctors:Joi.array().items({​​​​​​​​
// doctorId:Joi.string().alphanum().allow(null,'')
//             }​​​​​​​​),
// cpt_code:Joi.string().required(),
// code_type:Joi.string().required(),
// discount:Joi.number().required(),
// can_book:Joi.boolean().required(),
// duration:Joi.string().required(),
// Bundled_Price:Joi.number().required()

  submitServiceDetails() {
    let body: any = {
      _id: this.serviceObj.serviceID,
      zea_price: this.serviceObj.zeaPrice,
      cpt_code: this.serviceObj.itemCode,
      code_type: this.serviceObj.codeType,
      discount: this.serviceObj.discount,
      can_book: this.serviceObj.canBook,
      duration: this.serviceObj.duration.toString(),
      Bundled_Price: this.serviceObj.packagePrice,
      isEligibleForBid: this.serviceObj.isEligibleForBid,
      is_zeacard_accepted: this.serviceObj.is_zeacard_accepted,
    };
    let newDocArray = [];
    this.serviceObj.physicians.forEach(item => {
      newDocArray.push({ doctorId: item._id });
    });
    body.doctors = newDocArray;
    this.spinner.showSpinner('Loading...Please Wait');
    this.httpCall.postAuth(`${environment.providerDomain}/providerApi/upateSingleItem`, body).subscribe( res => {
      this.spinner.stopSpinner();
      if(res['success']) {
        this.snackbar.open(res['message'], 'Success', {
          duration: 5000,
          panelClass: 'snackBar-success'
        });
        this.dialogRef.close(res);
      } else {
        this.snackbar.open(res['message'], 'Failed', {
          duration: 5000,
          panelClass: 'snackBar-failure'
        });
      }
    }, (err) => {
      this.spinner.stopSpinner();
      this.snackbar.open(err.error['message'], 'Failed', {
        duration: 5000,
        panelClass: 'snackBar-failure'
      });
    });
  }

}
