<div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
  <h2 class="headline m-0" fxFlex="auto"> View Physician Information</h2>
  <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-divider class="mb-4"></mat-divider>

<mat-dialog-content fxLayout="column">
  <div class="d-flex py-3">
    <div class="p-2 flex-shrink-1">
      <img src="{{ physicianObj?.avatar}}" class="rounded-circle" style="width: 100px;height: 100px;"/>
    </div>
    <div class="p-2 w-100">
      <p class="h4 trimText">Dr. {{physicianObj?.fname}} <span *ngIf="physicianObj?.middle">{{physicianObj?.middle}}</span> {{physicianObj?.lname}}, {{physicianObj?.suffix}}</p>
      <p class="h6 font-weight-bold trimText">NPI: {{physicianObj?.npi}}</p>
      <div class="rounded-pill p-2 px-4 mb-3 text-white d-inline-flex" [style.background]="proThemes?.primary?.bgColor">{{ calcExp(physicianObj?.exp) }} Yrs</div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <p class="m-0">Employeed Physician</p>
      <p class="m-0" style="font-weight: 500;">{{physicianObj?.is_hospital == 1 ? 'Yes' : 'No'}}</p>
    </div>
    <div class="col-md-4" *ngIf="physicianObj?.pacId">
      <p class="m-0">PAC ID</p>
      <p class="m-0" style="font-weight: 500;">{{physicianObj?.pacId}}</p>
    </div>
    <div class="col-md-4" *ngIf="physicianObj?.groupName">
      <p class="m-0">Group Name</p>
      <p class="m-0" style="font-weight: 500;">{{physicianObj?.groupName}}</p>
    </div>
  </div>
  <div class="mt-4" *ngIf="physicianObj?.speciality.length > 0">
    <p class="h6 font-weight-bold" [style.color]="proThemes?.primary?.bgColor">Specialities</p>
    <div class="d-flex flex-wrap">
      <div class="rounded-pill p-2 mr-2 my-1" *ngFor="let item of physicianObj?.speciality;" [style.background-color]="hexToRgb(proThemes?.primary?.bgColor)" style="font-weight: 500;">{{item?.name}}</div>
    </div>
  </div>
  <div class="mt-4" *ngIf="physicianObj?.description">
    <p class="h6 font-weight-bold" [style.color]="proThemes?.primary?.bgColor">Description</p>
    <p style="line-height: 1.6;font-size: 16px;">{{physicianObj?.description}}</p>
  </div>

  <!-- <div>
    <mat-card-content>
      <div class="d-flex py-3">
        <div class="p-2 flex-shrink-1">
          <img src="{{ physicianObj?.avatar || 'assets/img/hospital-building.svg'}}" width="100" height="100"
            class="rounded-circle" />
        </div>
        <div class="p-2 w-100">
          <p class="h6 font-weight-bold trimText">Dr. {{physicianObj?.fname}} {{physicianObj?.lname}},
            {{physicianObj?.suffix}}</p>
          <p class="h4 font-weight-bold trimText"> {{physicianObj?.major}} {{ physicianObj?.suffix }} </p>
          <div class="rounded-pill p-2 px-4 mb-3 text-white d-inline-flex"
            [style.background]="proThemes?.primary?.bgColor">{{ calcExp(physicianObj?.exp)}} Yrs</div>
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <p class="h5 font-weight-bold text-green trimText"> Specialities </p>

          <div class="d-flex flex-wrap" *ngIf="physicianObj?.speciality.length > 0">
            <span style="margin-top: 1rem !important;"
              *ngFor="let physicianObj of physicianObj?.speciality; index as i">
              <span class="rounded-pill p-1 px-2 mr-1" [style.background-color]="hexToRgb(proThemes.primary.bgColor)"
                style="font-size: 12px;">{{physicianObj?.name}}</span>
            </span>
          </div>
        </div>
        <div class="col-9">
          <p class="h5 font-weight-bold text-green trimText"> Description </p>
        </div>
      </div>
    </mat-card-content>
  </div> -->
</mat-dialog-content>