import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import * as crypto from 'crypto-js';
import { environment } from 'src/environments/environment.prod';
import { HippaService } from './hippa.service';

@Injectable({
  providedIn: 'root'
})

export class HttpCallService {
  
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  constructor(
    private http: HttpClient,
    private router: Router,
    private hippa: HippaService
  ) {
    this.currentUserSubject = new BehaviorSubject<any>(localStorage.getItem('userAccess'));
    this.currentUser = this.currentUserSubject.asObservable();
  }
  
  public setCurrentUserAccess(value): any {
    this.currentUserSubject.next(value);
  }
  public get currentUserAccess(): any {
    if(this.currentUserSubject.value) {
      let accessObj = JSON.parse(this.decrypt(this.currentUserSubject.value));
      return accessObj;
    }
  }

  getProfile(userType: String, domain: String): Observable<any> {
    let headers: any;
    if(userType == "Provider") {
      headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'authorization': localStorage.getItem('token')

      });
    } else {
      headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
    }
    let options = {
      'headers': headers
    };
    return this.http.get(`${domain}/${userType}/profile`, options)
    .pipe(map(res => {
      localStorage.setItem('userAccess', res['access']);
      this.currentUserSubject.next(res['access']);
      return res;
    }));
    // return this.http.get(`${domain}/${userType}/profile`, options);
  }

  logOut() {
    localStorage.clear();
    this.currentUserSubject.next(null);
    this.router.navigate(['/']);
  }

  encrypt = data => {
    if(data != null) {
      const key = environment.SECRET;
      const keyutf = crypto.enc.Utf8.parse(key);
      const iv = crypto.enc.Base64.parse(key);
      const enc = crypto.AES.encrypt(data, keyutf, { iv: iv });
      return enc.toString();
    }
  }

  decrypt = data => {
    if(data != null) {
      const key = environment.SECRET;
      const keyutf = crypto.enc.Utf8.parse(key);
      const iv = crypto.enc.Base64.parse(key);
      const dec = crypto.AES.decrypt(data,keyutf,{iv: iv});
      const decStr = crypto.enc.Utf8.stringify(dec);
      return decStr;
    }
  }

  getProName() {
    return localStorage.getItem('proName');
  }

  getProLocation() {
    return localStorage.getItem("proLocation");
  }
  
  get(url) {
    return this.http.get(url);
  }

  post(url, body) {
    return this.http.post(url, body);
  }

  put(url, body) {
    return this.http.put(url, body);
  }

  getAuth(url) {
    let token = localStorage.getItem('token');
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'authorization': token
    });
    let options = {
      headers: headers
    };
    return this.http.get(url, options).pipe(map(res => {
      if(res['sessionexp']) {
        localStorage.clear();
        this.router.navigate(['/proLogin']);
      } else {
        return res;
      }
    }));
  }

  postAuth(url, body) {
    let token = localStorage.getItem('token');
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'authorization': token
    });
    let options = {
      headers: headers
    };
    return this.http.post(url, body, options).pipe(map(res => {
      if(res['sessionexp']) {
        localStorage.clear();
        this.router.navigate(['/proLogin']);
      } else {
        return res;
      }
    }));
  }

  putAuth(url, body) {
    let token = localStorage.getItem('token');
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'authorization': token
    });
    let options = {
      headers: headers
    };
    return this.http.put(url, body, options).pipe(map(res => {
      if(res['sessionexp']) {
        localStorage.clear();
        this.router.navigate(['/proLogin']);
      } else {
        return res;
      }
    }));
  }
  
  access_token() {
    /*
    old but secure method of accessing token
    const token = JSON.parse(this.hippa.decrypt(localStorage.getItem('session')));
    return token.token;
    */
   return localStorage.getItem('token');
  }

  refreshService(){
    let token = localStorage.getItem('refreshToken');
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'authorization': token
    });
    let options = {
      headers: headers
    };
    return this.http.get(`${environment.basicDomain}/Provider/refresh`,options);

  }

  export(url) {
    let token = this.access_token();
    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        'authorization': token
      })
    };
    return this.http.get(url, httpOptions);
  }
  
  // export(url) {
  //   let token;
  //   if(this.isPrimaryContact() || this.isPrimaryContactViewer() || this.isDepartmentViewer() || this.isDepartmentDirector()){
  //     token = localStorage.getItem('token')
  //   }else{
  //     token = this.access_token();
  //   }
  //   const httpOptions = {
  //     responseType: 'blob' as 'json',
  //     headers: new HttpHeaders({
  //       'authorization': token,
  //     })
  //   };
  //   return this.http.get(url, httpOptions);
  // }

}

// Working Old Code 15th Oct 2020 ------------------------
// export class HttpCallService {
  
//   constructor(
//     private http: HttpClient, 
//     private router: Router, 
//     // private notification: NotificationService,
//     // private hippa: HippaService
//   ) { }

//   getProfile(userType: String, domain: String): Observable<any> {
//     let headers:any//
//     if(userType == "Provider") {
//       headers = new HttpHeaders({
//         'Content-Type': 'application/json',
//         'authorization': localStorage.getItem('token')
//       });
//     } else {
//       headers = new HttpHeaders({
//         'Content-Type': 'application/json',
//         // 'authorization': this.access_token()
//       });
//     }
//     let options = {
//       headers: headers
//     }
//     return this.http.get(`${domain}/${userType}/profile`, options);
//   }

//   logOut() {
//     localStorage.clear();
//     this.router.navigate(['/']);
//   }

//   getProName() {
//     return localStorage.getItem('proName');
//   }
  
//   get(url) {
//     return this.http.get(url);
//   }

//   post(url, body) {
//     return this.http.post(url, body);
//   }

//   put(url, body) {
//     return this.http.put(url, body);
//   }

//   getAuth(url) {
//     let token = localStorage.getItem('token');
//     let headers = new HttpHeaders({
//       'Content-Type': 'application/json',
//       'authorization': token
//     });
//     let options = {
//       headers: headers
//     };
//     return this.http.get(url, options).pipe(map(res => {
//       if(res['sessionexp']) {
//         localStorage.clear();
//         this.router.navigate(['/consumer/userLogin']);
//       } else {
//         return res;
//       }
//     }));
//   }

//   postAuth(url, body) {
//     let token = localStorage.getItem('token');
//     let headers = new HttpHeaders({
//       'Content-Type': 'application/json',
//       'authorization': token
//     });
//     let options = {
//       headers: headers
//     };
//     return this.http.post(url, body, options).pipe(map(res => {
//       if(res['sessionexp']) {
//         localStorage.clear();
//         this.router.navigate(['/consumer/userLogin']);
//       } else {
//         return res;
//       }
//     }));
//   }

//   putAuth(url, body) {
//     let token = localStorage.getItem('token');
//     let headers = new HttpHeaders({
//       'Content-Type': 'application/json',
//       'authorization': token
//     });
//     let options = {
//       headers: headers
//     };
//     return this.http.put(url, body, options).pipe(map(res => {
//       if(res['sessionexp']) {
//         localStorage.clear();
//         this.router.navigate(['/consumer/userLogin']);
//       } else {
//         return res;
//       }
//     }));
//   }
  
//   // export(url) {
//   //   let token;
//   //   if(this.isPrimaryContact() || this.isPrimaryContactViewer() || this.isDepartmentViewer() || this.isDepartmentDirector()){
//   //     token = localStorage.getItem('token')
//   //   }else{
//   //     token = this.access_token();
//   //   }
//   //   const httpOptions = {
//   //     responseType: 'blob' as 'json',
//   //     headers: new HttpHeaders({
//   //       'authorization': token,
//   //     })
//   //   };
//   //   return this.http.get(url, httpOptions);
//   // }

// }