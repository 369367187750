<form #addForm="ngForm" (ngSubmit)="submitPrimaryContactDetails(pcObj)">
    <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
        <h2 class="headline m-0" fxFlex="auto">{{ data.isNew ? "Add" : "Update"}} primary contact</h2>
        <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <mat-divider class="mb-4"></mat-divider>

    <mat-dialog-content fxLayout="column">
        <div class="w-100">
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>First name</mat-label>
                        <input matInput name="firstName" ngModel #firstName="ngModel" type="text" [(ngModel)]="pcObj.f_name" required>
                        <mat-error *ngIf="firstName.touched && firstName.invalid">
                            <mat-error *ngIf="firstName.errors.required">First name is required</mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>Last name</mat-label>
                        <input matInput name="lastName" ngModel #lastName="ngModel" type="text" [(ngModel)]="pcObj.l_name" required>
                        <mat-error *ngIf="lastName.touched && lastName.invalid">
                            <mat-error *ngIf="lastName.errors.required">Last name is required</mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <p>
                <mat-form-field appearance="fill">
                    <mat-label>Email</mat-label>
                    <input matInput name="email" ngModel #email="ngModel" name="email" type="email" [(ngModel)]="pcObj.emp_email" pattern="^[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,125}[a-zA-Z]{2,63}$" required>
                    <mat-error *ngIf="email.touched && email.invalid">
                        <mat-error *ngIf="email.errors.required">Email is required</mat-error>
                        <mat-error *ngIf="email.errors && email.errors.pattern">Invalid email</mat-error>
                    </mat-error>
                </mat-form-field>
            </p>
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>Country code</mat-label>
                        <mat-select name="code" ngModel #code="ngModel" [(ngModel)]="pcObj.code" required>
                            <mat-option value="+1">+1</mat-option>
                            <mat-option value="+91">+91</mat-option>
                            </mat-select>
                        <mat-error *ngIf="code.touched && code.invalid">
                            <mat-error *ngIf="code.errors.required">Country code is required</mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>Mobile number</mat-label>
                        <input matInput name="mobile" ngModel #mobile="ngModel" type="text" [(ngModel)]="pcObj.emp_phone" pattern="^\+?\d{10}$" minlength="10" maxlength="10" required>
                        <mat-error *ngIf="mobile.touched && mobile.invalid">
                            <mat-error *ngIf="mobile.errors.required">Mobile number is required</mat-error>
                            <mat-error *ngIf="mobile.errors.minlength || mobile.errors.pattern">Invaid Mobile number</mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <p>
                <mat-form-field appearance="fill">
                    <mat-label>Role</mat-label>
                    <mat-select ngModel name="role" #role="ngModel" [(ngModel)]="pcObj.role_id" required>
                        <mat-option *ngFor="let role of providerRolesList" [value]="role._id">{{ role.name }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="role.touched && role.invalid">
                        <mat-error *ngIf="role.errors.required">Role is required</mat-error>
                    </mat-error>
                </mat-form-field>
            </p>
        </div>

    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button [disabled]="addForm.form.invalid" color="primary" mat-button type="submit">Save</button>
        <button mat-button mat-dialog-close type="button">Cancel</button>
    </mat-dialog-actions>
</form>