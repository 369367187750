

<mat-toolbar class="customNavbar staticNav">
    <mat-toolbar-row>
        <!-- <span><img id="navLogo" src="{{ providerDetails?.providerInfo?.pro_profile || 'assets/img/zea-tool-logo.png' }}" [width]="providerDetails?.providerInfo?.pro_profile ? '100' : '150'"/></span> -->
        <span><img id="navLogo" src="{{ providerDetails?.providerInfo?.pro_profile || 'assets/img/zea-tool-logo.png' }}" [height]="providerDetails?.providerInfo?.pro_profile ? '80' : '80'"/></span>
        <span class="nvSpacer"></span>
    </mat-toolbar-row>
</mat-toolbar>

<section class="bg-white mt-2 sticky-top">
    <div class="p-2">
        <div class="d-flex align-items-center w-100">
            <button *ngIf="hasHistory" class="flex-shrink-1 align-self-start" mat-button type="button" (click)="backToPreviousPage()">
                <mat-icon>arrow_back</mat-icon>
                <span class="mr-2">Go Back</span>
            </button>
            <div class="row align-items-center w-100">
                <div class="col-md-3 d-flex flex-md-row justify-content-md-center col-lg-1 col-2">
                    <img id="serviceLogo" class="mb-2" [src]="serviceImagePath" (error)="serviceImagePath = 'assets/img/service-default.png'"/>
                </div>
                <div class="col-md-9 col-lg-9 col-10">
                    <div class="h5 font-weight-bold" [style.color]="proThemes?.primary?.bgColor">{{serviceItemObj?.test_name}}</div>
                </div>
                <div class="col-md-3 col-lg-2">
                    <div class="h5 font-weight-bold" [style.color]="proThemes?.primary?.bgColor">{{codeTypes[serviceItemObj?.code_type]?.name}}-{{serviceItemObj?.cpt_code}}</div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="container-fluid">
    <div class="row">
        <div class="col-md-12" *ngIf="pRes">
            <mat-card class="mt-4 mb-1 mat-elevation-z2">
                <mat-card-content>
                    <ng-container *ngIf="testPricesList.length > 0; else noTestPrices">
                        <div>
                            <table id="headTable" class="w-100">
                                <tr>
                                  <th [style.background-color]="hexToRgbHead(proThemes?.primary?.bgColor)">Type</th>
                                  <th [style.background-color]="hexToRgbHead(proThemes?.primary?.bgColor)">Gross Price</th>
                                  <th [style.background-color]="hexToRgbHead(proThemes?.primary?.bgColor)">Cash Price</th>
                                  <th [style.background-color]="hexToRgbHead(proThemes?.primary?.bgColor)">Min Price</th>
                                  <th [style.background-color]="hexToRgbHead(proThemes?.primary?.bgColor)">Max Price</th>
                                </tr>
                                <tr>
                                  <td [style.background-color]="hexToRgbBody(proThemes?.primary?.bgColor)">IP Price</td>
                                  <td>{{serviceItemObj?.std_price_ip ? (serviceItemObj?.std_price_ip | currency:'USD') : 'N/A'}}</td>
                                  <td>{{serviceItemObj?.cash_price_ip ? (serviceItemObj?.cash_price_ip | currency:'USD') : 'N/A'}}</td>
                                  <td>{{serviceItemObj?.min_price_ip ? (serviceItemObj?.min_price_ip | currency:'USD') : 'N/A'}}</td>
                                  <td>{{serviceItemObj?.max_price_ip ? (serviceItemObj?.max_price_ip | currency:'USD') : 'N/A'}}</td>
                                </tr>
                                <tr>
                                  <td [style.background-color]="hexToRgbBody(proThemes?.primary?.bgColor)">OP Price</td>
                                  <td>{{serviceItemObj?.std_price_op ? (serviceItemObj?.std_price_op | currency:'USD') : 'N/A'}}</td>
                                  <td>{{serviceItemObj?.cash_price_op ? (serviceItemObj?.cash_price_op | currency:'USD') : 'N/A'}}</td>
                                  <td>{{serviceItemObj?.min_price_op ? (serviceItemObj?.min_price_op | currency:'USD') : 'N/A'}}</td>
                                  <td>{{serviceItemObj?.max_price_op ? (serviceItemObj?.max_price_op | currency:'USD') : 'N/A'}}</td>
                                </tr>
                            </table>
                        </div>
                    </ng-container>
                    <ng-template #noTestPrices>
                        <div class="d-flex flex-column justify-content-center align-items-center w-100">
                            <img class="" src="assets/img/Prices-not-Available.svg" alt="">
                            <p class="h3 mt-4">No Prices Found</p>
                        </div>
                    </ng-template>
                </mat-card-content>
            </mat-card>
        </div>
        <ng-container *ngIf="pRes">
            <ng-container *ngIf="insurancePricesList != null; else noInsurancePrices">
                <div class="col-md-12">
                    <div class="w-100">
                        <p class="h5 font-weight-bold mt-3" [style.color]="proThemes?.primary.bgColor ? proThemes?.primary.bgColor : '#20b04b'">Insurance Prices</p>
                    </div>
                </div>
                <div class="col-md-5 pr-1">
                    <mat-card class="my-1 mat-elevation-z2">
                        <mat-card-content>
                            <ng-container >
                                <div>
                                    <p-table #dt styleClass="p-datatable-gridlines" [value]="insurancePricesList"
                                        [paginator]="true" [rows]="10" paginatorPosition="both" [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
                                        <ng-template pTemplate="caption">
                                            <div *ngIf="toggler" class="d-flex flex-row justify-content-end">
                                                <p-multiSelect [options]="filterColumns" [(ngModel)]="selectedColumns" optionLabel="carrier_name"
                                                    selectedItemsLabel="{0} Column(s) Selected" [style]="{minWidth: '200px', maxWidth: '300px'}" defaultLabel="Choose columns">
                                                </p-multiSelect>
                                            </div>
                                            <div *ngIf="!toggler" class="d-flex flex-row justify-content-end">
                                                <p-multiSelect [options]="filterColumns" [(ngModel)]="selectedColumns" optionLabel="carrier_name"
                                                    selectedItemsLabel="{0} Column(s) Selected" [style]="{minWidth: '200px', maxWidth: '300px'}" defaultLabel="Choose columns">
                                                </p-multiSelect>
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th [style.background-color]="hexToRgbHead(proThemes?.primary?.bgColor)">Insurance Name</th>
                                                <th style="width: 120px;" [style.background-color]="hexToRgbHead(proThemes?.primary?.bgColor)">IP Price</th>
                                                <th style="width: 120px;" [style.background-color]="hexToRgbHead(proThemes?.primary?.bgColor)">OP Price</th>
                                                <th *ngIf="isLoggedIn == true" style="width: 80px;" [style.background-color]="hexToRgbHead(proThemes?.primary?.bgColor)">Options</th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-element>
                                            <tr>
                                                <td [style.background-color]="hexToRgbBody(proThemes?.primary?.bgColor)">{{element?.carrier_name}}</td>
                                                <td>{{ element?.ip_price ? (element?.ip_price | currency:'USD') : 'N/A' }}</td>
                                                <td>{{ element?.op_price ? (element?.op_price | currency:'USD') : 'N/A' }}</td>
                                                <td *ngIf="isLoggedIn == true">
                                                    <button [hidden]="!element.carrier_id" mat-icon-button [matMenuTriggerFor]="menu" class="text-muted">
                                                        <mat-icon>more_vert</mat-icon>
                                                    </button>
                                                    <mat-menu #menu="matMenu">
                                                        <button mat-menu-item (click)="showEditNote()">Edit</button>
                                                    </mat-menu>
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                </div>
                            </ng-container>
                        </mat-card-content>
                    </mat-card>
                </div>
                <div class="col-md-7 pl-1">
                    <ng-container>
                        <mat-card class="mat-elevation-z2 my-1 h-100 d-flex flex-column justify-content-center">
                            <mat-card-content>
                                <div echarts [options]="chartOption"></div>
                            </mat-card-content>
                        </mat-card>
                    </ng-container>
                </div>
            </ng-container>
            <ng-template #noInsurancePrices>
                <div class="d-flex flex-column justify-content-center align-items-center w-100">
                    <img class="" src="assets/img/Insurance-Price-not-available.svg" alt="">
                    <p class="h3 mt-4">Insurance Prices Not Found</p>
                </div>
            </ng-template>
        </ng-container>
    </div>
</div>

