<form #updatePriceForm="ngForm" (ngSubmit)="sumbitInsurancePrices()">
    <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
        <h2 class="headline m-0" fxFlex="auto">Update Insurance Prices</h2>
        <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <mat-divider class="mb-4"></mat-divider>

    <mat-dialog-content fxLayout="column">
        <div class="mb-2">
            <p class="h6">{{data?.selectedItem?.carrier_name}}</p>
        </div>
        <p>
            <mat-form-field appearance="fill">
                <mat-label>IP price</mat-label>
                <input matInput name="ip_price" ngModel #ip_price="ngModel" type="number" [(ngModel)]="insuranceObj.ip_price">
                <mat-error *ngIf="ip_price.touched && ip_price.invalid">
                    <mat-error *ngIf="ip_price.errors.required">IP price is required</mat-error>
                </mat-error>
            </mat-form-field>
        </p>
        <p>
            <mat-form-field appearance="fill">
                <mat-label>OP price</mat-label>
                <input matInput name="op_price" ngModel #op_price="ngModel" type="number" [(ngModel)]="insuranceObj.op_price">
                <mat-error *ngIf="op_price.touched && op_price.invalid">
                    <mat-error *ngIf="op_price.errors.required">OP price is required</mat-error>
                </mat-error>
            </mat-form-field>
        </p>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button [disabled]="updatePriceForm.form.invalid" color="primary" mat-button type="submit">Save</button>
        <button mat-button mat-dialog-close type="button">Cancel</button>
    </mat-dialog-actions>
</form>