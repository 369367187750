<form #deleteForm="ngForm" (ngSubmit)="confirmDeleteRole()">
    <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
        <h2 class="headline m-0" fxFlex="auto">Delete Role</h2>
        <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <mat-divider class="mb-4"></mat-divider>

    <mat-dialog-content fxLayout="column">
        <p class="h6">{{data?.roleObj.name}} assigned to {{data?.assignedCount}} employees. Please select another role for them.</p>
        <p>
            <mat-form-field appearance="fill">
                <mat-label>To which role you want to migrate</mat-label>
                <mat-select ngModel name="migrateRole" #migrateRole="ngModel" [(ngModel)]="migrateRoleID" required>
                    <mat-option *ngFor="let role of providerRolesList" [value]="role._id">{{ role.name }}</mat-option>
                </mat-select>
                <mat-error *ngIf="migrateRole.touched && migrateRole.invalid">
                    <mat-error *ngIf="migrateRole.errors.required">Field is required</mat-error>
                </mat-error>
            </mat-form-field>
        </p>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button [disabled]="deleteForm.form.invalid" color="primary" mat-button type="submit">Save</button>
        <button mat-button mat-dialog-close type="button">Cancel</button>
    </mat-dialog-actions>
</form>