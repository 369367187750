
<app-toolbar></app-toolbar>

<section class="container py-5">
    <mat-accordion>
        <mat-expansion-panel *ngFor="let item of faqList">
            <mat-expansion-panel-header>
                <mat-panel-title class="fw-5">{{item?.title}}</mat-panel-title>
            </mat-expansion-panel-header>
            <p>{{item?.content}}</p>
        </mat-expansion-panel>
    </mat-accordion>
</section>