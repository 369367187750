<mat-form-field class="mr-3" appearance="fill">
    <mat-label>From</mat-label>
    <mat-select placeholder="Select a start time" [(ngModel)]="selectedStartTime" (selectionChange)="onTimeChange()" [disabled]="isDisabled">
        <mat-option *ngFor="let time of times" [value]="time">
            {{time}}
        </mat-option>
    </mat-select>
</mat-form-field>

<mat-form-field appearance="fill">
    <mat-label>To</mat-label>
    <mat-select placeholder="Select an end time" [(ngModel)]="selectedEndTime" (selectionChange)="onTimeChange()" [disabled]="isDisabled">
        <mat-option *ngFor="let time of times" [value]="time">
            {{time}}
        </mat-option>
    </mat-select>
</mat-form-field>
