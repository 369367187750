<div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
    <h2 class="headline m-0" fxFlex="auto">Select Image</h2>
    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
        <mat-icon>close</mat-icon>
    </button>
</div>

<mat-divider class="mb-4"></mat-divider>

<mat-dialog-content class="text-center">
    <div class="row mt-4">
        <div class="text-center col-md-8">
            <p class="h6">Crop Image</p>
            <!-- [aspectRatio]="4 / 2" -->
            <image-cropper 
                [imageChangedEvent]="imageChangedEvent" 
                [maintainAspectRatio]="false" 
                format="png" 
                (imageCropped)="imageCropped($event)" 
                (imageLoaded)="imageLoaded()"
                (cropperReady)="cropperReady()" 
                (loadImageFailed)="loadImageFailed()">
        </image-cropper>
            <!-- <image-cropper 
            [imageChangedEvent]="imageChangedEvent" 
            [maintainAspectRatio]="true" 
            [aspectRatio]="4 / 4"
            [resizeToWidth]="256" 
            format="png" 
            (imageCropped)="imageCropped($event)" 
            (imageLoaded)="imageLoaded()"
            (cropperReady)="cropperReady()" 
            (loadImageFailed)="loadImageFailed()"></image-cropper> -->
        </div>
        <div class="text-center col-md-4">
            <p class="h6">Preview</p>
            <img [src]="croppedImage" />
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="center">
    <button mat-raised-button color="primary" (click)="previewImageSelected()">Submit</button>
</mat-dialog-actions>