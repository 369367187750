<!-- <ngx-spinner [fullScreen]="false" type="ball-clip-rotate" size="medium"></ngx-spinner> -->

<form #addServiceForm="ngForm" (ngSubmit)="submitServiceDetails()">
    <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
        <p class="h3 m-0" fxFlex="auto">{{ data.isNew ? "Add" : "Update" }} service</p>
        <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <mat-divider class="mb-4"></mat-divider>

    <mat-dialog-content fxLayout="column">

        <div class="row">
            <div class="col-md-6">
                <mat-form-field appearance="fill">
                    <mat-label>Code type</mat-label>
                    <mat-select name="codeType" ngModel #codeType="ngModel" [(ngModel)]="serviceObj.codeType" required>
                        <mat-option *ngFor="let item of codeTypes" [value]="item?.value">{{ item?.name }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="codeType.touched && codeType.invalid">
                        <mat-error *ngIf="codeType.errors.required">Code type is required</mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="fill">
                    <mat-label>Search by</mat-label>
                    <mat-select name="searchBy" ngModel [(ngModel)]="searchBy">
                        <mat-option value="1">Search by code</mat-option>
                        <mat-option value="2">Search by text</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <p>
            <mat-form-field appearance="fill">
                <mat-label> {{ data?.isChild ? 'Item/Service Name' : 'CMS Shoppable Service Name'}}</mat-label>
                <input matInput name="serviceName" [(ngModel)]="serviceObj.serviceName" (keyup)="getServicesLookup($event.target.value, serviceObj.codeType)" [formControl]="serviceNameControl" [matAutocomplete]="auto" type="text" autocomplete="off" required [readonly]="!data.isNew">
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let item of servicesList" [value]="item.test_name" (click)="selectService(item)">
                    {{ item.test_name }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </p>
        <p>
            <mat-form-field appearance="fill">
                <mat-label>Item/Service Code</mat-label>
                <input matInput name="itemCode" ngModel #itemCode="ngModel" type="text" [(ngModel)]="serviceObj.itemCode" required [readonly]="!data.isNew">
                <mat-error *ngIf="itemCode.touched && itemCode.invalid">
                    <mat-error *ngIf="itemCode.errors.required">Item code is required</mat-error>
                </mat-error>
            </mat-form-field>
        </p>
        <p [hidden]="data.isChild">
            <mat-form-field appearance="fill">
                <mat-label>Hospital Shoppable Name</mat-label>
                <input matInput name="shortDesc" ngModel #shortDesc="ngModel" name="shortDesc" type="text" [(ngModel)]="serviceObj.shortDesc">
                <mat-error *ngIf="shortDesc.touched && shortDesc.invalid">
                    <mat-error *ngIf="shortDesc.errors.required">Shoppable Service Name is required</mat-error>
                </mat-error>
            </mat-form-field>
        </p>
        <p>
            <mat-form-field appearance="fill">
                <mat-label>Description</mat-label>
                <textarea matInput rows="4" name="description" ngModel #description="ngModel" name="description" type="text" [(ngModel)]="serviceObj.description"></textarea>
                <mat-error *ngIf="description.touched && description.invalid">
                    <mat-error *ngIf="description.errors.required">Description is required</mat-error>
                </mat-error>
            </mat-form-field>
        </p>
        <!-- <p>
            <mat-form-field appearance="fill">
                <mat-label>Billing ID</mat-label>
                <input matInput rows="4" name="billingID" ngModel #billingID="ngModel" name="billingID" type="text" [(ngModel)]="serviceObj.billingID" required>
                <mat-error *ngIf="billingID.touched && billingID.invalid">
                    <mat-error *ngIf="billingID.errors.required">Billing ID is required</mat-error>
                </mat-error>
            </mat-form-field>
        </p> -->
        <p [hidden]="!data.isChild">
            <!-- <mat-form-field appearance="fill">
                <mat-label>Service item type</mat-label>
                <input matInput name="serviceItemType" [(ngModel)]="serviceObj.serviceItemType" [formControl]="serviceItemTypeControl" (click)="$event.target.select()" [matAutocomplete]="autoServiceItemType" type="text" autocomplete="off" [required]="data.isChild">
                <mat-autocomplete #autoServiceItemType="matAutocomplete">
                    <mat-option *ngFor="let item of filteredServiceItemTypesOptions | async" [value]="item?.item_type">
                    {{ item?.item_type }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field> -->
            <mat-form-field appearance="fill">
                <mat-label>Service item type</mat-label>
                <mat-select ngModel name="serviceItemType" #serviceItemType="ngModel" [(ngModel)]="serviceObj.serviceItemType" [required]="data.isChild">
                    <mat-option *ngFor="let item of serviceItemTypes" [value]="item?.item_type">{{ item?.item_type }}</mat-option>
                </mat-select>
                <mat-error *ngIf="serviceItemType.touched && serviceItemType.invalid">
                    <mat-error *ngIf="serviceItemType.errors.required">Service item type is required</mat-error>
                </mat-error>
            </mat-form-field>
        </p>
        <p [hidden]="data.isChild">
            <mat-form-field appearance="fill">
                <mat-label>Category type</mat-label>
                <mat-select name="categoryType" ngModel #categoryType="ngModel" [(ngModel)]="serviceObj.categoryType" [required]="!data.isChild">
                    <mat-option *ngFor="let item of mandatoryTestCategories" [value]="item?.masterID">{{ item?.name }}</mat-option>
                </mat-select>
                <mat-error *ngIf="categoryType.touched && categoryType.invalid">
                    <mat-error *ngIf="categoryType.errors.required">Category type is required</mat-error>
                </mat-error>
            </mat-form-field>
        </p>
        <p *ngIf="serviceObj.codeType == 5">
            <mat-form-field appearance="fill">
                <mat-label>Quantity</mat-label>
                <input matInput name="quantity" ngModel #quantity="ngModel" type="text" [(ngModel)]="serviceObj.quantity" [required]="serviceObj.codeType == 5">
                <mat-error *ngIf="quantity.touched && quantity.invalid">
                    <mat-error *ngIf="quantity.errors.required">Quantity is required</mat-error>
                </mat-error>
            </mat-form-field>
        </p>
        <div class="row">
            <div class="col-md-6 mb-4">
                <mat-slide-toggle color="primary" name="isBundled" #isBundled="ngModel" ngModel [(ngModel)]="serviceObj.isBundled">Packaged service</mat-slide-toggle>
            </div>
            <div class="col-md-6 mb-4" [hidden]="data.isChild">
                <mat-slide-toggle color="primary" name="isShoppable" #isShoppable="ngModel" ngModel [(ngModel)]="serviceObj.isShoppable" >Shoppable service</mat-slide-toggle>
            </div>
            <div class="col-md-6 mb-4" [hidden]="!data.isChild">
                <mat-slide-toggle color="primary" name="isServicable" #isServicable="ngModel" (change)="toggleShopable($event)" ngModel [(ngModel)]="serviceObj.isServicable">Offered service</mat-slide-toggle>
            </div>
        </div>

        <div *ngIf="(data.isChild && serviceObj.isServicable) || (!data.isChild && serviceObj.isShoppable)">
            
            <div class="!data.isNew" class="p-2 my-2 bg-custom-primary-light" style="font-size: 12px;">
                <span>Note: </span>
                <span>Prices cannot be edited here. </span>
                <span>To update prices go to charge master and update.</span>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <mat-form-field appearance="fill">
                        <mat-label>Standard price-IP</mat-label>
                        <input matInput name="standardPriceIP" ngModel #standardPriceIP="ngModel" type="number" [(ngModel)]="serviceObj.standardPriceIP" [readonly]="!data.isNew">
                        <mat-error *ngIf="standardPriceIP.touched && standardPriceIP.invalid">
                            <mat-error *ngIf="standardPriceIP.errors.required">Standard price-IP is required</mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field appearance="fill">
                        <mat-label>Standard price-OP</mat-label>
                        <input matInput name="standardPriceOP" ngModel #standardPriceOP="ngModel" type="number" [(ngModel)]="serviceObj.standardPriceOP" [readonly]="!data.isNew">
                        <mat-error *ngIf="standardPriceOP.touched && standardPriceOP.invalid">
                            <mat-error *ngIf="standardPriceOP.errors.required">Standard price-OP is required</mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field appearance="fill">
                        <mat-label>Cash price-IP</mat-label>
                        <input matInput name="cashPriceIP" ngModel #cashPriceIP="ngModel" type="number" [(ngModel)]="serviceObj.cashPriceIP" [readonly]="!data.isNew">
                        <mat-error *ngIf="cashPriceIP.touched && cashPriceIP.invalid">
                            <mat-error *ngIf="cashPriceIP.errors.required">Cash price-IP is required</mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field appearance="fill">
                        <mat-label>Cash price-OP</mat-label>
                        <input matInput name="cashPriceOP" ngModel #cashPriceOP="ngModel" type="number" [(ngModel)]="serviceObj.cashPriceOP" [readonly]="!data.isNew">
                        <mat-error *ngIf="cashPriceOP.touched && cashPriceOP.invalid">
                            <mat-error *ngIf="cashPriceOP.errors.required">Cash price-OP is required</mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field appearance="fill">
                        <mat-label>Out of Network price-IP</mat-label>
                        <input matInput name="outNetworkPriceIP" ngModel #outNetworkPriceIP="ngModel" type="number" [(ngModel)]="serviceObj.outNetworkPriceIP" [readonly]="!data.isNew">
                        <mat-error *ngIf="outNetworkPriceIP.touched && outNetworkPriceIP.invalid">
                            <mat-error *ngIf="outNetworkPriceIP.errors.required">Out of Network price-IP is required</mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field appearance="fill">
                        <mat-label>Out of Network price-OP</mat-label>
                        <input matInput name="outNetworkPriceOP" ngModel #outNetworkPriceOP="ngModel" type="number" [(ngModel)]="serviceObj.outNetworkPriceOP" [readonly]="!data.isNew">
                        <mat-error *ngIf="outNetworkPriceOP.touched && outNetworkPriceOP.invalid">
                            <mat-error *ngIf="outNetworkPriceOP.errors.required">Out of Network price-OP is required</mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <!-- <div class="row">
                <div class="col-md-6">
                    <mat-form-field appearance="fill">
                        <mat-label>Min price-IP</mat-label>
                        <input matInput name="minPriceIP" ngModel #minPriceIP="ngModel" type="number" [(ngModel)]="serviceObj.minPriceIP" [readonly]="!data.isNew">
                        <mat-error *ngIf="minPriceIP.touched && minPriceIP.invalid">
                            <mat-error *ngIf="minPriceIP.errors.required">Min price-IP is required</mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field appearance="fill">
                        <mat-label>Min price-OP</mat-label>
                        <input matInput name="minPriceOP" ngModel #minPriceOP="ngModel" type="number" [(ngModel)]="serviceObj.minPriceOP" [readonly]="!data.isNew">
                        <mat-error *ngIf="minPriceOP.touched && minPriceOP.invalid">
                            <mat-error *ngIf="minPriceOP.errors.required">Min price-OP is required</mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field appearance="fill">
                        <mat-label>Max price-IP</mat-label>
                        <input matInput name="maxPriceIP" ngModel #maxPriceIP="ngModel" type="number" [(ngModel)]="serviceObj.maxPriceIP" [readonly]="!data.isNew">
                        <mat-error *ngIf="maxPriceIP.touched && maxPriceIP.invalid">
                            <mat-error *ngIf="maxPriceIP.errors.required">Max price-IP is required</mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field appearance="fill">
                        <mat-label>Max price-OP</mat-label>
                        <input matInput name="maxPriceOP" ngModel #maxPriceOP="ngModel" type="number" [(ngModel)]="serviceObj.maxPriceOP" [readonly]="!data.isNew">
                        <mat-error *ngIf="maxPriceOP.touched && maxPriceOP.invalid">
                            <mat-error *ngIf="maxPriceOP.errors.required">Max price-OP is required</mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div> -->

            <ng-container *ngIf="data.isNew">
                <div *ngFor="let item of proInsuranceList; index as i">
                    <div class="row">
                        <div class="col-md-6">
                            <mat-form-field appearance="fill">
                                <mat-label>{{item?.carrier_name}}-IP</mat-label>
                                <input matInput name="{{item?.carrier_id}}ip" type="number" [(ngModel)]="proInsuranceList[i].ip_price" [readonly]="!data.isNew">
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="fill">
                                <mat-label>{{item?.carrier_name}}-OP</mat-label>
                                <input matInput name="{{item?.carrier_id}}op" type="number" [(ngModel)]="proInsuranceList[i].op_price" [readonly]="!data.isNew">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </ng-container>
            
            <!-- <div [hidden]="data.isChild">
                <p>
                    <mat-form-field appearance="fill">
                        <mat-label>This Service Includes</mat-label>
                        <textarea rows="4" matInput name="inclusive" ngModel #inclusive="ngModel" type="text" [(ngModel)]="serviceObj.inclusive"></textarea>
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="fill">
                        <mat-label>This Service Not-Includes</mat-label>
                        <textarea rows="4" matInput name="exclusive" ngModel #exclusive="ngModel" type="text" [(ngModel)]="serviceObj.exclusive"></textarea>
                    </mat-form-field>
                </p>
            </div> -->

            <p *ngIf="!data.isNew && data.isChild">
                <mat-form-field appearance="fill">
                    <mat-label>Physicians</mat-label>
                    <mat-select ngModel name="physicians" #physicians="ngModel" multiple [(ngModel)]="serviceObj.physicians">
                        <mat-option *ngFor="let item of physiciansList" [value]="item._id">Dr. {{item.firstName}} {{item.lastName}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </p>

            <div [hidden]="data.isChild" class="row">
                <div class="col-md-6">
                    <mat-form-field appearance="fill">
                        <mat-label>Facility Fee</mat-label>
                        <mat-select name="facilityFee" ngModel #facilityFee="ngModel" [(ngModel)]="serviceObj.facilityFee">
                            <mat-option *ngFor="let item of feeTypes" [value]="item.value">{{ item?.name }}</mat-option>
                            </mat-select>
                        <mat-error *ngIf="facilityFee.touched && facilityFee.invalid">
                            <mat-error *ngIf="facilityFee.errors.required">Facility Fee is required</mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field appearance="fill">
                        <mat-label>Drug Fee</mat-label>
                        <mat-select name="drugFee" ngModel #drugFee="ngModel" [(ngModel)]="serviceObj.drugFee">
                            <mat-option *ngFor="let item of feeTypes" [value]="item.value">{{ item?.name }}</mat-option>
                            </mat-select>
                        <mat-error *ngIf="drugFee.touched && drugFee.invalid">
                            <mat-error *ngIf="drugFee.errors.required">Drug Fee is required</mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div [hidden]="data.isChild" class="row">
                <div class="col-md-6">
                    <mat-form-field appearance="fill">
                        <mat-label>Physician Fee</mat-label>
                        <mat-select name="physicianFee" ngModel #physicianFee="ngModel" [(ngModel)]="serviceObj.physicianFee">
                            <mat-option *ngFor="let item of feeTypes" [value]="item.value">{{ item?.name }}</mat-option>
                            </mat-select>
                        <mat-error *ngIf="physicianFee.touched && physicianFee.invalid">
                            <mat-error *ngIf="physicianFee.errors.required">Physician Fee is required</mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field appearance="fill">
                        <mat-label>Other Fee</mat-label>
                        <mat-select name="otherFee" ngModel #otherFee="ngModel" [(ngModel)]="serviceObj.otherFee">
                            <mat-option *ngFor="let item of feeTypes" [value]="item.value">{{ item?.name }}</mat-option>
                            </mat-select>
                        <mat-error *ngIf="otherFee.touched && otherFee.invalid">
                            <mat-error *ngIf="otherFee.errors.required">Other Fee is required</mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>

    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button [disabled]="addServiceForm.form.invalid" color="primary" mat-button type="submit">Save</button>
        <button mat-button mat-dialog-close type="button">Cancel</button>
    </mat-dialog-actions>
</form>