import { Injectable } from '@angular/core';
import * as crypto from 'crypto-js';
import { environment } from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class HippaService {

  constructor() { }

  encrypt = data => {
    if(data != null) {
      const key = environment.SECRET;
      const keyutf = crypto.enc.Utf8.parse(key);
      const iv = crypto.enc.Base64.parse(key);
      const enc = crypto.AES.encrypt(data, keyutf, { iv: iv });
      return enc.toString();
    }
  }

  decrypt = data => {
    if(data != null) {
      const key = environment.SECRET;
      const keyutf = crypto.enc.Utf8.parse(key);
      const iv = crypto.enc.Base64.parse(key);
      const dec = crypto.AES.decrypt(data,keyutf,{iv: iv});
      const decStr = crypto.enc.Utf8.stringify(dec);
      return decStr;
    }
  }

}
