
<app-toolbar></app-toolbar>

<div class="py-4 bg-custom-primary">
    <p class="h2 display-5 text-center">Need help? Get in touch with &nbsp;&nbsp;<a href="tel:{{contactUsNumber}}" class="text-white">{{contactUsNumber}}</a></p>
    <p class="h4 lead text-center">Contact us by filling the below form.</p>
</div>

<div class="container py-4">
    <mat-card class="mat-elevation-z4">
        <mat-card-content>
            <form class="example-form" #contactForm="ngForm" (ngSubmit)="contactUsSubmit(vm, contactForm)">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>First Name</mat-label>
                    <input matInput ngModel #fname="ngModel" placeholder="First Name" name="fname" [(ngModel)]="vm.fname"
                        pattern="^[a-z A-Z,.'-]{1,260}$" required>
                    <mat-hint *ngIf="fname.touched && fname.invalid">
                        <mat-error *ngIf="fname.errors.required">First Name is required</mat-error>
                        <mat-error *ngIf="fname.errors.pattern">First Name should be in alphabets</mat-error>
                    </mat-hint>
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Last Name</mat-label>
                    <input matInput ngModel #lname="ngModel" name="lname" placeholder="Last Name" [(ngModel)]="vm.lname"
                        pattern="^[a-z A-Z,.'-]{1,260}$" required>
                    <mat-hint *ngIf="lname.touched && lname.invalid">
                        <mat-error *ngIf="lname.errors.required">Last Name is required</mat-error>
                        <mat-error *ngIf="lname.errors.pattern">Last Name should be in alphabets</mat-error>
                    </mat-hint>
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Email</mat-label>
                    <input matInput name="email" ngModel #email="ngModel" placeholder="Email" [(ngModel)]="vm.email"
                        pattern="^[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,125}[a-zA-Z]{2,63}$" required>
                    <mat-hint *ngIf="email.touched && email.invalid">
                        <mat-error *ngIf="email.errors.required">Email is required</mat-error>
                        <mat-error *ngIf="email.errors && email.errors.pattern">Invalid email</mat-error>
                    </mat-hint>
                </mat-form-field>
            
                <!-- <mat-form-field appearance="outline" style="max-width:30%;min-width:30%;">
                    <mat-label>Code</mat-label>
                    <mat-select ngModel name="countryCode" #countryCode="ngModel" [(ngModel)]="vm.countryCode" required>
                        <mat-option value="+1">USA (+1)</mat-option>
                        <mat-option value="+91">IND (+91)</mat-option>
                    </mat-select>
                    <mat-hint *ngIf="countryCode.touched && countryCode.invalid">
                        <mat-error *ngIf="countryCode.errors.required">Country code is required</mat-error>
                    </mat-hint>
                </mat-form-field> -->
            
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Contact Number</mat-label>
                    <input matInput ngModel #contact="ngModel" name="contact" type="tel" placeholder="Contact"
                        [(ngModel)]="vm.contact" type="text" required>
                    <!-- <mat-hint *ngIf="contact.touched && contact.invalid">
                        <mat-error *ngIf="contact.errors.required">Contact Info is required</mat-error>
                        <mat-error *ngIf="contact.errors.minlength || contact.errors.pattern">Invaid Mobile number</mat-error>
                    </mat-hint> -->
                </mat-form-field>
            
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Reason For getting in Touch</mat-label>
                    <mat-select ngModel name="reason" id="reason" #reason="ngModel" [(ngModel)]="vm.type" required>
                        <mat-option *ngFor="let reason of reasonsList" [value]="reason">
                            {{ reason }}
                        </mat-option>
                    </mat-select>
                    <mat-hint *ngIf="reason.touched && reason.invalid">
                        <mat-error *ngIf="reason.errors.required">Reason is required</mat-error>
                    </mat-hint>
                </mat-form-field>
            
                <mat-form-field appearance="outline" class="w-100">
                    <textarea matInput name="desc" ngModel #desc="ngModel" placeholder="Description*" [(ngModel)]="vm.desc" required></textarea>
                    <mat-hint *ngIf="desc.touched && desc.invalid">
                        <mat-error *ngIf="desc.errors.required">Description is required</mat-error>
                        <mat-error *ngIf="desc.errors.minlength">Description length should be minimum 10 characters</mat-error>
                    </mat-hint>
                </mat-form-field>
                <button mat-raised-button color="primary" class="p-2 px-4 fs-16" type="submit" [disabled]="!contactForm.form.valid">Submit Details</button>
            </form>
        </mat-card-content>
    </mat-card>
</div>
