<div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
  <h2 class="headline m-0" fxFlex="auto">View Details</h2>
  <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-divider class="mb-4"></mat-divider>

<mat-dialog-content fxLayout="column">

  <div *ngIf="data?.messageText" class="bg-custom-primary-light p-2 my-2" style="font-size: 12px;">{{data?.messageText}}</div>
  <p-table styleClass="p-datatable-gridlines" [value]="data.dataList">
    <ng-template pTemplate="header">
      <tr>
        <th>Item/Service Name</th>
        <th>Service Type/Code</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-element>
      <tr>
        <td class="text-truncate" style="font-weight: 500;">{{element?.name}}</td>
        <td>
          <!-- {{element?.code}}-{{element?.codeType}}  -->
          {{ codeTypes[element?.codeType].name }}/{{ element?.code }}
        </td>
      </tr>
    </ng-template>
  </p-table>
</mat-dialog-content>