<p-galleria [(value)]="galleryImgList" [responsiveOptions]="responsiveOptions" [containerStyle]="{'max-width': '640px'}" >
    <ng-template pTemplate="item" let-item>
        <lib-ngx-image-zoom class="w-100"
            [thumbImage]="item?.previewImageSrc"
            [fullImage]="item?.previewImageSrc"
            [magnification]="0.5"
            [enableLens]="true"
            [lensWidth]="150"
            [lensHeight]="150"
        ></lib-ngx-image-zoom>
    </ng-template>
    <ng-template pTemplate="thumbnail" let-item>
        <div class="p-1">
            <img [src]="item?.thumbnailImageSrc" class="w-100" />
        </div>
    </ng-template>
</p-galleria>