<form #addForm="ngForm" (ngSubmit)="submitPhysicianDetails(doctorObj)">
    <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
        <h2 class="headline m-0" fxFlex="auto">{{ data.isNew ? "Add" : "Update" }} Physician information</h2>
        <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <mat-divider class="mb-4"></mat-divider>

    <mat-dialog-content fxLayout="column">
        <div class="mb-4">
            <mat-slide-toggle color="primary" name="in_hospital" #in_hospital="ngModel" ngModel [(ngModel)]="doctorObj.in_hospital">Employeed Physician</mat-slide-toggle>
        </div>
        <div>
            <p>
                <mat-form-field appearance="fill">
                    <mat-label>NPI</mat-label>
                    <input matInput name="npi" ngModel #npi="ngModel" name="npi" type="text" [(ngModel)]="doctorObj.npi" required>
                    <mat-error *ngIf="npi.touched && npi.invalid">
                        <mat-error *ngIf="npi.errors.required">NPI is required</mat-error>
                    </mat-error>
                </mat-form-field>
            </p>
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field appearance="fill">
                        <mat-label>First name</mat-label>
                        <input matInput name="firstName" ngModel #firstName="ngModel" type="text" [(ngModel)]="doctorObj.firstName" required>
                        <mat-error *ngIf="firstName.touched && firstName.invalid">
                            <mat-error *ngIf="firstName.errors.required">First name is required</mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field appearance="fill">
                        <mat-label>Last name</mat-label>
                        <input matInput name="lastName" ngModel #lastName="ngModel" type="text" [(ngModel)]="doctorObj.lastName" required>
                        <mat-error *ngIf="lastName.touched && lastName.invalid">
                            <mat-error *ngIf="lastName.errors.required">Last name is required</mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field appearance="fill">
                        <mat-label>Middle name</mat-label>
                        <input matInput name="middleName" ngModel #middleName="ngModel" type="text" [(ngModel)]="doctorObj.middleName">
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field appearance="fill">
                        <mat-label>Gender</mat-label>
                        <mat-select name="gender" ngModel #gender="ngModel" [(ngModel)]="doctorObj.gender" required>
                            <mat-option value="0">Female</mat-option>
                            <mat-option value="1">Male</mat-option>
                            <mat-option value="2">Other</mat-option>
                            </mat-select>
                        <mat-error *ngIf="gender.touched && gender.invalid">
                            <mat-error *ngIf="gender.errors.required">Gender is required</mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field appearance="fill">
                        <mat-label>PAC ID</mat-label>
                        <input matInput name="pacId" ngModel #pacId="ngModel" type="text" [(ngModel)]="doctorObj.pacId" required>
                        <mat-error *ngIf="pacId.touched && pacId.invalid">
                            <mat-error *ngIf="pacId.errors.required">PAC ID is required</mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field appearance="fill">
                        <mat-label>Group name</mat-label>
                        <input matInput name="groupName" ngModel #groupName="ngModel" type="text" [(ngModel)]="doctorObj.groupName">
                        <mat-error *ngIf="groupName.touched && groupName.invalid">
                            <mat-error *ngIf="groupName.errors.required">Group name is required</mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field appearance="fill">
                        <mat-label>Suffix</mat-label>
                        <input matInput name="suffix" ngModel #suffix="ngModel" type="text" [(ngModel)]="doctorObj.suffix" required>
                        <mat-error *ngIf="suffix.touched && suffix.invalid">
                            <mat-error *ngIf="suffix.errors.required">Suffix is required</mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field appearance="fill">
                        <mat-label>Graduated Date</mat-label>
                        <input matInput name="exp" ngModel #exp="ngModel" [(ngModel)]="doctorObj.experience" required matInput [matDatepicker]="picker">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                            <mat-error *ngIf="exp.touched && exp.invalid">
                            <mat-error *ngIf="exp.errors.required">Graduated Date is required</mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <!-- <p>
                <mat-form-field appearance="fill">
                    <mat-label>Experience</mat-label>
                    <input matInput name="exp" ngModel #exp="ngModel" type="number" [(ngModel)]="doctorObj.experience" required>
                    <mat-error *ngIf="exp.touched && exp.invalid">
                        <mat-error *ngIf="exp.errors.required">Experience is required</mat-error>
                    </mat-error>
                </mat-form-field>
            </p> -->
            <p>
                <mat-form-field appearance="fill">
                    <mat-label>Profile pic URL</mat-label>
                    <input matInput name="avatar" ngModel #avatar="ngModel" type="text" [(ngModel)]="doctorObj.avatar">
                    <mat-error *ngIf="avatar.touched && avatar.invalid">
                        <mat-error *ngIf="avatar.errors.required">Experience is required</mat-error>
                    </mat-error>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="fill">
                    <mat-label>Speciality</mat-label>
                    <mat-select ngModel name="speciality" #speciality="ngModel" [(ngModel)]="doctorObj.speciality" multiple required>
                        <mat-option *ngFor="let speciality of specialitiesList" [value]="speciality._id">{{ speciality.name }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="speciality.touched && speciality.invalid">
                        <mat-error *ngIf="speciality.errors.required">Speciality is required</mat-error>
                    </mat-error>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="fill">
                    <mat-label>Description</mat-label>
                    <textarea matInput rows="4" name="description" ngModel #description="ngModel" name="description" type="text" [(ngModel)]="doctorObj.description"></textarea>
                    <mat-error *ngIf="description.touched && description.invalid">
                        <mat-error *ngIf="description.errors.required">Description is required</mat-error>
                    </mat-error>
                </mat-form-field>
            </p>
        </div>

    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button [disabled]="addForm.form.invalid" color="primary" mat-button type="submit">Save</button>
        <button mat-button mat-dialog-close type="button">Cancel</button>
    </mat-dialog-actions>
</form>