import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpCallService } from 'src/app/services/http-call.service';
import { environment } from 'src/environments/environment.prod';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import * as _ from 'underscore';
import { SpinnerService } from 'src/app/services/spinner.service';

interface DialogData {
  isNew: boolean;
  proID: string;
  isChild?: boolean;
  serviceItem?: any;
  insuranceList?: any;
}

@Component({
  selector: 'app-add-edit-mandatory-services',
  templateUrl: './add-edit-mandatory-services.component.html',
  styleUrls: ['./add-edit-mandatory-services.component.scss']
})
export class AddEditMandatoryServicesComponent implements OnInit {

  mandatoryTestCategories = [
    { name: 'EVALUATION & MANAGEMENT SERVICES', masterID: '60095f2f23b5e2083c46a652' },
    { name: 'LABORATORY & PATHOLOGY SERVICES', masterID: '60095f2f23b5e2083c46a638' },
    { name: 'RADIOLOGY SERVICES', masterID: '60095f2f23b5e2083c46a63f' },
    { name: 'MEDICINE & SURGERY SERVICES', masterID: '60095f2f23b5e2083c46a654' },
    { name: 'OTHERS', masterID: '606ebd93aa30040b0b5c5889' }
  ];

  serviceObj = {
    codeType: 1,
    itemCode: '',
    serviceName: '',
    serviceID: '',
    shortDesc: '',
    description: '',
    standardPriceIP: null,
    standardPriceOP: null,
    cashPriceIP: null,
    cashPriceOP: null,
    outNetworkPriceIP: null,
    outNetworkPriceOP: null,
    // minPriceIP: null,
    // minPriceOP: null,
    // maxPriceIP: null,
    // maxPriceOP: null,
    isBundled: false,
    isShoppable: true,
    isServicable: true,
    categoryType: '',
    serviceItemType: '',
    // billingID: '',
    quantity: '',
    // inclusive: '',
    // exclusive: ''
    facilityFee: '',
    drugFee: '',
    physicianFee: '',
    otherFee: '',
    physicians: []
  };

  feeTypes = [
    { name: 'Included', value: 'Y' },
    { name: 'Not Included', value: 'N' },
    { name: 'Partially Included', value: 'P' },
    { name: 'Not Applicable', value: 'NA' },
  ];

  proInsuranceList: any = [];

  serviceNameControl: FormControl = new FormControl();
  servicesDataList: any = [];
  
  serviceItemTypeControl = new FormControl();
  filteredServiceItemTypesOptions: Observable<string[]>;

  codeTypes = environment.codeTypes;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<AddEditMandatoryServicesComponent>,
    private snackbar: MatSnackBar,
    private spinner: SpinnerService,
    private httpCall: HttpCallService
  ) { 
    if(data != null) {
      this.data.insuranceList.forEach(element => {
        let newItem = {
          carrier_name: element.name,
          carrier_id: element.carrier_id,
          ip_price: null,
          op_price: null
        }
        this.proInsuranceList.push(newItem);
      });
    }
  }

  serviceItemTypes = [
    { item_type: 'Facility Fee' },
    { item_type: 'Drug Fee' },
    { item_type: 'Physician Fee' },
    { item_type: 'Other Fee' },
  ];
  
  ngOnInit(): void {
    if(!this.data.isNew) {
      this.getServiceDetails(this.data.serviceItem.id);
    }
    if(this.data.isChild) {
      this.getPhysicians(localStorage.getItem('proID'));
    }
    // this.getServiceItemTypes(localStorage.getItem('proID'));
    // this.filteredServiceItemTypesOptions = this.serviceItemTypeControl.valueChanges.pipe( startWith(''), map(value => this.filterServiceItemTypes(value)) );
  }

  // filterServiceItemTypes(value: string) {
  //   if(value) {
  //     const filterValue = value.toLowerCase();
  //     return this.serviceItemTypes.filter(element => element.item_type.toLowerCase().includes(filterValue));
  //   }
  // }

  searchBy = '1';
  servicesList: any = []
  getServicesLookup(serviceName, codeType) {
    if(serviceName) {
      let body = {
        code_type: codeType,
        text: serviceName,
        type: parseInt(this.searchBy)
      };
      this.httpCall.postAuth(`${environment.providerDomain}/providerApi/drglookup`, body).subscribe( res => {
        // this.spinner.spin$.next(false);
        if(res['success']) {
          this.servicesList = res['response'];
        } else {
          // this.snackbar.open(res['message'], 'Failed', {
          //   duration: 5000
          // });
        }
      }, (err) => {
        // this.spinner.spin$.next(false);
        // this.snackbar.open(err.message, 'Failed', {
        //   duration: 5000
        // });
      });
    }
  }

  physiciansList: any = [];
  getPhysicians(proID) {
    let body = {
      providerId: proID
    };
    this.httpCall.postAuth(`${environment.providerDomain}/providerApi/getAllDoctors`, body).subscribe(res => {
      if (res['success']) {
        this.physiciansList = res['response'];
      } else {
        this.snackbar.open(res['message'], 'Failed', {
          duration: 5000,
          panelClass: 'snackBar-failure'
        });
      }
    }, (err) => {
      this.snackbar.open(err.error['message'], 'Failed', {
        duration: 5000,
        panelClass: 'snackBar-failure'
      });
    });
  }

  // serviceItemTypes: any = [];
  // getServiceItemTypes(proID) {
  //   this.spinner.showSpinner('Loading...Please Wait');
  //   this.httpCall.getAuth(`${environment.providerDomain}/BackEnd/getServiceTypes/${proID}`).subscribe(res => {
  //     this.spinner.stopSpinner();
  //     if (res['success']) {
  //       this.serviceItemTypes = res['response'];
  //     } else {
  //       this.snackbar.open(res['message'], 'Failed', {
  //         duration: 5000,
  //         panelClass: 'snackBar-failure'
  //       });
  //     }
  //   }, (err) => {
  //     this.spinner.stopSpinner();
  //     this.snackbar.open(err.error['message'], 'Failed', {
  //       duration: 5000,
  //       panelClass: 'snackBar-failure'
  //     });
  //   });
  // }

  getServiceDetails(serviceID) {
    this.httpCall.get(`${environment.providerDomain}/BackEnd/fetchNewSingleProviderTest/${serviceID}`).subscribe( res => {
      if(res['success']) {
        let response = res['response'][0];
        this.serviceObj.codeType = response.code_type;
        this.serviceObj.itemCode = response.cpt_code;
        this.serviceObj.serviceItemType = response.item_type;
        this.serviceObj.serviceName = response.test_name != undefined ? response.test_name : '';
        this.serviceObj.serviceID = response.master_id ? response.master_id : response.provider_master;
        this.serviceObj.shortDesc = response.short_description;
        this.serviceObj.description = response.description;
        this.serviceObj.categoryType = response.department;
        this.serviceObj.standardPriceIP = response.std_price_ip;
        this.serviceObj.standardPriceOP = response.std_price_op;
        this.serviceObj.cashPriceIP = response.cash_price_ip;
        this.serviceObj.cashPriceOP = response.cash_price_op;
        this.serviceObj.outNetworkPriceIP = response.outofnetwork_ip;
        this.serviceObj.outNetworkPriceOP = response.outofnetwork_op;
        this.serviceObj.isBundled = response.is_bundled;
        this.serviceObj.isServicable = response.isServicable;
        this.serviceObj.isShoppable = response.isShoppable;
        // this.proInsuranceList.forEach(element => {
        //   let filtered = response.insurance_prices.filter( item => item.carrier_id == element.carrier_id);
        //   if(filtered.length > 0) {
        //     if(filtered[0].ip_price != null) {
        //       element.ip_price = filtered[0].ip_price;
        //     }
        //     if(filtered[0].op_price != null) {
        //       element.op_price = filtered[0].op_price;
        //     }
        //   }
        // });
        this.serviceObj.facilityFee = response.Facility_fee;
        this.serviceObj.drugFee = response.Drug_fee;
        this.serviceObj.physicianFee = response.Physician_fee;
        this.serviceObj.otherFee = response.Other_fee;
        this.serviceObj.physicians = _.pluck(response.doctors, '_id');
        // this.serviceObj.physicians = response.doctors;
        // console.log(this.serviceObj.physicians);
        // console.log(this.physiciansList)
        // this.serviceObj.inclusive = res['response'].inclusive;
        // this.serviceObj.exclusive = res['response'].exclusive;
      } else {
        this.snackbar.open(res['message'], 'Failed', {
          duration: 5000,
          panelClass: 'snackBar-failure'
        });
      }
    }, (err) => {
      this.snackbar.open(err.error['message'], 'Failed', {
        duration: 5000,
        panelClass: 'snackBar-failure'
      });
    });
  }

  toggleShopable(event: any) {
    this.serviceObj.isShoppable = event.checked;
  }

  selectService(selectedItem) {
    this.serviceObj.serviceID = selectedItem._id;
    this.serviceObj.itemCode = selectedItem.cpt_code;
    this.serviceObj.shortDesc = selectedItem.test_name;
  }

  submitServiceDetails() {
    let body;
    if(this.data.isNew) {
      if(this.data.isChild) {
        this.serviceObj.shortDesc = this.serviceObj.shortDesc ? this.serviceObj.shortDesc : this.serviceObj.serviceName;
        let returnedData = {
          notFromChargeMaster: true,
          selectedService: this.serviceObj,
          proInsuranceList: this.proInsuranceList
        }
        this.dialogRef.close(returnedData);
      }
    } else {
      if(this.data.isChild) {
        // Edit child service
        let physicanArr = [];
        this.serviceObj.physicians.forEach(element => {
          physicanArr.push({doctorId: element});
        });
        body = {
          // test_name: this.serviceObj.serviceName.toUpperCase(),
          // department: this.serviceObj.categoryType,
          is_bundled: this.serviceObj.isBundled,
          isServicable: this.serviceObj.isServicable,
          short_description: this.serviceObj.shortDesc,
          description: this.serviceObj.description,
          employee_id: localStorage.getItem('empID'),
          service_id: this.data.serviceItem.id,
          item_type: this.serviceObj.serviceItemType,
          doctors: physicanArr
        };
        this.spinner.showSpinner('Loading...Please Wait');
        this.httpCall.postAuth(`${environment.providerDomain}/providerApi/manageShoppableChild`, body).subscribe( res => {
          this.spinner.stopSpinner();
          if(res['success']) {
            this.snackbar.open(res['message'], 'Success', {
              duration: 5000,
              panelClass: 'snackBar-success'
            });
            this.dialogRef.close(res);
          } else {
            this.snackbar.open(res['message'], 'Failed', {
              duration: 5000,
              panelClass: 'snackBar-failure'
            });
          }
        }, (err) => {
          this.spinner.stopSpinner();
          this.snackbar.open(err.error['message'], 'Failed', {
            duration: 5000,
            panelClass: 'snackBar-failure'
          });
        });
      } else {
        // Edit parent service
        body = {
          // test_name: this.serviceObj.serviceName.toUpperCase(),
          // code_type: this.serviceObj.codeType,
          // cpt_code: this.serviceObj.itemCode,
          // item_type: this.serviceObj.serviceItemType,
          is_bundled: this.serviceObj.isBundled,
          isShoppable: this.serviceObj.isShoppable,
          short_description: this.serviceObj.shortDesc,
          description: this.serviceObj.description,
          employee_id: localStorage.getItem('empID'),
          service_id: this.data.serviceItem.id,
          department: this.serviceObj.categoryType,
          Facility_fee: this.serviceObj.facilityFee,
          Drug_fee: this.serviceObj.drugFee,
          Physician_fee: this.serviceObj.physicianFee,
          Other_fee: this.serviceObj.otherFee,
        };
        this.spinner.showSpinner('Loading...Please Wait');
        this.httpCall.postAuth(`${environment.providerDomain}/providerApi/manageShoppable`, body).subscribe( res => {
          this.spinner.stopSpinner();
          if(res['success']) {
            this.snackbar.open(res['message'], 'Success', {
              duration: 5000,
              panelClass: 'snackBar-success'
            });
            this.dialogRef.close(res);
          } else {
            this.snackbar.open(res['message'], 'Failed', {
              duration: 5000,
              panelClass: 'snackBar-failure'
            });
          }
        }, (err) => {
          this.spinner.stopSpinner();
          this.snackbar.open(err.error['message'], 'Failed', {
            duration: 5000,
            panelClass: 'snackBar-failure'
          });
        });
      }
    }
  }

}
