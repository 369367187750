import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ManagePhysicianServiceComponent } from './manage-physician-service/manage-physician-service.component';
import { AddMultiChildrenComponent } from './add-multi-children/add-multi-children.component';
import { AddMultiParentComponent } from './add-multi-parent/add-multi-parent.component';
import { RuleEngineComponent } from './rule-engine/rule-engine.component';
import { ProviderGuardService } from '../services/auth-guard.service';
import { AddEditCmInsurancePricesComponent } from './add-edit-cm-insurance-prices/add-edit-cm-insurance-prices.component';
import { ViewCmInsurancePricesComponent } from './view-cm-insurance-prices/view-cm-insurance-prices.component';
import { ViewServiceInsurancePricesComponent } from './view-service-insurance-prices/view-service-insurance-prices.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AddMrfComponent } from './add-mrf/add-mrf.component';
import { FaqComponent } from './faq/faq.component';
import { ViewInventoryComponent } from './view-inventory/view-inventory.component';
import { InsurancePricesComponent } from './insurance-prices/insurance-prices.component';


const routes: Routes = [
  {
    path: 'manage-services',
    component: ManagePhysicianServiceComponent
  },
  {
    path: 'add-multi-children',
    component: AddMultiChildrenComponent,
    canActivate: [ProviderGuardService]
  },
  {
    path: 'add-multi-parent',
    component: AddMultiParentComponent,
    canActivate: [ProviderGuardService]
  },
  {
    path: 'view-cm-insurance-prices/:serviceID/:mixedID',
    component: ViewCmInsurancePricesComponent,
    canActivate: [ProviderGuardService]
  },
  {
    path: 'view-service-insurance-prices/:serviceID/:mixedID',
    component: ViewServiceInsurancePricesComponent,
  },
  {
    path: 'insurance-prices/:serviceID/:mixedID',
    component: InsurancePricesComponent,
  },
  {
    path: 'rule-engine',
    component: RuleEngineComponent,
    canActivate: [ProviderGuardService]
  },
  {
    path: 'get-rule-engine/:id',
    component: RuleEngineComponent,
    canActivate: [ProviderGuardService]
  },
  {
    path: 'add-mrf',
    component: AddMrfComponent,
    canActivate: [ProviderGuardService]
  },
  {
    path: 'view-inventory/:id',
    component: ViewInventoryComponent,
    canActivate: [ProviderGuardService]
  },
  { 
    path: 'contactUs',
    component: ContactUsComponent 
  },
  { 
    path: 'FAQ',
    component: FaqComponent 
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SharedRoutingModule { }
