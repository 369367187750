import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
declare var gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor() { }

  public emitEvent(eventCategory: string, eventAction: string, eventLabel: string, eventValue: number) {
    gtag('event', 'click', {
      'eventCategory': eventCategory,
      'eventLabel': eventLabel,
      'value': eventValue
    });
  }

  public pageViews(event) {
    gtag('config', environment.GoogleAnalyticsKey, {
      'pagePath': event
    });
  }

}
