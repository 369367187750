import { Component, OnInit, OnDestroy, ChangeDetectorRef, Inject, PLATFORM_ID } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpCallService } from 'src/app/services/http-call.service';
import { environment } from 'src/environments/environment.prod';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'underscore';
import { MediaMatcher } from '@angular/cdk/layout';
import { UpdateRuleEngineComponent } from '../update-rule-engine/update-rule-engine.component';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-rule-engine',
  templateUrl: './rule-engine.component.html',
  styleUrls: ['./rule-engine.component.scss']
})
export class RuleEngineComponent implements OnInit {

  backToPreviousPage() {
    this.location.back();
  }

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  ruleID: string;
  constructor(
    public httpCall: HttpCallService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private snackbar: MatSnackBar,
    private spinner: SpinnerService,
    public dialog: MatDialog,
    // public googleAnalytics: GoogleAnalyticsService,
    @Inject(PLATFORM_ID) private platformId: any,
    // private updateMetaData: UpdateMetaDataService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private location: Location
  ) { 
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    
    this.activatedRoute.params.subscribe(params => {
      if(params.id) {
        this.ruleID = params.id;
        this.getRuleEngineData(params.id);
      }
    });
  }

  providerNumber: string;
  toggler:boolean = false;
  ngOnInit(): void {
    this.providerNumber = localStorage.getItem('proNumber');
    if(this.router.url.includes('/rule-engine')) {
      this.getDataInsertedInfo(localStorage.getItem('proID'));
    }
  }

  hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, 0.2)` : null;
  }

  selectedItem:any = [];
  filteredItems: any[];
  filterItem(event) {
    this.httpCall.get(`${environment.providerDomain2}/BackEnd/getProvider/${event.query}`).subscribe((res) => {
      this.spinner.stopSpinner();
      if(res['success']) {
        this.filteredItems = res['response'];
      }
    }, err => {
      this.spinner.stopSpinner();
      this.snackbar.open(err.error['message'], 'Failed', {
        duration: 5000,
        panelClass: 'snackBar-failure'
      });
    });
  }
  
  dataInserted: boolean;
  getDataInsertedInfo(proID) {
    this.spinner.showSpinner('Loading...Please Wait');
    this.httpCall.getAuth(`${environment.ruleEngineDomain}/BackEnd/getInsertedInfo/${proID}`).subscribe((res: any) => {
      this.spinner.stopSpinner();
      if(res['success']) {
        this.dataInserted = res['response'].dataInserted;
        this.runRuleEngine(localStorage.getItem('proID'));
      } else {
        this.snackbar.open(res['message'], 'Failed', {
          duration: 5000,
          panelClass: 'snackBar-failure'
        });
      }
    }, err => {
      this.spinner.stopSpinner();
      this.snackbar.open(err.error['message'], 'Failed', {
        duration: 5000,
        panelClass: 'snackBar-failure'
      });
    });
  }

  ruleEngineList: any = [];
  getRuleEngineData(ruleID) {
    if(ruleID != undefined){
      this.spinner.showSpinner('Loading...Please Wait');
      this.httpCall.getAuth(`${environment.ruleEngineDomain}/BackEnd/ruleDetails/${ruleID}`).subscribe((res: any) => {
        this.spinner.stopSpinner();
        if(res['success']) {
          this.ruleEngineList = res['response'].filter(element => element.skipped == false);
          this.toggler = !this.toggler;
        } else {
          this.snackbar.open(res['message'], 'Failed', {
            duration: 5000,
            panelClass: 'snackBar-failure'
          });
        }
      }, err => {
        this.spinner.stopSpinner();
        this.snackbar.open(err.error['message'], 'Failed', {
          duration: 5000,
          panelClass: 'snackBar-failure'
        });
      });
    }
  }

  runRuleEngine(proID) {
    this.ruleEngineList = [];
    this.spinner.showSpinner('Loading...Please Wait');
    let apiUrl = this.dataInserted ? 'providerApi/CMCEngineInserted' : 'providerApi/CMCEngine';
    this.httpCall.getAuth(`${environment.providerDomain2}/${apiUrl}/${proID}/${localStorage.getItem('empID')}`).subscribe((res: any) => {
      this.spinner.stopSpinner();
      if(res['success']) {
        this.ruleEngineList = res['response'];
      } else {
        this.snackbar.open(res['message'], 'Failed', {
          duration: 5000,
          panelClass: 'snackBar-failure'
        });
      }
    }, err => {
      this.spinner.stopSpinner();
      this.snackbar.open(err.error['message'], 'Failed', {
        duration: 5000,
        panelClass: 'snackBar-failure'
      });
    });
  }

  getSuccessCount(item) {
    let successCount = 0;
    let failureCount = 0;
    item.subSections.forEach(element => {
      successCount += _.where(element.rules, { status: true }).length;
      failureCount += element.rules.length;
    });
    return [successCount, failureCount];
  }

  checkIsCompliance() {
    if(_.contains(_.pluck(this.ruleEngineList, 'status'), false)) {
      return false;
    } else {
      return true
    }
  }

  skipRules(selectedItem) {
    Swal.fire({
      title: 'Are you sure?',
      text: "Skip this section rules",
      icon: 'warning',
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonColor: environment.colors.customPrimary,
      cancelButtonColor: environment.colors.customDanger,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        let body = {
          id: _.pluck(_.flatten(_.pluck(selectedItem.subSections, 'rules')), 'id')
        };
        this.spinner.showSpinner('Loading...Please Wait');
        this.httpCall.postAuth(`${environment.ruleEngineDomain}/BackEnd/skipRules`, body).subscribe((res: any) => {
          this.spinner.stopSpinner();
          if(res['success']) {
            this.snackbar.open(res['message'], 'Success', {
              duration: 5000,
              panelClass: 'snackBar-success'
            });
            this.getRuleEngineData(_.pluck(selectedItem.subSections, 'rules')[0][0].ruleId);
          } else {
            this.snackbar.open(res['message'], 'Failed', {
              duration: 5000,
              panelClass: 'snackBar-failure'
            });
          }
        }, err => {
          this.spinner.stopSpinner();
          this.snackbar.open(err.error['message'], 'Failed', {
            duration: 5000,
            panelClass: 'snackBar-failure'
          });
        });
      }
    });
  }

  async openUpdateRuleEngineDialog(selectedItem) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.minWidth = '40vw';
    dialogConfig.data = {
      selectedRuleItem: selectedItem,
    };
    const dialogRef = this.dialog.open(UpdateRuleEngineComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      if (data.success){
        this.ruleEngineList = data['response'];
        this.ruleID = selectedItem.ruleId;
        this.getRuleEngineData(selectedItem.ruleId);
      }
    });
  }

}