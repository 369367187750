import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { HttpCallService } from 'src/app/services/http-call.service';

@Component({
  selector: 'app-view-inventory',
  templateUrl: './view-inventory.component.html',
  styleUrls: ['./view-inventory.component.scss']
})
export class ViewInventoryComponent implements OnInit {

  id:string;

  constructor(
    private httpCall : HttpCallService,
    private activatedRoute : ActivatedRoute,
    private snackbar : MatSnackBar,
    private location: Location
  ) { 
    this.activatedRoute.params.subscribe( param => {
      this.id = param.id;
      console.log(this.id);
    } )
  }

  back(){
    this.location.back()
  }

  ngOnInit(): void {
    this.getCardDetails();
  }
  galleryImgList:any = [];
  cards:any;
  getCardDetails(){
    if(this.id){
      this.httpCall.get(`https://ng9diun4tf.execute-api.us-east-1.amazonaws.com/dev/inventory/${this.id}`).subscribe( res => {
        if (res['success']) {
            this.cards = res['response'].details;
            this.galleryImgList.push(
              {
                "previewImageSrc": this.cards?.front_preview,
                "thumbnailImageSrc": this.cards?.front_preview,
                "alt": 'Front View',
                "title": 'Front View'
              },
              {
                "previewImageSrc": this.cards?.back_preview,
                "thumbnailImageSrc": this.cards?.back_preview,
                "alt": 'Back View',
                "title": 'Back View'
              }
            );
            console.log(this.cards);
        } else {
          this.snackbar.open(res['response'], 'Failed', {
            duration: 5000,
            panelClass: 'snackBar-failure'
          });
        }
      }, (err) => {
        this.snackbar.open(err.error['message'], 'Failed', {
          duration: 5000,
          panelClass: 'snackBar-failure'
        });
      } )
    }
  }

}
