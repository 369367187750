<!-- <ngx-spinner [fullScreen]="false" type="ball-clip-rotate" size="medium"></ngx-spinner> -->

<form #addServiceForm="ngForm" (ngSubmit)="submitServiceDetails()">
    <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
        <p class="h3 m-0" fxFlex="auto">{{ data.isNew ? "Add" : "Update" }} service</p>
        <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <mat-divider class="mb-4"></mat-divider>

    <mat-dialog-content fxLayout="column">
        <p>
            <mat-form-field appearance="fill">
                <mat-label>Shoppable Service Name</mat-label>
                <input matInput name="serviceName" [(ngModel)]="serviceObj.serviceName" (keyup)="getServicesLookup($event.target.value, serviceObj.codeType)" [formControl]="serviceNameControl" [matAutocomplete]="auto" type="text" autocomplete="off" required>
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let item of servicesList" [value]="item.test_name" (click)="selectService(item)">
                    {{ item.test_name }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </p>
        <div class="row">
            <div class="col-md-6 mb-4">
                <mat-slide-toggle color="primary" name="canBook" #canBook="ngModel" ngModel [(ngModel)]="serviceObj.canBook">Bookable Service</mat-slide-toggle>
            </div>
            <div class="col-md-6 mb-4" *ngIf="data?.proObj?.is_bid_accepted">
                <mat-slide-toggle color="primary" name="isEligibleForBid" #isEligibleForBid="ngModel" ngModel [(ngModel)]="serviceObj.isEligibleForBid">Eligible for Bid</mat-slide-toggle>
            </div>
            <div class="col-md-6 mb-4" *ngIf="currentUserAccess?.Zeacard_Accepted && data?.proObj?.is_zeacard_accepted">
                <mat-slide-toggle color="primary" name="is_zeacard_accepted" #is_zeacard_accepted="ngModel" ngModel [(ngModel)]="serviceObj.is_zeacard_accepted">Eligible for ZeaCard</mat-slide-toggle>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <mat-form-field appearance="fill">
                    <mat-label>Code type</mat-label>
                    <mat-select name="codeType" ngModel #codeType="ngModel" [(ngModel)]="serviceObj.codeType" required>
                        <mat-option *ngFor="let item of codeTypes" [value]="item?.value">{{ item?.name }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="codeType.touched && codeType.invalid">
                        <mat-error *ngIf="codeType.errors.required">Code type is required</mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="fill">
                    <mat-label>Item/Service Code</mat-label>
                    <input matInput name="itemCode" ngModel #itemCode="ngModel" type="text" [(ngModel)]="serviceObj.itemCode" required>
                    <mat-error *ngIf="itemCode.touched && itemCode.invalid">
                        <mat-error *ngIf="itemCode.errors.required">Item Code is required</mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <mat-form-field appearance="fill">
                    <mat-label>Package Price</mat-label>
                    <input matInput name="packagePrice" ngModel #packagePrice="ngModel" type="number" [(ngModel)]="serviceObj.packagePrice" required>
                    <mat-error *ngIf="packagePrice.touched && packagePrice.invalid">
                        <mat-error *ngIf="packagePrice.errors.required">Package Price is required</mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="fill">
                    <mat-label>Discount</mat-label>
                    <input matInput name="discount" ngModel #discount="ngModel" type="number" [(ngModel)]="serviceObj.discount" required>
                    <mat-error *ngIf="discount.touched && discount.invalid">
                        <mat-error *ngIf="discount.errors.required">Discount is required</mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <mat-form-field appearance="fill">
                    <mat-label>Zea Price</mat-label>
                    <input matInput name="zeaPrice" ngModel #zeaPrice="ngModel" type="number" [(ngModel)]="serviceObj.zeaPrice">
                    <mat-error *ngIf="zeaPrice.touched && zeaPrice.invalid">
                        <mat-error *ngIf="zeaPrice.errors.required">Zea Price is required</mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="fill">
                    <mat-label>Duration</mat-label>
                    <input matInput name="duration" ngModel #duration="ngModel" type="number" [(ngModel)]="serviceObj.duration">
                    <mat-error *ngIf="duration.touched && duration.invalid">
                        <mat-error *ngIf="duration.errors.required">Duration is required</mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <p>
            <mat-form-field appearance="fill">
                <mat-label>Physicians</mat-label>
                <mat-select ngModel name="physicians"
                 #physicians="ngModel" 
                 multiple
                 [compareWith]="compareObjects"
                 [(ngModel)]="serviceObj.physicians">
                    <mat-option *ngFor="let item of physiciansList" [value]="item">Dr. {{item.firstName}} {{item.lastName}}</mat-option>
                </mat-select>
            </mat-form-field>
        </p>
        <!-- <p>
            <mat-form-field appearance="fill">
                <mat-label>Physicians</mat-label>
                <mat-select ngModel name="physicians" #physicians="ngModel" multiple [(ngModel)]="element.doctors">
                    <mat-option *ngFor="let item of physiciansList" [value]="item">Dr. {{item.firstName}} {{item.lastName}}</mat-option>
                </mat-select>
            </mat-form-field>
        </p> -->

    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button [disabled]="addServiceForm.form.invalid" color="primary" mat-button type="submit">Save</button>
        <button mat-button mat-dialog-close type="button">Cancel</button>
    </mat-dialog-actions>
</form>