import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpCallService } from 'src/app/services/http-call.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { environment } from 'src/environments/environment.prod';

interface DialogData {
  selectedItem?: any;
}

@Component({
  selector: 'app-view-service-detail',
  templateUrl: './view-service-detail.component.html',
  styleUrls: ['./view-service-detail.component.scss']
})
export class ViewServiceDetailComponent implements OnInit {

  codeTypes = environment.codeTypes;
  selectedServiceObj;

  columns: any[];
  pricesList: any[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<ViewServiceDetailComponent>,
    private snackbar: MatSnackBar,
    private spinner: SpinnerService,
    private httpCall: HttpCallService
  ) {
    if(data != null) {
      this.selectedServiceObj = data.selectedItem;
    }
  }

  ngOnInit(): void {
    this.columns = [ 
      { header: 'Name', field: 'name' },
      { header: 'In Patient', field: 'ip_price' },
      { header: 'Out Patient', field: 'op_price' },
    ];
    this.pricesList = [
      { name: 'Cash Price', ip_price: this.selectedServiceObj.cash_price_ip, op_price: this.selectedServiceObj.cash_price_op },
      { name: 'Standard Price', ip_price: this.selectedServiceObj.std_price_ip, op_price: this.selectedServiceObj.std_price_op },
      { name: 'Min Price', ip_price: this.selectedServiceObj.min_price_ip, op_price: this.selectedServiceObj.min_price_op },
      { name: 'Max Price', ip_price: this.selectedServiceObj.max_price_ip, op_price: this.selectedServiceObj.max_price_op },
      { name: 'Out of Network Price', ip_price: this.selectedServiceObj.outofnetwork_ip, op_price: this.selectedServiceObj.outofnetwork_op },
    ];
    if(this.selectedServiceObj.insurance_prices.length > 0) {
      this.selectedServiceObj.insurance_prices.forEach(element => {
        this.pricesList.push({ name: element.carrier_name, ip_price: element.ip_price, op_price: element.op_price });
      });
    }

  }

}
