

<app-toolbar></app-toolbar>

<div class="container-fluid">
    <mat-card class="my-4 mat-elevation-z2">
        <mat-card-content>
            <div class="d-flex align-items-center">
                <button class="mb-2 mr-2" mat-icon-button type="button" (click)="backToPreviousPage()">
                    <mat-icon>arrow_back</mat-icon>
                </button>
                <p class="h6">
                    If you'd like to add any additional shoppable services that we real ready bundled in your charge master, 
                    <br/>
                    you may search for those and add them here.
                </p>
            </div>
            <div>
                <div class="row my-4 align-items-center no-gutter">
                    <div class="col" *ngFor="let item of optionalTestCategories">
                        <div matRipple (click)="categorySelected(item)" [ngClass]="{'text-white': item?.masterID === selectedCategoryID, 'bg-custom-primary': item?.masterID === selectedCategoryID}" class="mat-elevation-z2 py-2 btn w-100" style="font-size: small;font-weight: 500;">{{item?.name}} ({{getEachTabCount(item?.masterID)}})</div>
                    </div>
                </div>
            </div>
            <div class="d-flex">
                <div class="flex-shrink-1 p-1">
                    <mat-form-field appearance="fill">
                        <mat-label>Code Type</mat-label>
                        <mat-select name="codeType" ngModel #codeType="ngModel" [(ngModel)]="selectedCodeType">
                            <mat-option *ngFor="let item of codeTypes" [value]="item?.value">{{ item?.name }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!-- <div class="flex-shrink-1 p-1">
                    <mat-form-field appearance="fill">
                        <mat-label>Service item type</mat-label>
                        <mat-select name="serviceItemType" ngModel #serviceItemType="ngModel" [(ngModel)]="selectedServiceItemType">
                            <mat-option *ngFor="let item of serviceItemTypes" [value]="item?.item_type">{{ item?.item_type }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div> -->
                <div class="flex-shrink-1 p-1">
                    <mat-form-field appearance="fill">
                        <mat-label>Search by</mat-label>
                        <mat-select name="searchBy" [(ngModel)]="searchBy">
                            <mat-option value="1">Text</mat-option>
                            <mat-option value="2">Code</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="w-100 p-1">
                    <div>
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Search Services</mat-label>
                            <input matInput name="service" (click)="$event.target.select()" [(ngModel)]="enteredServiceName" (keyup)="getChangeMasterLookup($event)" [matAutocomplete]="autoService" type="text" autocomplete="off">
                            <mat-autocomplete #autoService="matAutocomplete">
                                <mat-option *ngFor="let item of chargeMasterServicesList" [value]="item?.test_name" (click)="serviceSelectedFromChargeMaster(item)">
                                    <span>{{ item?.test_name }}</span>
                                    <div class="d-flex" style="font-size: smaller;">
                                        <div>{{ codeTypes[item?.code_type].name }}-{{ item?.cpt_code }}</div>
                                        <div class="mx-4"></div>
                                        <div class="font-weight-bold">
                                            {{ item?.std_price_ip | currency:'USD' }}
                                        </div>
                                    </div>
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div class="text-right">
                        <a href="javascript:void(0)" (click)="openTestDialog(true)">+Add Shoppable Service</a>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="tempAddedChildrenServicesList.length > 0; index as i; else noData">
                <div class="row">
                    <div class="col-md-4 mt-4" *ngFor="let item of tempAddedChildrenServicesList">
                        <div class="card p-3">
                            <div class="d-flex align-items-center">
                                <div class="w-100 trimText">
                                    <p class="h6 m-0">{{ item?.test_name }}</p>
                                </div>
                                <div class="flex-shrink-1">
                                    <button mat-icon-button (click)="removeChildren(item)">
                                        <mat-icon aria-hidden="false">close</mat-icon>
                                    </button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <p>{{ codeTypes[item?.code_type].name }}/{{ item?.cpt_code }}</p>
                                </div>
                                <div class="col">
                                    <a href="javascript:void(0)" *ngIf="item?.std_price_ip || item?.std_price_op" (click)="viewServiceDetailDialog(item)">View details</a>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 text-center">
                                    <p class="m-0 h6 custom-primary" style="font-weight: 500;">{{ item?.std_price_ip ? (item?.std_price_ip | currency:'USD') : 'N/A' }}</p>
                                    <p class="m-0" style="font-size: smaller;">Standard price (IP)</p>
                                </div>
                                <div class="col-md-6 text-center">
                                    <p class="m-0 h6 custom-primary" style="font-weight: 500;">{{ item?.std_price_op ? (item?.std_price_op | currency:'USD') : 'N/A' }}</p>
                                    <p class="m-0" style="font-size: smaller;">Standard price (OP)</p>
                                </div>
                            </div>

                            <div class="mt-2 row">
                                <div class="col-md-6">
                                    <mat-form-field appearance="fill">
                                        <mat-label>Facility Fee</mat-label>
                                        <mat-select name="facilityFee" ngModel #facilityFee="ngModel" [(ngModel)]="item.Facility_fee">
                                            <mat-option *ngFor="let item of feeTypes" [value]="item.value">{{ item?.name }}</mat-option>
                                            </mat-select>
                                        <mat-error *ngIf="facilityFee.touched && facilityFee.invalid">
                                            <mat-error *ngIf="facilityFee.errors.required">Facility Fee is required</mat-error>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6">
                                    <mat-form-field appearance="fill">
                                        <mat-label>Drug Fee</mat-label>
                                        <mat-select name="drugFee" ngModel #drugFee="ngModel" [(ngModel)]="item.Drug_fee">
                                            <mat-option *ngFor="let item of feeTypes" [value]="item.value">{{ item?.name }}</mat-option>
                                            </mat-select>
                                        <mat-error *ngIf="drugFee.touched && drugFee.invalid">
                                            <mat-error *ngIf="drugFee.errors.required">Drug Fee is required</mat-error>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6">
                                    <mat-form-field appearance="fill">
                                        <mat-label>Physician Fee</mat-label>
                                        <mat-select name="physicianFee" ngModel #physicianFee="ngModel" [(ngModel)]="item.Physician_fee">
                                            <mat-option *ngFor="let item of feeTypes" [value]="item.value">{{ item?.name }}</mat-option>
                                            </mat-select>
                                        <mat-error *ngIf="physicianFee.touched && physicianFee.invalid">
                                            <mat-error *ngIf="physicianFee.errors.required">Physician Fee is required</mat-error>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6">
                                    <mat-form-field appearance="fill">
                                        <mat-label>Other Fee</mat-label>
                                        <mat-select name="otherFee" ngModel #otherFee="ngModel" [(ngModel)]="item.Other_fee">
                                            <mat-option *ngFor="let item of feeTypes" [value]="item.value">{{ item?.name }}</mat-option>
                                            </mat-select>
                                        <mat-error *ngIf="otherFee.touched && otherFee.invalid">
                                            <mat-error *ngIf="otherFee.errors.required">Other Fee is required</mat-error>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>

                            <!-- <div class="row">
                                <div class="col">
                                    <p>{{ codeTypes[item?.code_type].name }}/{{ item?.cpt_code }}</p>
                                </div>
                                <div class="col text-right">
                                    <a href="javascript:void(0)" (click)="viewServiceDetailDialog(item)">View details</a>
                                </div>
                            </div>
                            <div class="d-flex justify-content-center text-center">
                                <div class="border border-dark rounded-left">
                                    <p class="m-0 p-1 text-white font-weight-bold" style="background-color: #00bb2d;">{{ item?.std_price_ip || 0 | currency:'USD' }}</p>
                                    <hr class="m-0"/>
                                    <p class="m-0 px-1" style="color: #00bb2d;font-size: smaller;">Standard price (IP)</p>
                                </div>
                                <div class="border border-dark rounded-right">
                                    <p class="m-0 p-1 text-white font-weight-bold" style="background-color: #00bb2d;">{{ item?.std_price_op || 0 | currency:'USD' }}</p>
                                    <hr class="m-0"/>
                                    <p class="m-0 px-1" style="color: #00bb2d;font-size: smaller;">Standard price (OP)</p>
                                </div>
                            </div> -->
                            <!-- <div class="mt-2">
                                <p>
                                    <mat-form-field appearance="fill">
                                        <mat-label>Category type</mat-label>
                                        <mat-select name="categoryType" ngModel #categoryType="ngModel" [(ngModel)]="item.department" required>
                                            <mat-option *ngFor="let item of optionalTestCategories" [value]="item?.masterID">{{ item?.name }}</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="categoryType.touched && categoryType.invalid">
                                            <mat-error *ngIf="categoryType.errors.required">Category type is required</mat-error>
                                        </mat-error>
                                    </mat-form-field>
                                </p>
                            </div> -->
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-template #noData>
                <p style="font-size: 16px;">
                    If there are additional shoppable services you'd like to include that were not a part of your original uploaded chargemaster,
                    <br/>
                    click the <span style="font-weight: 500;">+Add Shoppable Service</span> button above.
                </p>
            </ng-template>
            
            
        </mat-card-content>
        <mat-card-actions class="text-right p-2">
            <button mat-raised-button color="primary" type="button" (click)="submitParentServices()" [hidden]="addedChildrenServicesList.length == 0">Save details</button>
        </mat-card-actions>
    </mat-card>
</div>