import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-embedd',
  templateUrl: './embedd.component.html',
  styleUrls: ['./embedd.component.scss']
})
export class EmbeddComponent implements OnInit {

  constructor() { }

  code:string;

  ngOnInit(): void {
    this.code = localStorage.getItem('proNumber');
  }

  copyCode(): void {
    const iframeCodeElement = document.querySelector('#codeBlock code') as HTMLElement;
    const iframeCode = iframeCodeElement.innerText;
    navigator.clipboard.writeText(iframeCode).then(() => {
      console.log('Copying to clipboard was successful!');
    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  }

}
