<div class="container-fluid" style="min-height: 50vh;" *ngIf="toggler">
    <button class="my-3" mat-stroked-button type="button" style="vertical-align: middle;" (click)="backToPreviousPage()">
        <mat-icon class="mr-2">keyboard_backspace</mat-icon>
        <span>GO Back</span>
    </button>
    <ng-container *ngIf="ruleEngineList.length > 0">
        <div class="my-2 d-flex align-items-center">
            <div class="flex mr-5">
                <div class="arrow_box1 px-2">
                    <p class="fw-5">CMS Compliance Status</p>
                </div>
            </div>
            <div class="w-50">
                <p-timeline [value]="ruleEngineList" layout="horizontal" styleClass="customized-timeline">
                    <ng-template pTemplate="marker" let-event>
                        <span class="custom-marker p-2" [style.background-color]="event.status ? '#20b04b' : '#ea1d25'" [matTooltip]="event?.title">
                            <mat-icon>{{event.status ? 'check' : 'close'}}</mat-icon>
                        </span>
                    </ng-template>
                    <ng-template pTemplate="content" let-event></ng-template>
                </p-timeline>
            </div>
            <!-- <div class="flex-grow-1 text-right">
                <button mat-flat-button class="bg-custom-blue text-white" [routerLink]="['/provider-detail', providerNumber]">View Provider Detail Preview</button>
            </div> -->
        </div>
    
        <div class="pb-5">
            <div class="text-right mb-2" *ngIf="!ruleID">
                <button mat-button [useExistingCss]="true" printTitle="Zea Compliance Engine" printSectionId="compliance-engine-view" ngxPrint>
                    <span>Print</span>
                    <mat-icon class="ml-2">print</mat-icon>
                </button>
            </div>
            <div>
                <div class="my-4 container" style="max-width: 960px;">
                    <ng-container *ngIf="ruleID">
                        <div class="d-flex align-items-center justify-content-between">
                            <p class="h6 m-0">ID: #{{ruleID}}</p>
                            <p class="h6 m-0">Executed On: {{ruleEngineList[0].updatedAt | date:'medium'}}</p>
                            <button mat-button [useExistingCss]="true" printTitle="Zea Compliance Engine" printSectionId="compliance-engine-view" ngxPrint>
                                <span>Print</span>
                                <mat-icon class="ml-2">print</mat-icon>
                            </button>
                        </div>
                        <hr/>
                    </ng-container>
                    
                    <ng-container *ngIf="checkIsCompliance() == true">
                        <p class="h5 fw-6 custom-primary text-center" style="line-height: 1.6;">
                            Great job! Your hospital is CMS compliant. Learn more about how you can use this compliance to increase revenue.
                        </p>
                    </ng-container>
                    <ng-container *ngIf="checkIsCompliance() == false">
                        <p class="h5 fw-6 custom-danger text-center" style="line-height: 1.6;">
                            Based on our scan, it looks like your hospital is not CMS compliant. Here's a list of the reasons why your hospital is not compliant: We can help you become CMS compliant.
                        </p>
                    </ng-container>
                </div>
                <mat-accordion multi>
                    <mat-expansion-panel class="mb-3" *ngFor="let item of ruleEngineList; index as i" [expanded]="true">
                        <mat-expansion-panel-header [ngClass]="[item?.status ? 'disable-ripple-success' : 'disable-ripple-failure']">
                            <mat-panel-title class="text-white">
                                <div class="row align-items-center w-100">
                                    <div class="col-md-6 fw-5 fs-18">{{item?.title}}</div>
                                    <div class="col-md-4 fw-5 fs-16">{{getSuccessCount(item)[0]}} / {{getSuccessCount(item)[1]}} passed</div>
                                    <div class="col-md-2" *ngIf="i > 1">
                                        <button mat-stroked-button (click)="skipRules(item)">Skip</button>
                                    </div>
                                </div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="mt-4">
                            <ng-container *ngFor="let item1 of item?.subSections; index as i">
                                <p class="h4 fw-6 custom-primary-dark">{{item1?.subHeading}}</p>
                                <div class="ruleSection mb-4" *ngFor="let item2 of item1?.rules">
                                    <div class="row">
                                        <div class="col-md-9">
                                            <div class="d-flex">
                                                <!-- <div class="flex-shrink-1 mr-4">{{item2?.number}}.</div> -->
                                                <div class="w-100">
                                                    <p class="fw-5" style="line-height: 1.6;">{{item2?.title}}</p>
                                                    <!-- <p class="text-muted font-italic" style="line-height: 1.6;">{{item2?.description}}</p> -->
                                                    <!-- <p *ngIf="!item2?.status" class="custom-danger" style="line-height: 1.6;">{{item2?.errorText}}</p>
                                                    <p *ngIf="item2?.status && item2?.manualFix" class="custom-primary" style="line-height: 1.6;">{{item2?.errorText}}</p> -->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="row align-items-center">
                                                <ng-container *ngIf="item2?.status == false && item2?.isnotsure == true; else anotherSection">
                                                    <div class="col">
                                                        <div class="custom-warning">
                                                            <span class="mr-2 fw-6">Not Sure</span>
                                                            <i class="fw-6 pi pi-exclamation-triangle" style="vertical-align: middle;"></i>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <button mat-stroked-button (click)="openUpdateRuleEngineDialog(item2)">
                                                            <span class="mr-2">Fix It</span>
                                                            <i class="custom-warning pi pi-exclamation-circle"></i>
                                                        </button>
                                                    </div>
                                                </ng-container>
                                                <ng-template #anotherSection>
                                                    <div class="col">
                                                        <div [ngClass]="[item2?.status ? 'custom-primary' : 'custom-danger']">
                                                            <span class="mr-2 fw-6">{{item2?.status ? 'Yes' : 'No'}}</span>
                                                            <i class="fw-6" [ngClass]="[item2?.status ? 'pi pi-check-circle' : 'pi pi-times-circle']" style="vertical-align: middle;"></i>
                                                        </div>
                                                    </div>
                                                    <div class="col" *ngIf="!item2?.status && !item2?.manualFix">
                                                        <button mat-stroked-button (click)="openUpdateRuleEngineDialog(item2)">
                                                            <span class="mr-2">Fix It</span>
                                                            <i class="custom-warning pi pi-exclamation-circle"></i>
                                                        </button>
                                                    </div>
                                                </ng-template>
                                                <!-- <div class="col">
                                                    <div [ngClass]="[item2?.status ? 'custom-primary' : 'custom-danger']">
                                                        <span class="mr-2 fw-6">{{item2?.status ? 'Yes' : 'No'}}</span>
                                                        <i class="fw-6" [ngClass]="[item2?.status ? 'pi pi-check-circle' : 'pi pi-times-circle']" style="vertical-align: middle;"></i>
                                                    </div>
                                                </div>
                                                <div class="col" *ngIf="!item2?.status && !item2?.manualFix">
                                                    <button mat-stroked-button (click)="openUpdateRuleEngineDialog(item2)">
                                                        <span class="mr-2">Fix It</span>
                                                        <i class="custom-warning pi pi-exclamation-circle"></i>
                                                    </button>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>

            <div id="compliance-engine-view" class="d-none">
                <div class="bg-white">
                    <div class="mb-4 container">
                        <div class="mb-4 d-flex align-items-center justify-content-between">
                            <div>
                                <p class="m-0 h3 fw-6">{{httpCall.getProName()}}</p>
                                <p class="h6">{{httpCall.getProLocation()}}</p>
                            </div>
                            <div>
                                <img src="assets/img/zea-tool-logo.png" alt="ZeaMed Logo" style="width: 200px;">
                            </div>
                        </div>
                        <div class="my-2 d-flex align-items-center">
                            <div class="flex mr-5">
                                <div class="arrow_box1 px-2">
                                    <p class="fw-5">CMS Compliance Status</p>
                                </div>
                            </div>
                            <div class="w-50">
                                <p-timeline [value]="ruleEngineList" layout="horizontal" styleClass="customized-timeline">
                                    <ng-template pTemplate="marker" let-event>
                                        <span class="custom-marker p-2" [style.background-color]="event.status ? '#20b04b' : '#ea1d25'">
                                            <mat-icon>{{event.status ? 'check' : 'close'}}</mat-icon>
                                        </span>
                                    </ng-template>
                                    <ng-template pTemplate="content" let-event></ng-template>
                                </p-timeline>
                            </div>
                        </div>
                        <ng-container *ngIf="checkIsCompliance() == true">
                            <p class="h5 fw-6 custom-primary text-center" style="line-height: 1.6;">
                                Great job! Your hospital is CMS compliant. Learn more about how you can use this compliance to increase revenue.
                            </p>
                        </ng-container>
                        <ng-container *ngIf="checkIsCompliance() == false">
                            <p class="h5 fw-6 custom-danger text-center" style="line-height: 1.6;">
                                Based on our scan, it looks like your hospital is not CMS compliant. Here's a list of the reasons why not: We can help you become CMS compliant in as little as one day.
                            </p>
                        </ng-container>
                    </div>
                    <mat-accordion multi>
                        <mat-expansion-panel class="mb-3" *ngFor="let item of ruleEngineList" [expanded]="true">
                            <mat-expansion-panel-header [ngClass]="[item?.status ? 'disable-ripple-success' : 'disable-ripple-failure']">
                                <mat-panel-title class="text-white">
                                    <div class="row w-100">
                                        <div class="col-md-8 fw-5 fs-18">{{item?.title}}</div>
                                        <div class="col-md-4 fw-5 fs-16">{{getSuccessCount(item)[0]}} / {{getSuccessCount(item)[1]}} passed</div>
                                    </div>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="mt-4">
                                <ng-container *ngFor="let item1 of item?.subSections; index as i">
                                    <p class="h4 fw-6 custom-primary-dark">{{item1?.subHeading}}</p>
                                    <div class="ruleSection mb-4" *ngFor="let item2 of item1?.rules">
                                        <div class="row">
                                            <div class="col-md-9">
                                                <div class="d-flex">
                                                    <!-- <div class="flex-shrink-1 mr-4">{{item2?.number}}.</div> -->
                                                    <div class="w-100">
                                                        <p class="fw-5" style="line-height: 1.6;">{{item2?.title}}</p>
                                                        <!-- <p class="text-muted font-italic" style="line-height: 1.6;">{{item2?.description}}</p> -->
                                                        <!-- <p *ngIf="!item2?.status" class="custom-danger" style="line-height: 1.6;">{{item2?.errorText}}</p>
                                                        <p *ngIf="item2?.status && item2?.manualFix" class="custom-primary" style="line-height: 1.6;">{{item2?.errorText}}</p> -->
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="row align-items-center">
                                                    <ng-container *ngIf="item2?.status == false && item2?.isnotsure == true; else anotherSection">
                                                        <div class="custom-warning">
                                                            <span class="mr-2 fw-6">Not Sure</span>
                                                            <i class="fw-6 pi pi-exclamation-triangle" style="vertical-align: middle;"></i>
                                                        </div>
                                                    </ng-container>
                                                    <ng-template #anotherSection>
                                                        <div class="col">
                                                            <div [ngClass]="[item2?.status ? 'custom-primary' : 'custom-danger']">
                                                                <span class="mr-2 fw-6">{{item2?.status ? 'Yes' : 'No'}}</span>
                                                                <i class="fw-6" [ngClass]="[item2?.status ? 'pi pi-check-circle' : 'pi pi-times-circle']" style="vertical-align: middle;"></i>
                                                            </div>
                                                        </div>
                                                        <div class="col" *ngIf="!item2?.status && !item2?.manualFix">
                                                            <button mat-stroked-button (click)="openUpdateRuleEngineDialog(item2)">
                                                                <span class="mr-2">Fix It</span>
                                                                <i class="custom-warning pi pi-exclamation-circle"></i>
                                                            </button>
                                                        </div>
                                                    </ng-template>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>
        </div>
    </ng-container>
</div>

<div class="container-fluid" style="min-height: 50vh;" *ngIf="!toggler">
    <button class="my-3" mat-stroked-button type="button" style="vertical-align: middle;" (click)="backToPreviousPage()">
        <mat-icon class="mr-2">keyboard_backspace</mat-icon>
        <span>GO Back</span>
    </button>
    <ng-container *ngIf="ruleEngineList.length > 0">
        <div class="my-2 d-flex align-items-center">
            <div class="flex mr-5">
                <div class="arrow_box1 px-2">
                    <p class="fw-5">CMS Compliance Status</p>
                </div>
            </div>
            <div class="w-50">
                <p-timeline [value]="ruleEngineList" layout="horizontal" styleClass="customized-timeline">
                    <ng-template pTemplate="marker" let-event>
                        <span class="custom-marker p-2" [style.background-color]="event.status ? '#20b04b' : '#ea1d25'" [matTooltip]="event?.title">
                            <mat-icon>{{event.status ? 'check' : 'close'}}</mat-icon>
                        </span>
                    </ng-template>
                    <ng-template pTemplate="content" let-event></ng-template>
                </p-timeline>
            </div>
            <!-- <div class="flex-grow-1 text-right">
                <button mat-flat-button class="bg-custom-blue text-white" [routerLink]="['/provider-detail', providerNumber]">View Provider Detail Preview</button>
            </div> -->
        </div>
    
        <div class="pb-5">
            <div class="text-right mb-2" *ngIf="!ruleID">
                <button mat-button [useExistingCss]="true" printTitle="Zea Compliance Engine" printSectionId="compliance-engine-view" ngxPrint>
                    <span>Print</span>
                    <mat-icon class="ml-2">print</mat-icon>
                </button>
            </div>
            <div>
                <div class="my-4 container" style="max-width: 960px;">
                    <ng-container *ngIf="ruleID">
                        <div class="d-flex align-items-center justify-content-between">
                            <p class="h6 m-0">ID: #{{ruleID}}</p>
                            <p class="h6 m-0">Executed On: {{ruleEngineList[0].updatedAt | date:'medium'}}</p>
                            <button mat-button [useExistingCss]="true" printTitle="Zea Compliance Engine" printSectionId="compliance-engine-view" ngxPrint>
                                <span>Print</span>
                                <mat-icon class="ml-2">print</mat-icon>
                            </button>
                        </div>
                        <hr/>
                    </ng-container>
                    
                    <ng-container *ngIf="checkIsCompliance() == true">
                        <p class="h5 fw-6 custom-primary text-center" style="line-height: 1.6;">
                            Great job! Your hospital is CMS compliant. Learn more about how you can use this compliance to increase revenue.
                        </p>
                    </ng-container>
                    <ng-container *ngIf="checkIsCompliance() == false">
                        <p class="h5 fw-6 custom-danger text-center" style="line-height: 1.6;">
                            Based on our scan, it looks like your hospital is not CMS compliant. Here's a list of the reasons why your hospital is not compliant: We can help you become CMS compliant.
                        </p>
                    </ng-container>
                </div>
                <mat-accordion multi>
                    <mat-expansion-panel class="mb-3" *ngFor="let item of ruleEngineList; index as i" [expanded]="true">
                        <mat-expansion-panel-header [ngClass]="[item?.status ? 'disable-ripple-success' : 'disable-ripple-failure']">
                            <mat-panel-title class="text-white">
                                <div class="row align-items-center w-100">
                                    <div class="col-md-6 fw-5 fs-18">{{item?.title}}</div>
                                    <div class="col-md-4 fw-5 fs-16">{{getSuccessCount(item)[0]}} / {{getSuccessCount(item)[1]}} passed</div>
                                    <div class="col-md-2" *ngIf="i > 1">
                                        <button mat-stroked-button (click)="skipRules(item)">Skip</button>
                                    </div>
                                </div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="mt-4">
                            <ng-container *ngFor="let item1 of item?.subSections; index as i">
                                <p class="h4 fw-6 custom-primary-dark">{{item1?.subHeading}}</p>
                                <div class="ruleSection mb-4" *ngFor="let item2 of item1?.rules">
                                    <div class="row">
                                        <div class="col-md-9">
                                            <div class="d-flex">
                                                <!-- <div class="flex-shrink-1 mr-4">{{item2?.number}}.</div> -->
                                                <div class="w-100">
                                                    <p class="fw-5" style="line-height: 1.6;">{{item2?.title}}</p>
                                                    <!-- <p class="text-muted font-italic" style="line-height: 1.6;">{{item2?.description}}</p> -->
                                                    <!-- <p *ngIf="!item2?.status" class="custom-danger" style="line-height: 1.6;">{{item2?.errorText}}</p>
                                                    <p *ngIf="item2?.status && item2?.manualFix" class="custom-primary" style="line-height: 1.6;">{{item2?.errorText}}</p> -->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="row align-items-center">
                                                <ng-container *ngIf="item2?.status == false && item2?.isnotsure == true; else anotherSection">
                                                    <div class="col">
                                                        <div class="custom-warning">
                                                            <span class="mr-2 fw-6">Not Sure</span>
                                                            <i class="fw-6 pi pi-exclamation-triangle" style="vertical-align: middle;"></i>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <button mat-stroked-button (click)="openUpdateRuleEngineDialog(item2)">
                                                            <span class="mr-2">Fix It</span>
                                                            <i class="custom-warning pi pi-exclamation-circle"></i>
                                                        </button>
                                                    </div>
                                                </ng-container>
                                                <ng-template #anotherSection>
                                                    <div class="col">
                                                        <div [ngClass]="[item2?.status ? 'custom-primary' : 'custom-danger']">
                                                            <span class="mr-2 fw-6">{{item2?.status ? 'Yes' : 'No'}}</span>
                                                            <i class="fw-6" [ngClass]="[item2?.status ? 'pi pi-check-circle' : 'pi pi-times-circle']" style="vertical-align: middle;"></i>
                                                        </div>
                                                    </div>
                                                    <div class="col" *ngIf="!item2?.status && !item2?.manualFix">
                                                        <button mat-stroked-button (click)="openUpdateRuleEngineDialog(item2)">
                                                            <span class="mr-2">Fix It</span>
                                                            <i class="custom-warning pi pi-exclamation-circle"></i>
                                                        </button>
                                                    </div>
                                                </ng-template>
                                                <!-- <div class="col">
                                                    <div [ngClass]="[item2?.status ? 'custom-primary' : 'custom-danger']">
                                                        <span class="mr-2 fw-6">{{item2?.status ? 'Yes' : 'No'}}</span>
                                                        <i class="fw-6" [ngClass]="[item2?.status ? 'pi pi-check-circle' : 'pi pi-times-circle']" style="vertical-align: middle;"></i>
                                                    </div>
                                                </div>
                                                <div class="col" *ngIf="!item2?.status && !item2?.manualFix">
                                                    <button mat-stroked-button (click)="openUpdateRuleEngineDialog(item2)">
                                                        <span class="mr-2">Fix It</span>
                                                        <i class="custom-warning pi pi-exclamation-circle"></i>
                                                    </button>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>

            <div id="compliance-engine-view" class="d-none">
                <div class="bg-white">
                    <div class="mb-4 container">
                        <div class="mb-4 d-flex align-items-center justify-content-between">
                            <div>
                                <p class="m-0 h3 fw-6">{{httpCall.getProName()}}</p>
                                <p class="h6">{{httpCall.getProLocation()}}</p>
                            </div>
                            <div>
                                <img src="assets/img/zea-tool-logo.png" alt="ZeaMed Logo" style="width: 200px;">
                            </div>
                        </div>
                        <div class="my-2 d-flex align-items-center">
                            <div class="flex mr-5">
                                <div class="arrow_box1 px-2">
                                    <p class="fw-5">CMS Compliance Status</p>
                                </div>
                            </div>
                            <div class="w-50">
                                <p-timeline [value]="ruleEngineList" layout="horizontal" styleClass="customized-timeline">
                                    <ng-template pTemplate="marker" let-event>
                                        <span class="custom-marker p-2" [style.background-color]="event.status ? '#20b04b' : '#ea1d25'">
                                            <mat-icon>{{event.status ? 'check' : 'close'}}</mat-icon>
                                        </span>
                                    </ng-template>
                                    <ng-template pTemplate="content" let-event></ng-template>
                                </p-timeline>
                            </div>
                        </div>
                        <ng-container *ngIf="checkIsCompliance() == true">
                            <p class="h5 fw-6 custom-primary text-center" style="line-height: 1.6;">
                                Great job! Your hospital is CMS compliant. Learn more about how you can use this compliance to increase revenue.
                            </p>
                        </ng-container>
                        <ng-container *ngIf="checkIsCompliance() == false">
                            <p class="h5 fw-6 custom-danger text-center" style="line-height: 1.6;">
                                Based on our scan, it looks like your hospital is not CMS compliant. Here's a list of the reasons why not: We can help you become CMS compliant in as little as one day.
                            </p>
                        </ng-container>
                    </div>
                    <mat-accordion multi>
                        <mat-expansion-panel class="mb-3" *ngFor="let item of ruleEngineList" [expanded]="true">
                            <mat-expansion-panel-header [ngClass]="[item?.status ? 'disable-ripple-success' : 'disable-ripple-failure']">
                                <mat-panel-title class="text-white">
                                    <div class="row w-100">
                                        <div class="col-md-8 fw-5 fs-18">{{item?.title}}</div>
                                        <div class="col-md-4 fw-5 fs-16">{{getSuccessCount(item)[0]}} / {{getSuccessCount(item)[1]}} passed</div>
                                    </div>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="mt-4">
                                <ng-container *ngFor="let item1 of item?.subSections; index as i">
                                    <p class="h4 fw-6 custom-primary-dark">{{item1?.subHeading}}</p>
                                    <div class="ruleSection mb-4" *ngFor="let item2 of item1?.rules">
                                        <div class="row">
                                            <div class="col-md-9">
                                                <div class="d-flex">
                                                    <!-- <div class="flex-shrink-1 mr-4">{{item2?.number}}.</div> -->
                                                    <div class="w-100">
                                                        <p class="fw-5" style="line-height: 1.6;">{{item2?.title}}</p>
                                                        <!-- <p class="text-muted font-italic" style="line-height: 1.6;">{{item2?.description}}</p> -->
                                                        <!-- <p *ngIf="!item2?.status" class="custom-danger" style="line-height: 1.6;">{{item2?.errorText}}</p>
                                                        <p *ngIf="item2?.status && item2?.manualFix" class="custom-primary" style="line-height: 1.6;">{{item2?.errorText}}</p> -->
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="row align-items-center">
                                                    <ng-container *ngIf="item2?.status == false && item2?.isnotsure == true; else anotherSection">
                                                        <div class="custom-warning">
                                                            <span class="mr-2 fw-6">Not Sure</span>
                                                            <i class="fw-6 pi pi-exclamation-triangle" style="vertical-align: middle;"></i>
                                                        </div>
                                                    </ng-container>
                                                    <ng-template #anotherSection>
                                                        <div class="col">
                                                            <div [ngClass]="[item2?.status ? 'custom-primary' : 'custom-danger']">
                                                                <span class="mr-2 fw-6">{{item2?.status ? 'Yes' : 'No'}}</span>
                                                                <i class="fw-6" [ngClass]="[item2?.status ? 'pi pi-check-circle' : 'pi pi-times-circle']" style="vertical-align: middle;"></i>
                                                            </div>
                                                        </div>
                                                        <div class="col" *ngIf="!item2?.status && !item2?.manualFix">
                                                            <button mat-stroked-button (click)="openUpdateRuleEngineDialog(item2)">
                                                                <span class="mr-2">Fix It</span>
                                                                <i class="custom-warning pi pi-exclamation-circle"></i>
                                                            </button>
                                                        </div>
                                                    </ng-template>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>
        </div>
    </ng-container>
</div>