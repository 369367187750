<div class="text-right">
    <button mat-stroked-button color="primary" [disabled]="docInsuranceList.length == 0" (click)="openPhysicianServiceDialog(true)">
        <span>Add physician service</span>
        <mat-icon>add</mat-icon>
    </button>
</div>

<div class="my-4">
    <mat-accordion>
        <mat-expansion-panel class="card" [expanded]="panelExpanded()" style="box-shadow: none;">
            <mat-expansion-panel-header>
                <mat-panel-title>CSV download and upload</mat-panel-title>
                <mat-panel-description>
                  <!-- Type your name and age -->
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="row align-items-center">
                <div class="col-md-6">
                    <div class="text-center">
                        <div>
                            <mat-icon class="text-secondary" style="font-size: 60px;width: 60px;height: 60px;">cloud_download</mat-icon>
                        </div>
                        <button mat-raised-button color="primary" (click)="downloadPhysicianServiceTemplate()">Download physician service csv</button>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card-header">Please note:</div>
                    <div class="card-body" style="font-size: 12px;">
                        <p>The information must be published in a single digital file that is in a machine-readable format. we accept the following file types: XML, JSON, CSV, Excel</p>
                        <p>Data must be updated at least annually. Don't worry! We'll remind you when a mandatory update is due.</p>
                        <mat-checkbox color="primary">I have read and agree to <a href="#">ZeaTool's terms and policies</a></mat-checkbox>
                        <div class="text-center">
                            <div>
                                <mat-icon class="text-secondary" style="font-size: 60px;width: 60px;height: 60px;">cloud_upload</mat-icon>
                            </div>
                            <button mat-raised-button color="primary" (click)="physicianServiceFileInput.click()">
                                <input #physicianServiceFileInput type="file" accept="csv/*" (change)="onPhysicianServiceCSVUpload($event)" style="display:none;" />
                                <span>Upload physician service csv</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>

<p-treeTable [value]="physicianServicesList" [columns]="physicianServiceCols" (onNodeExpand)="onNodeExpand($event)" class="card table-responsive">
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns" style="width: 200px;">
                {{col.header}}
            </th>
            <th style="width: 8rem">Actions</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
        <tr>
            <td *ngFor="let col of columns; let i = index">
                <p-treeTableToggler [rowNode]="rowNode" *ngIf="i == 0"></p-treeTableToggler>
                <span>{{rowData[col.field]}}</span>
            </td>
            <td>
                <button mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="openPhysicianServiceDialog(false, rowData)">Edit</button>
                    <button mat-menu-item>Delete</button>
                    <button [hidden]="!rowData?.is_bundled" mat-menu-item (click)="openPhysicianServiceDialog(true, rowData, true)">Add sub item</button>
                </mat-menu>
            </td>
        </tr>            
    </ng-template>
</p-treeTable>