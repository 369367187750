import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpCallService } from 'src/app/services/http-call.service';
import { environment } from 'src/environments/environment.prod';
import * as _ from 'underscore';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment'
import { SpinnerService } from 'src/app/services/spinner.service';

interface DialogData {
  isNew: boolean;
  proID: string;
  empID: string;
  physicianObj?: any;
}

@Component({
  selector: 'app-add-edit-physician',
  templateUrl: './add-edit-physician.component.html',
  styleUrls: ['./add-edit-physician.component.scss']
})
export class AddEditPhysicianComponent implements OnInit {

  doctorObj = {
    doctorID: '',
    firstName: '',
    lastName: '',
    middleName: '',
    pacId: '',
    groupName: '',
    speciality: [],
    experience: null,
    avatar: '',
    gender: '',
    suffix: '',
    npi: '',
    description: '',
    in_hospital: false,
    providerId: ''
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<AddEditPhysicianComponent>,
    private snackbar: MatSnackBar,
    private spinner: SpinnerService,
    private httpCall: HttpCallService
  ) { }

  ngOnInit() {
    if(!this.data.isNew) {
      this.getSinglePhysicianDetails();
    }
    this.getSpecialities();
  }

  getSinglePhysicianDetails() {
    let body = {
      providerId: this.data.proID,
      doctorId: this.data.physicianObj._id
    };
    this.httpCall.postAuth(`${environment.providerDomain}/provider/getDoctor`, body).subscribe( res => {
      if(res['success']) {
        this.doctorObj.doctorID = res['response'][0]._id;
        this.doctorObj.firstName = res['response'][0].firstName;
        this.doctorObj.lastName = res['response'][0].lastName;
        this.doctorObj.middleName = res['response'][0].middleName;
        this.doctorObj.speciality = res['response'][0].speciality;
        this.doctorObj.experience = new Date(res['response'][0].experience);
        this.doctorObj.avatar = res['response'][0].avatar;
        if(res['response'][0].gender == 0) {
          this.doctorObj.gender = '0';
        } else {
          this.doctorObj.gender = res['response'][0].gender.toString();
        }
        this.doctorObj.suffix = res['response'][0].suffix;
        this.doctorObj.npi = res['response'][0].npi;
        this.doctorObj.pacId = res['response'][0].pacId;
        this.doctorObj.groupName = res['response'][0].groupName;
        this.doctorObj.description = res['response'][0].description;
        this.doctorObj.in_hospital = res['response'][0].is_hospital == 1 ? true : false;
      } else {
        this.snackbar.open(res['message'], 'Failed', {
          duration: 5000,
          panelClass: 'snackBar-failure'
        });
      }
    }, (err) => {
      this.snackbar.open(err.error['message'], 'Failed', {
        duration: 5000,
        panelClass: 'snackBar-failure'
      });
    });
  }

  specialitiesList: any = [];
  getSpecialities() {
    this.httpCall.get(`${environment.providerDomain}/BackEnd/speciality`).subscribe( res => {
      if(res['success']) {
        this.specialitiesList = res['response'];
      } else {
        this.snackbar.open(res['message'], 'Failed', {
          duration: 5000,
          panelClass: 'snackBar-failure'
        });
      }
    }, (err) => {
      this.snackbar.open(err.error['message'], 'Failed', {
        duration: 5000,
        panelClass: 'snackBar-failure'
      });
    });
  }

  submitPhysicianDetails(addDocData) {
    let body;
    const experienceDate = new Date(addDocData.experience);
    // const datestr = experienceDate.getMonth()+'/'+experienceDate.getDate() +'/'+experienceDate.getFullYear();
    const datestr = moment(experienceDate).format('MM/DD/YYYY');
    
    if(this.data.isNew) {
      body = {
        firstName: addDocData.firstName,
        lastName: addDocData.lastName,
        middleName: addDocData.middleName,
        speciality: addDocData.speciality,
        experience: datestr,
        avatar: addDocData.avatar,
        gender: addDocData.gender,
        suffix: addDocData.suffix,
        npi: addDocData.npi,
        pacId: addDocData.pacId,
        groupName: addDocData.groupName,
        description: addDocData.description,
        is_hospital: addDocData.in_hospital ? 1 : 2,
        providerId: this.data.proID
      };
      this.spinner.showSpinner('Loading...Please Wait');
      this.httpCall.postAuth(`${environment.providerDomain}/providerApi/addNewDoctor`, body).subscribe( res => {
        this.spinner.stopSpinner();
        if(res['success']) {
          this.snackbar.open(res['message'], 'Success', {
            duration: 5000,
            panelClass: 'snackBar-success'
          });
          this.dialogRef.close(res);
        } else {
          this.snackbar.open(res['message'], 'Failed', {
            duration: 5000,
            panelClass: 'snackBar-failure'
          });
        }
      }, (err) => {
        this.spinner.stopSpinner();
        this.snackbar.open(err.error['message'], 'Failed', {
          duration: 5000,
          panelClass: 'snackBar-failure'
        });
      });
    } else {
      body = {
        doctorId: addDocData.doctorID,
        firstName: addDocData.firstName,
        lastName: addDocData.lastName,
        middleName: addDocData.middleName,
        speciality: addDocData.speciality,
        experience: datestr,
        avatar: addDocData.avatar,
        gender: addDocData.gender,
        suffix: addDocData.suffix,
        npi: addDocData.npi,
        pacId: addDocData.pacId,
        groupName: addDocData.groupName,
        description: addDocData.description,
        is_hospital: addDocData.in_hospital ? 1 : 2,
        providerId: this.data.proID,
        updatedBy: this.data.empID
      };
      this.spinner.showSpinner('Loading...Please Wait');
      this.httpCall.putAuth(`${environment.providerDomain}/providerApi/updateDoctor`, body).subscribe( res => {
        this.spinner.stopSpinner();
        if(res['success']) {
          this.snackbar.open(res['message'], 'Success', {
            duration: 5000,
            panelClass: 'snackBar-success'
          });
          this.dialogRef.close(res);
        } else {
          this.snackbar.open(res['message'], 'Failed', {
            duration: 5000,
            panelClass: 'snackBar-failure'
          });
        }
      }, (err) => {
        this.spinner.stopSpinner();
        this.snackbar.open(err.error['message'], 'Failed', {
          duration: 5000,
          panelClass: 'snackBar-failure'
        });
      });
    }
  }

}
