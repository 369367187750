import { Injectable } from '@angular/core';
import { MatSpinner } from '@angular/material/progress-spinner';
import { Overlay } from '@angular/cdk/overlay';
import { Subject } from 'rxjs';
import { mapTo, scan, map, mergeMap } from 'rxjs/operators'
import { ComponentPortal } from '@angular/cdk/portal';
import { MatDialog } from '@angular/material/dialog';
import { CommonSpinnerComponent } from '../shared/common-spinner/common-spinner.component';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  private spinnerTopRef = this.cdkSpinnerCreate();
  spin$: Subject<boolean> = new Subject()
  constructor(private overlay: Overlay,private dialog: MatDialog) {
    this.spin$
      .asObservable()
      .pipe(
        map(val => {
          if (val) {
            return 1
          } else {
            return -1
          }
        }),
        scan((acc, one) => (acc + one) >= 0 ? acc + one : 0, 0)
      )
      .subscribe(
        (res) => {
          if (res === 1) { this.showSpinner("Please wait...") }
          else if (res == 0) {
            this.spinnerTopRef.hasAttached() ? this.stopSpinner() : null;
          }
        }
      );
    // console.log(this.spin$);

  }

  showSpinner(message:string) {
    this.dialog.open(CommonSpinnerComponent,{panelClass:'custom-dialog-container',width:'300px',height:'200px',data: {message:message},disableClose: true})
 }

  stopSpinner() {
    this.dialog.closeAll();
    //this.spinnerTopRef.detach();
  }

  //Create a Overlay Layer
  private cdkSpinnerCreate() {
    return this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'dark-backdrop',
      positionStrategy: this.overlay.position()
        .global()
        .centerHorizontally()
        .centerVertically()
    })
  }

}