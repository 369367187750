import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-zeacard-gallery',
  templateUrl: './zeacard-gallery.component.html',
  styleUrls: ['./zeacard-gallery.component.scss']
})
export class ZeacardGalleryComponent implements OnInit {

  cartItems:any = [];
  responsiveOptions: any[] = [
    {
      breakpoint: '1024px',
      numVisible: 5
    },
    {
      breakpoint: '768px',
      numVisible: 3
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];

  @Input() galleryImgList:any

  constructor() {}

  ngOnInit(): void {
    console.log("received",this.galleryImgList)
  }

}
