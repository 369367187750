import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpCallService } from 'src/app/services/http-call.service';
import { environment } from 'src/environments/environment.prod';
import * as _ from 'underscore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { SpinnerService } from 'src/app/services/spinner.service';

interface DialogData {
  physicianObj?: any;
  proThemes?: any;
}

@Component({
  selector: 'app-view-physician-detail',
  templateUrl: './view-physician-detail.component.html',
  styleUrls: ['./view-physician-detail.component.scss']
})
export class ViewPhysicianDetailComponent implements OnInit {

  proThemes;
  physicianObj: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<ViewPhysicianDetailComponent>,
    private snackbar: MatSnackBar,
    private spinner: SpinnerService,
    private httpCall: HttpCallService,
    public activatedRoute: ActivatedRoute,
  ) { 
    if(data != null) {
      this.physicianObj = data.physicianObj;
      if(!this.physicianObj.avatar) {
        if(this.physicianObj.gender == 1) {
          this.physicianObj.avatar = 'assets/img/doctor-male.svg';
        } else if(this.physicianObj.gender == 2) {
          this.physicianObj.avatar = 'assets/img/doctor-female.svg'
        } else {
          this.physicianObj.avatar = 'assets/img/hospital-building.svg';
        }
      }
      this.proThemes = data.proThemes;
    }
  }

  ngOnInit(): void {

  }

  hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, 0.2)` : null;
  }

  calcExp(dateStr: Date) {
    const expDate = new Date(dateStr);
    const dateNow = new Date();
    if ( expDate ) {
      return dateNow.getFullYear() - expDate.getFullYear();
    } else {
      return '0';
    }
  }

}
