import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { Inject } from '@angular/core';
import { MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ThemePalette} from '@angular/material/core';

@Component({
  selector: 'app-common-spinner',
  templateUrl: './common-spinner.component.html',
  styleUrls: ['./common-spinner.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CommonSpinnerComponent implements OnInit {
  @Input() message: string;
  color: ThemePalette = 'primary';
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.message = this.data.message;
  }

  ngOnInit(): void {
  }

}
