import { Component, OnInit, Input } from '@angular/core';
import { HttpCallService } from 'src/app/services/http-call.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment.prod';
import { AddEditCmInsurancePricesComponent } from '../add-edit-cm-insurance-prices/add-edit-cm-insurance-prices.component';
import { SpinnerService } from 'src/app/services/spinner.service';
import { EChartOption } from 'echarts';

@Component({
  selector: 'app-view-cm-insurance-prices',
  templateUrl: './view-cm-insurance-prices.component.html',
  styleUrls: ['./view-cm-insurance-prices.component.scss']
})
export class ViewCmInsurancePricesComponent implements OnInit {

  codeTypes = environment.codeTypes;

  ipRes:boolean = false;
  pRes:boolean = false;

  pricesAvailable:boolean=false;

  constructor(
    public httpCall: HttpCallService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private snackbar: MatSnackBar,
    private spinner: SpinnerService,
    private location: Location
  ) {
    this.activatedRoute.params.subscribe(params => {
      if(params) {
        this.getSingleServiceData(this.activatedRoute.snapshot.params.serviceID);
        // this.getSingleServiceInsurancePricesData(params.mixedID);
      }
    });
  }

  backToPreviousPage() {
    this.location.back();
  }

  _selectedColumns: any[] = [];
  filterColumns: any[] = [];
  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  toggler=false;

  set selectedColumns(val: any[]) {
    //restore original order
    if(val.length != 0){
      this._selectedColumns = this.filterColumns.filter(col => val.includes(col));
      this.insurancePricesList = [...this._selectedColumns];
      this.setGraphValues();
    }else if (val.length == 0){
      this._selectedColumns = this.filterColumns.filter(col => val.includes(col));
      this.insurancePricesList = [...this._selectedColumns];
      this.setGraphValues();
    }
    else{
      this.toggler = !this.toggler;
    }
  }

  ngOnInit(): void {
    // this.getSingleServiceData(this.activatedRoute.snapshot.params.serviceID);
    // this.getSingleServiceInsurancePricesData(this.activatedRoute.snapshot.params.mixedID);
  }

  serviceImagePath: string;
  serviceItemObj: any = [];
  testPricesList: any = [];
  getSingleServiceData(serviceID) {
    this.spinner.showSpinner('Loading...Please Wait');
    this.httpCall.get(`${environment.providerDomain}/BackEnd/fetchNewSingleChargeMaster/${serviceID}`).subscribe(res => {
      this.pRes = true;
      this.spinner.stopSpinner();
      if (res['success']) {
        this.serviceItemObj = res['response'][0];
        this.serviceImagePath = this.serviceItemObj.isMandatory == true ? `https://zea-tool.s3.amazonaws.com/${this.serviceItemObj.cpt_code}.png` : 'assets/img/service-default.png';
        this.getSingleServiceInsurancePricesData(this.activatedRoute.snapshot.params.mixedID);
      }
    }, (err) => {
      this.spinner.stopSpinner();
      this.snackbar.open(err.error['message'], 'Failed', {
        duration: 5000,
        panelClass: 'snackBar-failure'
      });
    });
  }

  insurancePricesList: any = [];
  getSingleServiceInsurancePricesData(mixedID) {
    this.spinner.showSpinner('Loading...Please Wait');
    this.httpCall.get(`${environment.providerDomain}/BackEnd/getInsuranceInfo/${mixedID}`).subscribe(res => {
      this.ipRes = true;
      this.spinner.stopSpinner();
      if (res['success']) {
        this.testPricesList = [
          { carrier_name: 'GROSS PRICE', ip_price: this.serviceItemObj.std_price_ip, op_price: this.serviceItemObj.std_price_op },
          { carrier_name: 'CASH PRICE', ip_price: this.serviceItemObj.cash_price_ip, op_price: this.serviceItemObj.cash_price_op },
          { carrier_name: 'MIN PRICE', ip_price: this.serviceItemObj.min_price_ip, op_price: this.serviceItemObj.min_price_op },
          { carrier_name: 'MAX PRICE', ip_price: this.serviceItemObj.max_price_ip, op_price: this.serviceItemObj.max_price_op },
          { carrier_name: 'OUT OF NETWORK PRICE', ip_price: this.serviceItemObj.outofnetwork_ip, op_price: this.serviceItemObj.outofnetwork_op },
        ];
        this.insurancePricesList = [...res['response']];
        this.pricesAvailable = this.insurancePricesList.length > 0 ?  true : false;
        this.filterColumns = res['response'];
        this._selectedColumns = res['response'].slice(0, 5);
        this.setGraphValues();
      } else {
        this.insurancePricesList = [];
      }
    }, (err) => {
      this.spinner.stopSpinner();
      this.snackbar.open(err.error['message'], 'Failed', {
        duration: 5000,
        panelClass: 'snackBar-failure'
      });
    });
  }

  async openInsurancePricesDialog(selectedItem?: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.minWidth = '30vw';
    dialogConfig.data = {
      proID: localStorage.getItem('proID'),
      selectedItem: selectedItem
    };
    const dialogRef = this.dialog.open(AddEditCmInsurancePricesComponent, dialogConfig);
    dialogRef.beforeClosed().subscribe(data => {
      if (data) {
        if (data.success) {
          this.router.navigateByUrl(`/shared/view-cm-insurance-prices/${this.activatedRoute.snapshot.params.serviceID}/${data.response}`, {replaceUrl: true});
          // this.getSingleServiceInsurancePricesData(this.activatedRoute.snapshot.params.mixedID);
        }
      }
    });
  }

  chartOption: EChartOption;

  setGraphValues(){
    let sortedPrices = [...this._selectedColumns]

    sortedPrices.sort((a,b) => {
      if(!!sortedPrices[0].ip_price){
        return b.ip_price - a.ip_price
      }else{
        return b.op_price - a.op_price
      }
    })
    sortedPrices = sortedPrices.map(el => {
      el.ip_price = el.ip_price>0? el.ip_price : 0;
      el.op_price = el.op_price>0? el.op_price : 0;
      return el;
    })
    this.chartOption = {
      grid: { containLabel: true },
      legend: {},
      tooltip: {},
      dataset: {
        dimensions: ['carrier_name', 'ip_price', 'op_price'],
        source: sortedPrices
      },
      xAxis: {},
      yAxis: {
         type: 'category',
         axisLabel: {
          interval: 0,
          rotate: 0,
          formatter: function(value) {
            if(value.length > 25){
              let slicedVal = value.slice(0,23);
              slicedVal = slicedVal + '...'
              return slicedVal
            }else{
              return value
            }
          }
        },
        nameTextStyle: {
          fontSize: 6
        }
        },
      // Declare several bar series, each will be mapped
      // to a column of dataset.source by default.
      series: [
        { type: 'bar' ,
        itemStyle: {color: '#20B04B'} ,
        label: {
          show: true,
          position: 'right',
          color: '#000000',
          formatter: function(c){
            if(c.value.ip_price<=0){
              return 'N/A';
            }else{
              return `$${c.value.ip_price}`;
            }
          } }
        },
        { type: 'bar',
        itemStyle: {color: '#FFB904'} ,
        label: {
          show: true,
          position: 'right',
          color: '#000000',
          formatter: function(c){
            if(c.value.op_price<=0){
              return 'N/A';
            }else{
              return `$${c.value.op_price}`;
            }
          }}
        }
      ]
    };
  }

}
