import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpCallService } from 'src/app/services/http-call.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { environment } from 'src/environments/environment.prod';

interface DialogData {
  proID: string;
  docID: string;
}

@Component({
  selector: 'app-manage-physician-insurances-modal',
  templateUrl: './manage-physician-insurances-modal.component.html',
  styleUrls: ['./manage-physician-insurances-modal.component.scss']
})
export class ManagePhysicianInsurancesModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<ManagePhysicianInsurancesModalComponent>,
    private spinner: SpinnerService,
    private httpCall: HttpCallService
  ) { }

  ngOnInit(): void {
    this.getInsuranceLookup();
    this.getPhysicianInsurances(this.data.docID);
  }

  addedInsurancesList: any = [];
  getPhysicianInsurances(docID) {
    // this.spinner.spin$.next(true);
    this.httpCall.getAuth(`${environment.providerDomain}/providerApi/getDoctorInsurances/${docID}`).subscribe( res => {
      this.spinner.stopSpinner();
      if(res['success']) {
        this.addedInsurancesList = res['response'];
      } else {
        // this.snackbar.open(res['message'], 'Failed', {
        //   duration: 5000
        // });
      }
    }, (err) => {
      this.spinner.stopSpinner();
      // this.snackbar.open(err.message, 'Failed', {
      //   duration: 5000
      // });
    });
  }

  changeInsuranceStatus(insuranceID, state) {
    let body;
    if(state == 1) {
      body = {
        id: insuranceID,
        state: 2
      };
    } else {
      body = {
        id: insuranceID,
        state: 1
      };
    }
    this.httpCall.postAuth(`${environment.providerDomain}/providerApi/manageDoctorInsurance`, body).subscribe( res => {
      this.spinner.stopSpinner();
      if(res['success']) {
        this.getPhysicianInsurances(this.data.docID);
      } else {
        // this.snackbar.open(res['message'], 'Failed', {
        //   duration: 5000
        // });
      }
    }, (err) => {
      this.spinner.stopSpinner();
      // this.snackbar.open(err.message, 'Failed', {
      //   duration: 5000
      // });
    });
  }

  insuranceList: any = [];
  enteredInsurance: string;
  getInsuranceLookup() {
    this.httpCall.getAuth(`${environment.providerDomain}/providerApi/insurenceAutoComplete`).subscribe( res => {
      // this.spinner.stopSpinner();
      if(res['success']) {
        this.insuranceList = res['response'];
      } else {
        // this.snackbar.open(res['message'], 'Failed', {
        //   duration: 5000
        // });
      }
    }, (err) => {
      // this.spinner.stopSpinner();
      // this.snackbar.open(err.message, 'Failed', {
      //   duration: 5000
      // });
    });
  }

  selectedInsuranceList: any = [];
  addInsurance(selectedItem) {
    let filtered = this.selectedInsuranceList.filter( item => item.carrierId == selectedItem._id);
    let addedFiltered = this.addedInsurancesList.filter( item => item.carrierId == selectedItem._id);
    if(filtered.length > 0 || addedFiltered.length > 0) {
      alert(`${selectedItem.Name} was already added`);
    } else {
      this.selectedInsuranceList.push({ carrierId: selectedItem._id, name: selectedItem.Name });
      this.enteredInsurance = '';
      // this.snackbar.open(`${selectedItem.fname} ${selectedItem.lname} was already added`, 'Failed', {
      //   duration: 5000
      // });
    }
  }

  removeInsurance(selectedItem) {
    let filteredIndex = this.selectedInsuranceList.findIndex( item => item._id == selectedItem._id);
    this.selectedInsuranceList.splice(filteredIndex, 1);
  }

  submitInsuranceDetails() {
    let body = {
      providerId: this.data.proID,
      doctorId: this.data.docID,
      insurances: this.selectedInsuranceList
    };
    this.httpCall.postAuth(`${environment.providerDomain}/providerApi/docInsurance`, body).subscribe( res => {
      this.spinner.stopSpinner();
      if(res['success']) {
        this.dialogRef.close(res);
      } else {
        // this.snackbar.open(res['message'], 'Failed', {
        //   duration: 5000
        // });
      }
    }, (err) => {
      this.spinner.stopSpinner();
      // this.snackbar.open(err.message, 'Failed', {
      //   duration: 5000
      // });
    });
  }

}
